import HK from "../Images/hongkong-icon.png"
import Mumbai from "../Images/mumbai-icon.png"
import Surat from "../Images/surat-icon.png"
const ContactData= [
    {
        id:"1",
        imgsrc:HK,
        locationName:"Hong Kong",
        contactNum:"+852 4644 4677"
    },
    {
        id:"2",
        imgsrc:Mumbai,
        locationName:"Mumbai",
        contactNum:"+91 91065 58044"
    },
    {
        id:"3",
        imgsrc:Surat,
        locationName:"Surat",
        contactNum:"+91 81604 24996"
    },
]
export default ContactData;