/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Link } from "react-router-dom";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// import { ContentRoute } from "../../../../_metronic/layout";
import LoginForm from "./LoginForm";
// import Registration from "./Registration";
// import ForgotPassword from "./ForgotPassword";
import LoginSideImg from "./Images/login-img.png"
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function Login(props) {
  // console.log("propssss",props)
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{ backgroundColor: "#EEF1F6" }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-center">
              {/* start:: Aside content */}
                <div className="aside-img text-center" >
                  <img src={LoginSideImg} alt="Login_img" title="Login" />
                </div>
              {/* end:: Aside content */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-lg-0">
                <LoginForm props={props} />
                {/* <ContentRoute path="/auth/login" component={Login} /> */}
              </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

export default Login;