import React from "react";
import Scrollspy from 'react-scrollspy'
// import {BASE_URL_OLD,BASE_URL} from "../../../../env_config"
import { toAbsoluteUrl } from "../../../_helpers";
import { Link } from "react-router-dom";

export const KycHelp = () => {
    return (
        <>
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">Getting Started</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span> Getting Started</span>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <div className="card card-custom card-stretch">
                                <div className="card-body px-1">
                                    <div className="sticky-top" style={{top:'100px'}}>
                                        <Scrollspy className="help-scrollspy" items={ ['kys','status', 'adding-bus-info', 'get-approved'] } currentClassName="is-current">
                                            <li><a href="#kys">KYS</a></li>
                                            <li><a href="#status">Status Definitions</a></li>
                                            <li><a href="#adding-bus-info">Adding Business Information</a></li>
                                            <li><a href="#get-approved">Getting Approval</a></li>
                                        </Scrollspy>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10">
                            <div className="card card-custom card-stretch">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-12">
                                            <div className="p-3 border-bottom">
                                                <h2 className="" >Introduction</h2>
                                                <p className="mt-4">
                                                    Diamonds On Call Supplier Center is a complete and integrated solution for all your Online diamond sales. It lets you sale exclusively ranges of diamonds. We at Diamond On Call Supplier Centre exclusively maintain the four Cs (i.e. carat, cut, clarity, and color) for you which helps to determine the value of any diamond even before customer makes purchase. Here, we help you set up your account with Diamonds On Call Supplier Centre so that you can begin transacting electronically with jewellers and retailers across the entire globe.&nbsp;</p>
                                                <p className="mt-4">By taking these steps to join the world’s largest online diamond buying platform,
                                                    your selected quality diamonds will be able to reach jewellers around the world.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className="p-3 border-bottom">
                                                <h2 className="" id="kys">KYS (Know Your Supplier) &amp; Approval</h2>
                                                <p className="mt-4">Know Your Supplier (KYS) is a mandatory one-time process of Diamonds On Call
                                                    Supplier Centre to authenticate our supplier’s identity and address for a hassle free
                                                    financial transaction in the future. It is a prerequisite to associate and become a
                                                    supplier for Diamonds On Call. After completing this process your account will be
                                                    approved and activated for further transactions with Diamonds On Call Supplier
                                                    Centre.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className=" p-3 border-bottom" id="status">
                                                <h3>Status Definitions</h3>
                                                <ul>
                                                    <li>
                                                        <p><b>Pending:</b> The status of your account will be displayed as &quot;Pending&quot; after you login to Diamond On Call Supplier Centre for the first time after joining process. You have to complete your profile in the business information page for your account verification. For more information on completing profile see section&nbsp; <Link to="kyc-help/#adding-bus-info">Adding Business Information</Link></p>
                                                    </li>
                                                    <li>
                                                        <p><b>Freeze:</b> After submitting your  profile information your data will be
                                                            verified by our Account Management Team. Once your profile information is verified and approved  your Account Status will be shown as <b>Freeze</b>. In the <b>Freeze</b> status you can test your inventory before it is live and activated by clicking on <strong>&quot;Add Listing&quot;</strong> in the <strong>Dashboard page</strong>.</p>
                                                        <p className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20" style={{borderLeft:"5px solid"}}><strong>Note:</strong> The <strong>Add Listing</strong> option will be displayed for only one time, when you add your</p>
                                                    </li>
                                                    <li>
                                                        <p><b>Active:</b> After the stock list is uploaded, your inventory will be verified and
                                                            account status will be displayed as “<b>Active</b>”. In the active status your
                                                            diamonds will be live to customers and you will start receiving orders.&nbsp;</p>
                                                    </li>
                                                </ul>
                                                <div className="mt-4">
                                                    <h3>Documents to be Uploaded </h3>
                                                    <p className="">Below are the list of documents required to be uploaded in the Business Info page to complete your profile and company information.</p>
                                                    <ul>
                                                        <li>
                                                            <strong>Company Logo :</strong> Your registered company logo.</li>
                                                        <li>
                                                            <strong>Business Registration Number :</strong> Scanned copy of your<strong> Business Reg. number.</strong></li>
                                                        <li>
                                                            <strong>ID Document :&nbsp;</strong>Scanned copy of the identity proof of the Director/Partner
                                                            along with the ID number.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="mt-4">
                                                    <h3>Completing your Profile</h3>
                                                    <p>The objective of e-KYS (Know Your Supplier) is to reduce paperwork and cut down on the turnaround time of completing your KYS formalities online in real time in a few easy steps. You can complete your KYS formalities in a matter of minutes and proceed to make your first sale from Diamonds On Call Supplier Centre Account.</p>
                                                    <p>Your Profile will be completed, once the KYS/Business Info and Approval process is complete. The steps to complete the process is provided below:</p>
                                                    <ol>
                                                        <li><Link to="kyc-help/#adding-bus-info">Adding the Business Information</Link></li>
                                                        <li><Link to="/upload-diamond">Uploading the Stock</Link></li>
                                                        <li><Link href="kyc-help/#get-approved">Get Approved</Link></li>
                                                    </ol>
                                                </div>
                                                <div className="text-center">
                                                    <figure>
                                                        {/* <img className="img-fluid p-3" src={`${BASE_URL_OLD}/assets/images/Completing-KYS-ss.png`} alt="Completing-KYS-ss" border="0" /> */}
                                                        <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/01.jpg`)} alt="01" border="0" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className="p-3 border-bottom" id="adding-bus-info">
                                                <h2 className="" id="kys">Adding Business Information</h2>
                                                <p className=""><strong>Business Info </strong>page of Diamonds On Call Supplier Centre requires you to provide your <b>Profile Information</b> i.e. personal information and <strong>company information</strong>.</p>
                                                <p className=""> The step-by-step process to complete your <strong>Profile Information</strong> page is provided below; </p>
                                                <p className="pl-3 mb-0">Step 1: Login to <strong>Diamonds On Call Supplier Centre</strong> by providing your credentials</p>
                                                <p className="pl-3">Step 2: Go to <b>Dashboard</b> tab and click on <b>Business Info</b></p>
                                                <div className="text-center mb-3">
                                                    <figure>
                                                        <img className="img-fluid p-4" src={toAbsoluteUrl(`/media/screenshots/02.jpg`)} alt="02" border="0" />
                                                    </figure>
                                                </div>
                                                <p className="pl-3">Step 3: Enter the required details for <strong>Personal Information</strong> and <strong>Company Information </strong>page</p>
                                                <div className="row justify-content-center">
                                                    <div className="col-md-8 col-sm-12">
                                                        <table className="table kycTable table-bordered w-100">
                                                            <thead className="bg-gray-100">
                                                                <tr>
                                                                    <th colSpan="2">Personal Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td >Email</td>
                                                                    <td >Mail ID (Read-Only mode, same as used while creating <b>Join Now</b> information)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >First Name</td>
                                                                    <td >First name (Read-Only mode, same as used while creating <b>Join Now</b> information)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Last Name</td>
                                                                    <td >Last name (Read-Only mode, same as used while creating <b>Join Now</b> information)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Designation (compulsory) </td>
                                                                    <td >Enter your designation</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Mobile (compulsory)</td>
                                                                    <td >Enter your mobile number</td>
                                                                </tr>
                                                            </tbody>
                                                            <thead className="bg-gray-100">
                                                                <tr>
                                                                    <th colSpan="2">Company Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td >Company Name </td>
                                                                    <td >Company Name (Read-Only mode, same as used while creating <b>Join Now</b> information)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Company Logo </td>
                                                                    <td >Select and upload the company logo<br />
                                                                        <b>Size : </b>500 x 500cm<br />
                                                                        <b>Format : </b>PNG/JPEG/PDF<br />
                                                                        <b>File size : </b> 100KB (maximum)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Address (compulsory) </td>
                                                                    <td >Enter your company address</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Company Website </td>
                                                                    <td >Enter your company website</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Company Email (compulsory) </td>
                                                                    <td >Enter your company Email id</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Company Telephone (compulsory) </td>
                                                                    <td >Enter your company phone number
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Business Reg. Number (compulsory) </td>
                                                                    <td >Enter your company registration number
                                                                        and upload the registration number in<br />
                                                                        <b>Format:</b> PNG/JPEG/PDF
                                                                        <br /><b>Size:</b> 2MB Maximum</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Director/Partner Name (compulsory) </td>
                                                                    <td >Enter your Director/Partner Name</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Type of document (compulsory) </td>
                                                                    <td >Enter the type of document Passport or ID Card.</td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Upload Document proof (compulsory) </td>
                                                                    <td >Select and upload the identity proof along with the ID number of the Director/Partner<br />
                                                                        <b>Format: </b>PNG/JPEG/PDF
                                                                        <br /><b>Size:</b> 2MB Maximum<br />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <figure>
                                                        <img className="img-fluid" src={toAbsoluteUrl(`/media/screenshots/03.jpg`)} alt="03" border="0" />
                                                    </figure>
                                                </div>
                                                <p class="pl-3">Step 4: Click <strong>Save Changes</strong> to save all updated information</p>
                                                <p class="pl-3">A message will be displayed <em>“Thank you for completing your profile. Please wait for approval, until then you can start uploading your stock.”</em></p>
                                                <div class="text-center">
                                                    <figure>
                                                        <img className="img-fluid p-4" src={toAbsoluteUrl(`/media/screenshots/05.jpg`)} alt="05" border="0" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className="border-bottom" id="get-approved">
                                                <h2 className="">Getting Approval</h2>
                                                <p className="">Once you finish your profile, you need to wait for approval. Diamonds On Call Supplier Centre approval process takes about 24 hours. Meanwhile you can test and upload your inventory. Once it is approved your account status will be displayed as "<strong>Freeze</strong>".</p>
                                                <div class="text-center">
                                                    <figure>
                                                        <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/06.jpg`)} alt="06" border="0" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
