/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaGem } from "react-icons/fa";
import { Link } from "react-router-dom";
export function ActiveStoneTile({activeStoneCount,userinfo}) {
  return (
    <>
      <div className={`card card-custom activestone card-stretch gutter-b`} style={{ height: "120px" }} >
          <Link to="/search-diamond" >
            <div className="card-body">
              <div className="text-white font-weight-bolder font-size-h2 mt-3">{activeStoneCount !== "" ? activeStoneCount :"0" }</div>
              <span className="text-light font-weight-bold font-size-lg mt-1">Active Stones</span>
              <div className="bgIcon">
                <FaGem />
              </div>
            </div>
          </Link>
      </div>
    </>
  );
}
