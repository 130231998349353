import React from "react";
import { Link } from "react-router-dom";
import Scrollspy from 'react-scrollspy'
import {BASE_URL_OLD} from "../../../../env_config"
import { toAbsoluteUrl } from "../../../_helpers";


export const SearchDiamHelp = () => {


    return (
        <>
           <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">Search Diamonds Help</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span> Search Diamonds</span>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <div className="card card-custom card-stretch">
                                <div className="card-body px-1">
                                    <div className="sticky-top" style={{top:'100px'}}>
                                        <Scrollspy className="help-scrollspy" items={ ['search-diamonds','invalid-diamonds'] } currentClassName="is-current">
                                            <li><a href="#search-diamonds">Search Diamonds</a></li>
                                            <li><a href="#invalid-diamonds">Invalid Diamonds</a></li>
                                        </Scrollspy>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10">
                            <div className="card card-custom card-stretch">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 border-bottom">
                                            <h3 id="search-diamonds">Search Diamonds</h3>
                                            <p >The Search Diamonds page displays the various types of diamonds available at Diamonds On Call Supplier Centre. It allows you to search and choose from a range of available options. You can also search diamonds based on shapes, carat, color etc.</p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/18.jpg`)} alt="18" border="0" />
                                                </figure>
                                            </div>
                                            <div className="text-center mt-3">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/19.jpg`)} alt="19" border="0" />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <h3 id="invalid-diamonds">Invalid Diamonds</h3>
                                            <p>The diamonds which do not match the parameters listed in the <strong>HELP&#62;<Link to="/parameter-help">Parameter</Link> </strong>section, is moved to this <strong>Invalid Diamonds</strong> page. After the stock list is uploaded via ATP, FTP, or manually, they are compared to the <strong>Round Diamond, Fancy Shaped Diamond,</strong> and <strong>Fancy Colored Diamond</strong> parameter list.</p>
                                            <p>The <strong>Reason</strong> column in the table, clearly mentions why a diamond is invalid. For example, the depth of the diamond, weight, color, clarity, cut etc are some of the reasons</p>
                                            <div className="text-center mt-3">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/20.jpg`)} alt="20" border="0" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
