import React from "react";
import uploadInv  from "../Images/upload-inventory.png"
import uploadIcon  from "../Images/upload1.svg"
import orderReqNoti  from "../Images/order-request-notification.png"
import boxIcon  from "../Images/box.svg"
import diamQualityCheck  from "../Images/diamond-quality-check.png"
import diamondIcon  from "../Images/diamond_icon.svg"
import deliveryImg  from "../Images/shipping-and-delivery.png"
import truckIcon  from "../Images/truck.svg"
import payment  from "../Images/payment.png"
import getpaid  from "../Images/getpaid.svg"

const HowitworkSec = () =>{
    return(
        <>
            <div className="howitworks text-center">
                <span className="anchorspace" id="howitworks"></span>
                <h2 className="mt-3 mb-2">How it Works?</h2>
                <p className="mb-4">Sell your diamonds in 5 simple steps</p>
            </div>
            <div className="container my-10">
                <div className="steps">
                    <div className="row justify-content-center position-relative">
                        <div className="col-md-5 text-center ">
                            <img className="btmg size_c2" src={uploadInv} alt="upload-inventory" title="Upload Inventory" />
                        </div>
                        <div className="col-md-5 align-self-center timeline p-8">
                            <h4>STEP 1 : UPLOAD INVENTORY</h4>
                            <p>Upload your diamond inventory using Excel, API or FTP. Upload with complete details like NO BGM, eye clean, image and video links, to get the online sales faster.</p>
                        </div>
                        <div className="iconimg d-flex justify-content-center align-items-center">
                            <img className="w-50" src={uploadIcon} alt="upload" />
                        </div>
                    </div>
                </div>
                <div className="steps">
                    <div className="row justify-content-center position-relative">
                        <div className=" col-md-5 order-xs-1 col-sd-12 order-md-2 order-sm-1 order-lg-2 text-center timeline py-2 px-4">
                            <img className="tmg size_c" src={orderReqNoti} alt="order-request-with-notification" title="Get Orders" />
                        </div>
                        <div className="col-md-5 order-xs-2 col-sd-12 order-md-1 order-sm-1 order-lg-1 align-self-center btmg py-2 px-8">
                            <h4>STEP 2 : GET ORDERS</h4>
                            <p>Receive order request on the supplier centre with notification, where you can approve your order.</p>
                        </div>
                        <div className="iconimg d-flex justify-content-center align-items-center">
                            <img className="w-50" src={boxIcon} alt="box" />
                        </div>
                    </div>
                </div>
                <div className="steps">
                    <div className="row justify-content-center position-relative">
                        <div className="col-md-5 text-center py-2 px-4">
                            <img className="btmg size_c2" src={diamQualityCheck} alt="diamond-quality-check" title="Diamond Quality Check" />
                        </div>
                        <div className="col-md-5 align-self-center timeline p-8">
                            <h4>STEP 3 : QUALITY CHECK </h4>
                            <p>Once order is confirmed, Diamond goes under quality check to make sure all the aspects of diamonds are conflict free.</p>
                        </div>
                        <div className="iconimg d-flex justify-content-center align-items-center">
                            <img className="w-50" src={diamondIcon} alt="diamond" />
                        </div>
                    </div>
                </div>
                <div className="steps">
                    <div className="row justify-content-center position-relative">
                        <div className="col-md-5 order-xs-1 col-sd-12 order-md-2 order-sm-1 order-lg-2 text-center timeline py-2 px-4">
                            <img className="tmg size_c" src={deliveryImg} alt="shipping-and-delivery" title="Shipping and Delivery" />
                        </div>
                        <div className="col-md-5 order-xs-2 col-sd-12 order-md-1 order-sm-2 order-lg-1 align-self-center btmg py-2 px-8">
                            <h4>STEP 4 : DELIVERY</h4>
                            <p>Once the quality check is approved, your diamond will be confirmed to customer and processed for delivery. </p>
                        </div>
                        <div className="iconimg d-flex justify-content-center align-items-center">
                            <img className="w-50" src={truckIcon } alt="logistics" />
                        </div>
                    </div>
                </div>
                <div className="steps">
                    <div className="row justify-content-center position-relative ">
                        <div className="col-md-5 text-center py-2 px-4">
                            <img className="btmg size_c2" src={payment} alt="payment" title="Payment" />
                        </div>
                        <div className="col-md-5 align-self-center py-2 px-4">
                            <h4>STEP 5 : PAYMENT </h4>
                            <p>All the payments will be done in 14 Days of invoice and delivery.</p>
                        </div>
                        <div className="iconimg d-flex justify-content-center align-items-center">
                            <img className="w-50" src={getpaid} alt="getpaid" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HowitworkSec;