import React, { useEffect } from "react";
// import { Redirect, Route, Switch } from "react-router-dom";
import { useSubheader } from "../../layout";
import { CompanyInformation } from "./components/CompanyInformation";
import { CompanyRefdata } from "./components/CompanyRefdata";
import { PersonaInformation } from "./components/PersonaInformation";
import { ProfileCard } from "./components/ProfileCard";
// import userData from "../dummyData/uaserdata.json";
// import companyRefDataDummy from "../dummyData/companyRefData.json";
import {Tab} from "react-bootstrap";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function ProfilePage({userinfo,companyRef}) {
    // console.log(companyRef)
    const suhbeader = useSubheader();
    suhbeader.setTitle("User profile");

    // const user = useMemo(() => userData, []);
    // const companyReferenceData = useMemo(() => companyRefDataDummy, []);

    useEffect(() => {
        document.title = "User Profile | Diamonds on Call - Suppliers Center";
    }, []);

    return (
        <Tab.Container id="profileTab" defaultActiveKey="personal">
            <div className="d-flex flex-row">
                <ProfileCard user={userinfo} />
                <div className="flex-row-fluid ml-lg-8">
                    <Tab.Content className="h-100 profileTab">
                        <Tab.Pane eventKey="personal">
                            <PersonaInformation user={userinfo} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="company">
                            <CompanyInformation user={userinfo} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="companyref">
                            <CompanyRefdata data={companyRef} />
                        </Tab.Pane>
                    </Tab.Content>
                    {/* <Switch>
                    <Redirect
                        from="/profile"
                        exact={true}
                        to="/profile/personal-information"
                    />
                    <Route
                        path="/profile/personal-information"
                        component={() => <PersonaInformation user={user} />}
                    />
                    <Route
                        path="/profile/company-information"
                        component={() => <CompanyInformation user={user}/>}
                    />
                    <Route
                        path="/profile/company-reference"
                        component={() => <CompanyRefdata data={companyReferenceData}/>}
                    />
                </Switch> */}
                </div>
            </div>
        </Tab.Container>
    );
}
