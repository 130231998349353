import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function SalesHistorySk() {
   return (
        <>
            <div className="card card-custom gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start">
                        <Skeleton variant="text" className="mr-3" width={100} />
                    </div>
                    <div className="card-toolbar flex-md-nowrap align-items-center">
                        <Skeleton className="mr-3" variant="rect" width={140} height={30} />
                        <Skeleton className="mr-3" variant="text" width={5} height={40} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive custom-scrollbar w-100">
                        <table className="table table-hover table-striped">
                            <thead className="custom_datatable_head">
                                <tr>
                                    <th></th>
                                    {Array.from(Array(19), (e, i) => {
                                        return (
                                            <th key={i}><Skeleton className="mx-auto" variant="text" width={50} height={20} /></th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(Array(8), (e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Skeleton className="mx-auto" variant="text" width={20} height={25} /></td>
                                            <td className="text-center"><Skeleton className="mx-auto" variant="text" width={20} height={20} /></td>
                                            <td className="text-center"><Skeleton className="mx-auto" variant="text" width={80} height={30} key={i}/></td>
                                            <td className="text-center"><Skeleton className="mx-auto" variant="text" width={100} height={20} />
                                            </td>
                                            {Array.from(Array(3), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={30} height={20} /></td>
                                                );
                                            })}
                                            <td className="">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <Skeleton className="mx-auto" variant="circle" width={20} height={20} />
                                                    <Skeleton className="mr-3" variant="text" width={80} height={15} key={i}/>
                                                </div>
                                            </td>
                                            {Array.from(Array(7), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={30} height={20} /></td>
                                                );
                                            })}
                                            {Array.from(Array(3), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={80} height={20} /></td>
                                                );
                                            })}
                                            <td ><Skeleton className="mx-auto" variant="text" width={80} height={20} /></td>
                                            <td ><Skeleton className="mx-auto" variant="text" width={40} height={20} /></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer border-0 d-flex align-items-center justify-content-between">
                    <Skeleton className="mr-3" variant="text" width={100} height={40} />
                    <Skeleton className="mr-3" variant="text" width={200} height={50} />
                    <Skeleton className="mr-3" variant="text" width={200} height={50} />
                </div>
            </div>
        </>
    );
}
