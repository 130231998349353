import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function DiamFeedSk() {
   return (
        <>
            <div className="card-body p-0">
                <div className="d-flex justify-content-between">
                    <div className="card-title align-items-start">
                        <Skeleton variant="text" className="mr-3" width={100} />
                    </div>
                    <div className="card-toolbar d-flex flex-md-nowrap align-items-center">
                        <Skeleton className="mr-3" variant="rect" width={140} height={30} />
                        <Skeleton className="mr-3" variant="text" width={5} height={40} />
                    </div>
                </div>
                <div className="table-responsive custom-scrollbar w-100">
                    <table className="table table-hover table-striped">
                        <thead className="custom_datatable_head">
                            <tr>
                                <th ><Skeleton className="mx-auto" variant="text" width={60} height={20} /></th>
                                <th ><Skeleton className="mx-auto" variant="text" width={70} height={20} /></th>
                                <th ><Skeleton className="mx-auto" variant="text" width={50} height={20} /></th>
                                <th ><Skeleton className="mx-auto" variant="text" width={40} height={20} /></th>
                                <th ><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                <th ><Skeleton className="mx-auto" variant="text" width={40} height={20} /></th>
                            </tr>
                        </thead>
                        <tbody >
                            {Array.from(Array(5), (e, i) => {
                                return (
                                    <tr key={i}>
                                        <td><Skeleton className="mx-auto" variant="text" width={50} height={20} /></td>
                                        <td><Skeleton className="mx-auto" variant="text" width={70} height={20} /></td>
                                        <td><Skeleton className="mx-auto" variant="text" width={50} height={20} /></td>
                                        <td><Skeleton className="mx-auto" variant="text" width={50} height={35} /></td>
                                        <td><Skeleton className="mx-auto" variant="text" width={120} height={20} /></td>
                                        <td><Skeleton className="mx-auto" variant="text" width={60} height={35} /></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer border-0 d-flex align-items-center justify-content-between">
                <Skeleton className="mr-3" variant="text" width={100} height={40} />
                <Skeleton className="mr-3" variant="text" width={200} height={50} />
                <Skeleton className="mr-3" variant="text" width={200} height={50} />
            </div>
        </>
    );
}
