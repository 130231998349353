import React from "react";
import { Field } from "formik";

export const Origin = () => {
    const originData = ([
        {
            id: 1,
            origin:"Australia"
        },
        {
            id: 2,
            origin:"Angola"
        },
        {
            id: 3,
            origin:"Botswana"
        },
        {
            id: 4,
            origin:"Brazil"
        },
        {
            id: 5,
            origin:"Canada"
        },
        {
            id: 6,
            origin:"Congo"
        },
        {
            id: 7,
            origin:"DTC"
        },
        // {
        //     id: 8,
        //     origin:"India" //new
        // },
        // {
        //     id: 9,
        //     origin:"Indonesia" //new
        // },
        {
            id: 10,
            origin:"Lesotho"
        },
        {
            id: 11,
            origin:"Liberia"
        },
        {
            id: 12,
            origin:"Namibia"
        },
        {
            id: 13,
            origin:"Russia"
        },
        {
            id: 14,
            origin:"Sierra Leone"
        },
        {
            id: 15,
            origin:"South Africa"
        },
        {
            id: 16,
            origin:"Tanzania"
        },
        // {
        //     id: 17,
        //     origin:"United State" //new
        // },
        {
            id: 18,
            origin:"Zimbabwe"
        },
        {
            id: 19,
            origin:"Other"
        },
        // {
        //     id: 20,
        //     origin:"Unknown"
        // },
    ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">ORIGIN</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        {originData.map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="origin" id={`${item.origin}_origin`} value={item.origin}/>
                                <div className="treatBox selectBox">
                                    <span>{item.origin}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};