import React, { useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { toAbsoluteUrl } from "../../../../_helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { LuPanelLeftClose, LuPanelRightClose } from "react-icons/lu";
// import { GoPlusCircle,GoMinusCircle } from "react-icons/go";
// import { FaMinus, FaPlus } from "react-icons/fa6";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";

export const Shape = () => {
    const shapeNames = [ "ROUND", "PEAR", "OVAL", "MARQUISE", "HEART", "RADIANT", "PRINCESS","EMERALD", "ASSCHER", "SQUARE RADIANT", "CUSHION BRILLIANT",  "CUSHION MODIFIED", "BAGUETTE", "EUROPEAN CUT",  "OLD MINER", "BRIOLETTE", "BULLETS","CADILLAC", "CALF","CIRCULAR BRILLIANT","CRISS","EPAULETTE","FLAME",  "FLANDERS", "HALF MOON", "HEXAGON",  "KITE", "LOZENGE", "OCTAGON", "PENTAGON", "ROSE", "SHIELD",  "SQUARE", "STAR",  "TAPERED BAGUETTE", "TAPERED BULLET", "TRAPEZOID",  "TRIANGLE", "TRILLIANT", "OTHER" ];

    const ShapeData = shapeNames.map((value, index) => ({
        id: index + 1,
        value
    }));

    const [shapeLimit, setShapeLimit] = useState(12);
    const [selectedShapes, setSelectedShapes] = useState([]);
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
        setFieldValue("Shape_all", selectedShapes.length === ShapeData.length);
    }, [selectedShapes, setFieldValue]);

    const showMoreShape = () => setShapeLimit(ShapeData.length);
    const showLessShape = () => setShapeLimit(12);

    const handleSelectAll = () => {
        const newSelectedAll = !values.Shape_all;
        setFieldValue("Shape_all", newSelectedAll);
        setSelectedShapes(newSelectedAll ? ShapeData.map(item => item.value) : []);
        setFieldValue("Shape", newSelectedAll ? ShapeData.map(item => item.value) : []);
    };

    const handleSelectShape = (value) => {
        const newSelectedShapes = selectedShapes.includes(value)
            ? selectedShapes.filter(item => item !== value)
            : [...selectedShapes, value];
        setSelectedShapes(newSelectedShapes);
        setFieldValue("Shape", newSelectedShapes);
    };

    return (
        <div className="row mt-5 pb-10 border-bottom">
            <div className="col-lg-2">
                <p className="font-size-lg font-weight-bold mb-0">Shape</p>
            </div>
            <div className="col-lg-10 flex-wrap d-flex">
                <label className="search_btn_check">
                    <input
                        type="checkbox"
                        name="Shape_all"
                        id="shape_all"
                        onChange={handleSelectAll}
                        checked={values.Shape_all}
                    />
                    <div className="shapeBox selectBox d-flex align-items-center justify-content-center">
                        <span className="text-uppercase font-size-h1">ALL</span>
                    </div>
                </label>
                
                {ShapeData.slice(0, shapeLimit).map((item) => (
                    <label key={item.id} className="search_btn_check">
                        {/* {item.value.toLowerCase()} */}
                        <Field
                            type="checkbox"
                            name="Shape"
                            id={`${item.value}_shape`}
                            value={item.value}
                            checked={selectedShapes.includes(item.value)}
                            onChange={() => handleSelectShape(item.value)}
                        />
                        <div className="shapeBox selectBox ">
                            <SVG className={`${item.value.toLowerCase()}_shape`} src={toAbsoluteUrl(`/media/shape/svg_shape/${item.value.toLowerCase()}.svg`)} />
                            <br />
                            <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">{item.value}</Tooltip>}>
                                <span className="text-uppercase text-truncate">{item.value}</span>
                            </OverlayTrigger>
                        </div>
                    </label>
                ))}
                {ShapeData.length > 12 &&
                    <span className="search_btn_check" onClick={shapeLimit === ShapeData.length ? showLessShape : showMoreShape}>
                        <div className="shapeBox selectBox">
                            {shapeLimit === ShapeData.length ? <CiCircleMinus /> : <CiCirclePlus />}<br />
                            <span className="text-uppercase text-truncate">{shapeLimit === ShapeData.length ? "Less" : "More"}</span>
                        </div>
                    </span>
                }
            </div>
        </div>
    );
};
