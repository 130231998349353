import React, { useEffect } from "react";
import { useSubheader } from "../../layout";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import {HoldList} from "./HoldList/HoldList"
import {HoldCancelledList} from "./HoldCancelledList/HoldCancelledList"
import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const HoldRequest = (userinfo) => {
    const [loader,setLoader] = React.useState(true)
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)

    const suhbeader = useSubheader();
    suhbeader.setTitle("Hold Request");

    useEffect(() => {
        document.title = "Hold Request | Diamonds on Call - Suppliers Center";

        let isMounted = true;

        if (userinfo.subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":userinfo.subId,
              "resource_id":7
            };
            axios({
              method: "POST",
              url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
              headers: { "Content-Type": "application/json" },
              data: permiFetchData,
            }).then((res) => {
                setLoader(false)
            //   console.log(res.data.data[0])
                if (res && res.data) {
                    isMounted && setEditPerm(res.data.data[0].edit_permission);
                    isMounted && setReadPerm(res.data.data[0].read_permission);
                }
            }).catch(error => {
                setLoader(false)
                // console.log(error,"ERROR")
            });
          }else{
              setLoader(false)
          }

    }, [userinfo.subId]);

    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
        setValue(newValue);
    }


    return (
        <>
        {!loader?
            <>
                {readPerm || !userinfo.subId ?
                    <div className="card card-custom gutter-b">
                        <div className="card-header tabbed-card pb-0" style={{minHeight:"50px!important"}}>
                            <div className="card-title">
                                <span className="card-label font-weight-bolder text-dark">
                                    Hold Request
                                </span>
                            </div>
                            <div className="card-toolbar m-0 flex-md-nowrap">
                            <Paper position="static" className="shadow-none border-bottom">
                                <Tabs value={value} indicatorColor="secondary" textColor="secondary" onChange={handleTabChange}>
                                    <Tab label="Hold List" />
                                    <Tab label="Hold Cancelled List"  />
                                </Tabs>
                            </Paper>
                            </div>
                        </div>
                        {value === 0 && (
                            <TabContainer>
                                <HoldList editPerm={editPerm} userinfo={userinfo.userinfo} subId={userinfo.subId}/>
                            </TabContainer>
                        )}
                        {value === 1 && (
                            <TabContainer>
                                <HoldCancelledList userinfo={userinfo.userinfo} />
                            </TabContainer>
                        )}
                    </div>
                :
                    <div className="card card-custom gutter-b">
                        <div className="card-header py-3 mb-3">
                            <div className="card-title">
                                <span className="card-label font-weight-bolder text-dark">
                                    Hold Request
                                </span>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            You do not have permission to access, Please contact your administrator for help
                        </div>
                    </div>
                }
            </>
           : 
            <div className="card card-custom gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title">
                        <span className="card-label font-weight-bolder text-dark">
                            Hold Request
                        </span>
                    </div>
                </div>
                <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
                    Loading...<div className="spinner spinner-primary ml-5"></div>
                </div>
            </div>
            }
        </>
    );
};
