import React, { useEffect } from "react";
import { useSubheader } from "../../layout";
import SVG from "react-inlinesvg";
import { Tab, Nav } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ChangePassword } from "./Component/ChangePassword";
import { ApiSetting } from "./Component/ApiSetting";
import { TimeZone } from "./Component/TimeZone";
import { ManageUser } from "./Component/ManageUser";
import { ManageAccount } from "./Component/ManageAccount"
import { GetCookies } from "../../_helpers/Cookies";
import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';

export function Setting({userinfo,subId}) {
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)
    const [secId,setSecId] = React.useState()
    const [loader,setLoader] = React.useState(true)

    // console.log(subId)
    const suhbeader = useSubheader();
    suhbeader.setTitle("Settings");

    useEffect(() => {
        document.title = "Settings | Diamonds on Call - Suppliers Center";

        let isMounted = true;
        GetCookies("SecId").then(response => {
            if(response){
              setSecId(response)
            }
          })
        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":13
            };
            axios({
              method: "POST",
              url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
              headers: { "Content-Type": "application/json" },
              data: permiFetchData,
            }).then((res) => {
                setLoader(false)
            //   console.log(res.data.data[0])
                if (res && res.data) {
                    isMounted && setEditPerm(res.data.data[0].edit_permission);
                    isMounted && setReadPerm(res.data.data[0].read_permission);
                }
            }).catch(error => {
                setLoader(false)
                console.log(error,"ERROR")
            });
          }else{
            setLoader(false)
          }
    }, [subId]);

    return (
        <>
        {!secId ? 
            <>
                {!loader ? 
                <>
                        {readPerm || !subId ?
                            <Tab.Container id="SettingTab" defaultActiveKey={!subId ? "security" : "apisetting"}>
                                    <div className="d-flex flex-row">
                                        <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside" >
                                            <div className="card card-custom card-stretch">
                                                <div className="card-body pt-4">
                                                    <Nav variant="pills" className="flex-column mt-md-10 mt-3 nav-bold nav-hover nav-active nav-link-rounded" >
                                                        {!subId ? 
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="security">
                                                                    <span className="nav-icon mr-2">
                                                                        <span className="svg-icon">
                                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Shield-user.svg")} />
                                                                        </span>
                                                                    </span>
                                                                    <div className="nav-text font-size-h6">Change Password <br/>
                                                                        <div className="small text-muted">Update your account password</div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        :""}
                                                        {userinfo.stock_status === 0 ? 
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="apisetting">
                                                                    <span className="nav-icon mr-2">
                                                                        <span className="svg-icon">
                                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Code.svg")} />
                                                                        </span>
                                                                    </span>
                                                                    <div className="nav-text font-size-h6">API Settings <br/>
                                                                        <div className="small text-muted">check your preference for API</div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        : ""}
                                                        {!subId ?
                                                            <>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="manageusersetting">
                                                                        <span className="nav-icon mr-2">
                                                                            <span className="svg-icon">
                                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                                                                            </span>
                                                                        </span>
                                                                        <div className="nav-text font-size-h6">Manage User<br/>
                                                                            <div className="small text-muted">Manage your users</div>
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </> :""
                                                        }
                                                        {(readPerm || !subId) && userinfo.is_pending !== 1  ?
                                                            <>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="manageaccountsetting">
                                                                        <span className="nav-icon mr-2">
                                                                            <span className="svg-icon">
                                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                                                                            </span>
                                                                        </span>
                                                                        <div className="nav-text font-size-h6">Manage Switch Account<br/>
                                                                            <div className="small text-muted">Manage your Accounts</div>
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </> :""
                                                        }
                                                        {!subId ?
                                                            <>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="timezonesetting">
                                                                        <span className="nav-icon mr-2">
                                                                            <span className="svg-icon">
                                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")} />
                                                                            </span>
                                                                        </span>
                                                                        <div className="nav-text font-size-h6">Timezone Settings <br/>
                                                                            <div className="small text-muted">Change your Timezone</div>
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </> :""
                                                        }
                                                    </Nav>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-row-fluid ml-lg-8">
                                            <Tab.Content className="h-100 profileTab">
                                                {userinfo.stock_status === 0 && 
                                                    <Tab.Pane eventKey="apisetting">
                                                        <ApiSetting userinfo={userinfo} />
                                                    </Tab.Pane>
                                                }
                                                {!subId ?
                                                    <>
                                                        <Tab.Pane eventKey="security">
                                                            <ChangePassword userinfo={userinfo}/>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="manageusersetting">
                                                            <ManageUser subId={subId} userinfo={userinfo} />
                                                        </Tab.Pane>
                                                </> :""
                                                }
                                                {(readPerm || !subId) && userinfo.is_pending !== 1 ?
                                                    <>
                                                        <Tab.Pane eventKey="manageaccountsetting">
                                                            <ManageAccount subId={subId} userinfo={userinfo} />
                                                        </Tab.Pane>
                                                    </> :""
                                                }
                                                {!subId ?
                                                    <>
                                                        <Tab.Pane eventKey="timezonesetting">
                                                            <TimeZone userinfo={userinfo} />
                                                        </Tab.Pane>
                                                    </> :""
                                                }
                                            </Tab.Content>
                                        </div>
                                    </div>
                            </Tab.Container>
                        :
                            <div className="card card-custom gutter-b">
                                <div className="card-header py-3 mb-3">
                                    <div className="card-title">
                                        <span className="card-label font-weight-bolder text-dark">
                                            Settings
                                        </span>
                                    </div>
                                </div>
                                <div className="card-body text-center">
                                    You do not have permission to access, Please contact your administrator for help
                                </div>
                            </div>
                        }
                </>
                : 
                    <div className="card card-custom gutter-b">
                        <div className="card-header py-3 mb-3">
                            <div className="card-title">
                                <span className="card-label font-weight-bolder text-dark">
                                    Settings
                                </span>
                            </div>
                        </div>
                        <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
                            Loading...<div className="spinner spinner-primary ml-5"></div>
                        </div>
                    </div>
                }
            </>
            : 
            <div className="card card-custom gutter-b">
                    <div className="card-header py-3 mb-3">
                        <div className="card-title">
                            <span className="card-label font-weight-bolder text-dark">
                                Settings
                            </span>
                        </div>
                    </div>
                    <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
                      Please login as a primary aacount to access settings.
                    </div>
                </div>
            }
        </>
    );
}