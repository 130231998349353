import React from "react";

const ContactCard = (props) =>{  
    return(
        <>
            <div className="col-md-4 align-self-center text-center my-4">
                <img className="mx-auto m-0" src={props.imgsrc} alt="Location" />
                <p>{props.locationName}</p>
                <h5>{props.contactNum}</h5>
            </div>  
        </>
    )

}

export default ContactCard;