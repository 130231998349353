import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {NODE_API_URL} from "../../../../env_config";


/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
};

function ResendForm() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState()
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email Id is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        // console.log(values)
        const finalData ={
          "email":values.email
        }
        axios({
          method: 'POST',
          url: `${NODE_API_URL}/SUPP/resendLink`,
          headers: { 
              'Access-Control-Allow-Origin': '*', 
              'Content-Type': 'application/json'
          },
          data: finalData
        })
          .then(function (res) {
            disableLoading();
            setStatus(res.data.msg)
            setSuccess(res.data.success)
            setError()
            // console.log(res)
          })
          .catch((res) => {
            // console.error(res.response.data)
            disableLoading();
            setError(res.response.data.error)
            setSuccess()
            setSubmitting(false);
          });
          // disableLoading();
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="mb-5">
        <h1> Re-Send verification </h1>
        <p className="text-muted font-weight-bold">Please enter valid email address to Re-Send verification Link. </p>
      </div>
      {/* end::Head */}
      {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
         
          {formik.status ? (
            <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (<div></div>
          )}
          {error ? (
            <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{error}</div>
            </div>
          ) : (<div></div>
          )}
          {!success ? 
            <>
              <div className="form-group fv-plugins-icon-container">
                <label htmlFor="type">Email Address<span className="text-danger"> *</span></label>
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                >
                  <span>Send Me verification Link</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              </div>
            </>
           :
           null }
        </form>
      {/*end::Form*/}
    </div>
  );
}

export default ResendForm;


