import React, { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
// import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
// import { injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { register } from "../_redux/authCrud";
import axios from "axios"
import {NODE_API_URL} from "../../../../env_config";
import PhoneInput from "react-phone-input-2";
import "../../../../../node_modules/react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";

const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    supplier_name: "",
    password: "",
    retypepassword: "",
    moblie_no: "",
    country: "India",
    acceptTerms: false,
};

function Regfrom() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState("India");
    const [success , setSuccess] = useState(false)
    const RegistrationSchema = Yup.object().shape({
        firstname: Yup.string().required("First Name is required").min(2, " At least two characters required."),
        lastname: Yup.string().required("Last Name is required").min(2, " At least two characters required."),
        email: Yup.string()
            .email("Please Enter Valid Email Address")
            .required("Please Enter Valid Email Address"),
        supplier_name: Yup.string().required("Company Name is Required"),
        moblie_no: Yup.string().required("Mobile Number is Required").min(10, "Please enter a valid phone number"),
        password: Yup.string("Enter your new password")
            .min(8, " At least 8 characters required.")
            .required("Password is Required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                " Please enter a Alphanumeric with both upper and lower case and symbol - @ . # $ & _ * Password."
            ),
        retypepassword: Yup.string("retype your new password")
            .oneOf([Yup.ref("password"), null], "Password must match")
            .min(8, " At least 8 characters required.")
            .required("Confirm Password is Required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                " Please enter a Alphanumeric with both upper and lower case and symbol - @ . # $ & _ * Password."
            ),
        acceptTerms: Yup.bool().oneOf([true],"You need to accept the terms and conditions"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          const finalData = {
            "firstname":values.firstname,
            "lastname":values.lastname,
            "supplier_name":values.supplier_name,
            "country":values.country,
            "email":values.email,
            "password":values.password,
            "moblie_no":values.moblie_no
        }
        setTimeout(() => {
          axios({
            method: 'POST',
            url: `${NODE_API_URL}/SUPP/registration`,
            headers: { 
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
              },
            data: finalData
          })
            .then(function (res) {
            //   console.log(res)
            //   console.log("finalData",finalData)
              disableLoading();
              setSubmitting(false);
              setSuccess(res.data.success)
            //   history.push({
            //     pathname: "/auth/login",
            //     state: {
            //         success:"Your account is Successfully Created. Please check your mailbox and verify your email address"
            //     },
            // });
              setTimeout(() => {
                //   window.location.replace('/auth/login')
                  setStatus("Your account is Successfully Created. Please check your mailbox and verify your email address");
              }, 350);
            })
            .catch((res) => {
            //   console.log(res.response)
              disableLoading();
              setSubmitting(false);
              setStatus(res.response.data.error);
            });
        }, 1000);

            setSubmitting(true);
            enableLoading();
            // console.log(values)
            // console.log(selected)
            
        },
    });

    return (
        <div className="login-form login-signin" style={{ display: "block" }}>
        {!success ?
           <>
                <div className="mb-5">
                    <h1>Create an account For Free </h1>
                    <p className="text-muted font-weight-bold">
                        {" "}
                        Please fill all details to create account{" "}
                    </p>
                </div>
    
                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    {/* begin: Alert */}
                    {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                                {formik.status}
                            </div>
                        </div>
                    )}
                    {/* end: Alert */}
                    <div className="row">
                        {/* begin: firstname */}
                        <div className="form-group col-md-6 fv-plugins-icon-container">
                            <input
                                placeholder="First Name"
                                type="text"
                                className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                                    "firstname"
                                )}`}
                                name="firstname"
                                {...formik.getFieldProps("firstname")}
                            />
                            {formik.touched.firstname && formik.errors.firstname ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.firstname}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end:firstname */}
    
                        {/* begin: lastname */}
                        <div className="form-group col-md-6 pl-md-0 fv-plugins-icon-container">
                            <input
                                placeholder="Last Name"
                                type="lastname"
                                className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                                    "lastname"
                                )}`}
                                name="lastname"
                                {...formik.getFieldProps("lastname")}
                            />
                            {formik.touched.lastname && formik.errors.lastname ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.lastname}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: lastname */}
                    </div>
    
                    {/* begin: email */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Enter Email Address"
                            type="email"
                            className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.email}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: email */}
                    {/* begin: password */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Enter Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.password}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: password */}
    
                    {/* begin: Confirm password */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Enter Confirm Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                                "retypepassword"
                            )}`}
                            name="retypepassword"
                            {...formik.getFieldProps("retypepassword")}
                        />
                        {formik.touched.retypepassword &&
                        formik.errors.retypepassword ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.retypepassword}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Confirm password */}
    
                    {/* begin: supplier_name */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Enter Company Name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                                "supplier_name"
                            )}`}
                            name="supplier_name"
                            {...formik.getFieldProps("supplier_name")}
                        />
                        {formik.touched.supplier_name &&
                        formik.errors.supplier_name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.supplier_name}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: supplier_name */}
    
                    <div className="row">
                        {/* begin: mobile_no */}
                        <div className="form-group col-md-12 fv-plugins-icon-container">
                            <PhoneInput
                                placeholder="Contact Number"
                                country={"in"}
                                className={`form-control form-control-solid h-auto p-0 ${getInputClasses(
                                    "moblie_no"
                                )}`}
                                name="moblie_no"
                                // {...formik.getFieldProps("moblie_no")}
                                onChange={(value) => formik.setFieldValue("moblie_no",value)}
                            />
                            {formik.touched.moblie_no && formik.errors.moblie_no ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.moblie_no}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end:mobile_no */}
    
                        {/* begin: country */}
                        <div className="form-group col-md-12 fv-plugins-icon-container">
                            {/* <ReactFlagsSelect
                                selected={selected}
                                onSelect={(code) => setSelected(code)}
                                id="country"
                                searchPlaceholder="Search countries"
                                searchable
                                className={`h-auto ${getInputClasses("country")}`}
                                name="country"
                                {...formik.getFieldProps("country")}
                                // onChange={(value) => formik.setFieldValue("country",value)}
                                onChange={(value, country) => console.log(country)}
                            /> */}
                            <select 
                                className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                                    "country"
                                )}`}
                                id="country"
                                name="country"
                                {...formik.getFieldProps("country")}>
                                    <option value="">Select Country</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Aland Islands">Aland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua">Antigua</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Barbuda">Barbuda</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia">Bosnia</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Trty.">British Indian Ocean Trty.</option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Caicos Islands">Caicos Islands</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Futuna Islands">Futuna Islands</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard">Heard</option>
                                    <option value="Herzegovina">Herzegovina</option>
                                    <option value="Holy See">Holy See</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Jan Mayen Islands">Jan Mayen Islands</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea">Korea</option>
                                    <option value="Korea (Democratic)">Korea (Democratic)</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao">Lao</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="McDonald Islands">McDonald Islands</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia">Micronesia</option>
                                    <option value="Miquelon">Miquelon</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Principe">Principe</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Barthelemy">Saint Barthelemy</option>
                                    <option value="Saint Helena">Saint Helena</option>
                                    <option value="Saint Kitts">Saint Kitts</option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                                    <option value="Saint Pierre">Saint Pierre</option>
                                    <option value="Saint Vincent">Saint Vincent</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome">Sao Tome</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia">South Georgia</option>
                                    <option value="South Sandwich Islands">South Sandwich Islands</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard">Svalbard</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="The Grenadines">The Grenadines</option>
                                    <option value="Timor-Leste">Timor-Leste</option>
                                    <option value="Tobago">Tobago</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad">Trinidad</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks Islands">Turks Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="US Minor Outlying Islands">US Minor Outlying Islands</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">Vatican City State</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                    <option value="Virgin Islands (US)">Virgin Islands (US)</option>
                                    <option value="Wallis">Wallis</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
    
                            {formik.touched.country && formik.errors.country ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.country}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: country */}
                    </div>
                    {/* begin: Terms and Conditions */}
                    <div className="form-group">
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                name="acceptTerms"
                                className="m-1"
                                {...formik.getFieldProps("acceptTerms")}
                            />
                            <Link
                                to="/terms"
                                target="_blank"
                                className="mr-1"
                                rel="noopener noreferrer"
                            >
                                I agree the Terms & Conditions
                            </Link>
                            <span />
                        </label>
                        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.acceptTerms}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Terms and Conditions */}
                    <div className="form-group d-flex justify-content-end mb-2">
                        <button
                            type="submit"
                            // disabled={
                            //     formik.isSubmitting ||
                            //     !formik.isValid ||
                            //     !formik.values.acceptTerms
                            // }
                            className="btn btn-primary font-weight-bold px-9"
                        >
                            <span>Create Account</span>
                            {loading && (
                                <span className="ml-3 spinner spinner-white"></span>
                            )}
                        </button>
                    </div>
                </form>
           </>
        : 
            formik.status && (
                <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                    <div className="alert-text font-weight-bold">
                        {formik.status}
                    </div>
                </div>
            )
        }
            <div className="text-right">
                Already have account ? <Link to="/auth/login">Login Here</Link>
            </div>
        </div>
    );
}

export default Regfrom;
