import React, { useMemo, useEffect,useState } from "react";
import { useSubheader } from "../../layout";
import { useLocation } from "react-router";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
// import { Link } from "react-router-dom";
// import DummyData from "../dummyData/searchresult.json";
// import {DiamDetail} from "../popup/DiamDetail"
import { searchResultColumn } from "./Columns";
import { GlobleFilter } from "./../tableFilter";
import { CheckBox } from "./../tableFilter";
import { Dropdown, Modal } from 'react-bootstrap';
import ExpandedDetails from "../expandedDetails/ExpandedDetails"
import { FaSortDown,FaSortUp,FaAngleLeft,FaAngleRight,FaAngleDoubleLeft,FaAngleDoubleRight,FaChevronDown, FaChevronRight,FaEllipsisV} from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import { round } from 'lodash';
import { SwalWarn } from "../popup";
import { ActiveShipping } from "../popup/ActiveShipping";
import SearchTableSkeleton from "../SkeletonLoaders/SearchTable";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const SearchResult = ({userinfo}) => {
    const location = useLocation();
    // const history = useHistory()
    const [stoneData, setStoneData] = useState(location.state.searchresult);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [shipPopup,setShipPopup] = useState(false)
    const [successMsg,setSuccessMsg] = useState(false)
    const [temp,setTemp] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [shipStone,setShipStone] = useState([])
    // const [selectedData, setSelectedData] = useState([]);
    // console.log(stoneData)
    // const [showDiamPopup,setShowDiamPopup] = useState(false)
    // const [showDiamData,setShowDiamData] = useState([])
    const [loader,setLoader] = useState(true)
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);

    const [totalDiamonds, setTotalDiamonds] = useState("0");
    const [totalDollarCarat, setTotalDollarCarat] = useState("0");
    const [totalPrice, setTotalPrice] = useState("0");
    const [totalCarat, setTotalCarat] = useState("0");

    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <ExpandedDetails row={row.row.original} userinfo={userinfo} />
            </>
        ),[]
      )
    const suhbeader = useSubheader();
    suhbeader.setTitle("Search Result");

    useEffect(() => {
        document.title ="Search Result | Diamonds on Call - Suppliers Center";

        if (stoneData){
            setTimeout(function() { 
                setLoader(false)
            }, 1000);
        } else{
            setLoader(true)
        }
        
    }, [stoneData]);

    useEffect(() => {
        let finalSerchData = window.localStorage.getItem("fetchData")
        // console.log(finalSerchData)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/search`,
            headers: {
                "Content-Type": "application/json",
            },
            data: finalSerchData,
        })
        .then(function(res) {
            if(res && res.data &&res.data.data){
                // console.log("searchRes",res.data.data)
                setStoneData([...res.data.data]);
            }
        })
    }, [temp]);

    const activeShippSwal =(selectedFlatRows)=>{
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selectedFlatRows)

        let temptotaldiamonds = 0
        let temptotalprice = 0
        let diamondcarat = 0
        selectedFlatRows.map(value => {
            temptotaldiamonds += 1
            temptotalprice += parseFloat(value.original.C_Weight * value.original.O_Rate)
            diamondcarat += parseFloat(value.original.C_Weight)
            return {
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
            }
        })


        setTotalDiamonds(parseFloat(temptotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice))
        setTotalCarat(parseFloat(diamondcarat))
        setTotalDollarCarat( temptotalprice !== 0 ?  parseFloat(temptotalprice) / parseFloat(diamondcarat) : "00")
        
        // console.log("searchResultData",searchResultData)
        // console.log("trackListData",trackListData)
        // console.log("NewData",NewData)


        let diamondinfo = selectedFlatRows.map(value => {
            return (
                (value.original)
            )
        })
        setShipStone(diamondinfo)
        // setSwalWarnOpen(true)
        setShipPopup(true)
        setAlertMessage(`Are you sure wanted to active Shipment for ${diamondinfo}`)

    }

    const activeShipp =(shipStone)=>{
        // console.log(shipStone)
        // setSwalWarnOpen(true)
        // setAlertMessage("Stone is already approved")

    }

    const columns = useMemo(() => searchResultColumn(), []);
    // const data = useMemo(() => DummyData, []);

    // const showDiamDetail = (row) =>{
    //     setShowDiamPopup(true)
    //     setShowDiamData(row.original)
    // }
    // function discount(row){
        
    //     let orignal_price = round(row.O_Rate * row.C_Weight, 2);
    //     let carat_price = round(orignal_price / row.C_Weight, 2);
    //     let discount = row.raprate ? <>{round((carat_price - row.raprate) / (row.raprate) * 100,2)}</> : "00.00" 
    //     console.log(discount)
    //    return discount;
    //     // $discount_main 	= !empty($value->raprate) ? round(($carat_price - $value->raprate) / $value->raprate * 100, 2) : 0;
    // }
    function discount(row){
        
        let orignal_price = round(row.O_Rate * row.C_Weight, 2);
        let carat_price = round(orignal_price / row.C_Weight, 2);
        let discount = row.raprate ?
            round((carat_price - row.raprate) / (row.raprate) * 100,2)
            : parseInt(row.carat_price) ? round((carat_price - row.carat_price) / (row.carat_price) * 100,2) : "0.00"
        // console.log(parseFloat(discount).toFixed(2))
       return parseFloat(discount).toFixed(2);
        // $discount_main 	= !empty($value->raprate) ? round(($carat_price - $value->raprate) / $value->raprate * 100, 2) : 0;
    }
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        selectedFlatRows,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data:stoneData,
            initialState: {
                sortBy: [
                    {
                        id: 'C_Weight',
                        desc: true
                    }
                ]
            }
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    // {
                    //     Header: () => null,
                    //     id: "expander-popup",
                    //     Cell: ({ row }) => (
                    //         <span>
                    //             <FaEye className="text-hover-primary cursor-pointer" onClick={() => showDiamDetail(row)}/>
                    //         </span>
                    //     ),
                    // },
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                                {row.original.cust_type_no === "0" ?"":
                                    <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                                }
                            </div>
                          ),
                    },
                    {
                        Header: () => null,
                        id: 'expander',
                        Cell: ({ row }) => (
                            <span  {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand" />}
                            </span>
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );
    // setSelectedData(selectedFlatRows)
    return (
        <>
            {!loader ?<div className="card card-custom gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title">
                        <span className="card-label font-weight-bolder text-dark">
                            Search Result <span className="text-primary">(Total stone: {stoneData.length})</span>
                        </span>
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        <button className="btn btn-primary btn-sm text-nowrap mr-2" onClick={()=>activeShippSwal(selectedFlatRows)}>Active Shipment</button>
                        <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter}  />
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2 searchResult">
                                    <PerfectScrollbar
                                        options={perfectScrollbarOptions}
                                        className="scroll"
                                        style={{ maxHeight: "400px", position: "relative" }}
                                    >
                                    {/* <div  className="" > */}
                                        <div>
                                            <label className="checkbox my-2 border p-2">
                                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                <span className="mr-2"></span>
                                                Show All
                                            </label> 
                                        </div>
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id} className={`${column.id}`}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    {/* </div> */}
                                    </PerfectScrollbar>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-5 pb-0">
                    {successMsg ? 
                        <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
                            <div className="alert-text font-weight-bold">
                                Stones are activated for Shipment
                            </div>
                        </div>
                    : null }
                    <div className="table-responsive custom-scrollbar w-100 mt-4">
                        <table className="table table-striped table-hover custom_datatable" {...getTableProps()} >
                            <thead className="custom_datatable_head">
                                {headerGroups.map((headerGroup, i) => (
                                    <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(
                                            (column, i) => (
                                                <th key={i} className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                    </span>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {stoneData.length !== 0 ? page.map((row) => {
                                    prepareRow(row)
                                    return(
                                        <React.Fragment key={row.id}>
                                            <tr {...row.getRowProps()} key={row.id} className={`${discount(row.original) < (-97.00) ? "bg-danger-o-20" : ""}`}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </React.Fragment>
                                    )
                                }) :
                                <tr>
                                    <td className="text-center align-middle"colSpan="100%">
                                        No data found
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer border-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">
                            Page {pageIndex + 1} of {pageOptions.length}{" "}
                        </span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage} >
                                <FaAngleDoubleLeft />
                            </button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage} >
                                <FaAngleLeft />
                            </button>
                            <span className="font-weight-bold text-muted">
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(pageNumber);
                                    }}
                                    style={{ maxWidth: "70px" }}
                                /> of {pageOptions.length} 
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage} >
                                <FaAngleRight />
                            </button>
                            <button className="btn btn-sm btn-light" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >
                                <FaAngleDoubleRight />
                            </button>
                        </div>
                        <span className="d-flex align-items-center">
                            <span className="text-nowrap page_size">
                                Rows Per page:{" "}
                            </span>
                            <select
                                className="form-select ml-2 custom-select py-1"
                                value={pageSize}
                                onChange={(e) =>
                                    setPageSize(Number(e.target.value))
                                }
                            >
                                {[10, 25, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                </div>
                <ActiveShipping setSuccessMsg={setSuccessMsg} show={shipPopup} onHide={() => setShipPopup(false)} data={shipStone} userinfo={userinfo} setTemp={setTemp} temp={temp} totalDiamonds={totalDiamonds} totalPrice={totalPrice} totalDollarCarat={totalDollarCarat} totalCarat={totalCarat}/>
                <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} onHide={() => setSwalWarnOpen(false)} responseOnOk={()=>activeShipp(shipStone)}/> 
            </div>
            : 
                <SearchTableSkeleton />
                // <div className="card card-custom gutter-b">
                //     <div className="card-header py-3 mb-3">
                //         <div className="card-title">
                //             <span className="card-label font-weight-bolder text-dark">
                //                 Search Result
                //             </span>
                //         </div>
                //     </div>
                //     <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
                //         Loading...<div className="spinner spinner-primary ml-5"></div>
                //     </div>
                // </div>
            }
        </>
    );
};
