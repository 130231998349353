import React from "react";
// import { BASE_URL_OLD } from "../../../../env_config";
import { toAbsoluteUrl } from "../../../_helpers";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export const UploadMode = ({userinfo}) => {
    return (
        <>
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">
                        Upload Diamonds
                    </div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/
                        <span> Upload Diamonds</span>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="card card-custom gutter-b">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="mb-10" id="adding-listing" ><b>Introduction </b></h2>
                                    <p className="p-3 ">The Upload Diamonds page of Diamonds On Call Supplier Centre allows you to upload the stock list. You can upload your stock list in anyone of the following modes:</p>
                                    <ul>
                                        <li>API (Application Programming Interface): In the API mode user can upload files by following the API documentation. Our portal supports REST API which uses JSON in PHP. The HTTP Protocol is used for exchanging data. The API details page contains the API access key which is used to upload the file.<Link to={`${userinfo.type==="Natural" ? "/apimode-help" : "/lab-apimode-help"}`}> <strong>Read more</strong></Link> about how to use API</li>
                                        <li>FTP (File Transfer Protocol): In the FTP mode user can upload the files using FTP client. It is recommended to use Filezilla or WinCEP client. The FTP Details page contains the FTP Filename, Hostname, Username, password, and port information. <Link to="/ftpmode-help"> <strong>Read more</strong></Link> about how to use FTP.</li>
                                        <p className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20 mt-3" style={{borderLeft:"5px solid"}}><strong>Note:</strong>You can define the setting in WinCEP FTP client to automatically upload files to the server every two hours.</p>
                                        <li>File (Manual mode to upload document in .csv or .xls format): In this mode user can manually upload the file.<Link href="/filemode-help"> <strong>Read more</strong></Link> about how to use Manual file upload</li>
                                    </ul>
                                    <div className="text-center">
                                        <figure>
                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/07.jpg`)} alt="07" border="0" />
                                        </figure>
                                    </div>
                                    <ul className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20" style={{listStyleType:'none',borderLeft:"5px solid"}}>
                                        <li><em> - The naming convention of the uploaded file should be in .csv format and the file name should be <strong>“stock.csv”</strong> only.</em></li>
                                        <li><em> - Please use the parameters mentioned in our list or else it the list might not uploaded. <br />&nbsp;&nbsp;&nbsp;See HELP (tab)→ <Link to="/parameter-help">Parameters</Link> section as shown the screenshot below; </em></li>
                                    </ul>
                                    <div className="text-center">
                                        <figure>
                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/08.jpg`)} alt="08" border="0" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-12 mt-5">
                                    <p>Your Account Status will be displayed as <b>"Active"</b> only after adding the stock list. You can start uploading the stock list in the <b>Upload Diamonds</b> section.</p>
                                    <p className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20 mt-3" style={{borderLeft:"5px solid"}}><strong>Note:</strong> If the Account Status is not updated to “Active”, and if there is an error please contact your Account Manager.</p>
                                    <div className="text-center">
                                        <figure>
                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/09.jpg`)} alt="09" border="0" />
                                        </figure>
                                    </div>
                                    <p>To start uploading the stock list see <HashLink to="#adding-listing">Uploading the stock</HashLink></p>
                                    <h2 className="mt-5"><b>Uploading the stock</b></h2>
                                    <p >Diamonds On Call Supplier Centre allows you to upload your stock list to our portal by two different ways. Follow the steps provided below to upload your stock list. </p>
                                    <p className="pl-3">Step 1: Do one of the following</p>
                                    <div className="text-center">
                                        <figure>
                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/10.jpg`)} alt="10" border="0" />
                                            {/* <figcaption className="pt-1 font-italic">The Upload Diamond page is displayed.</figcaption> */}
                                        </figure>
                                    </div>
                                    <ul className="ml-3">
                                        <li> Click on Dashboard Page  &#62; Add Listing option </li>  OR
                                        <li> Click on Upload Diamonds page </li>
                                    </ul>
                                    <div className="text-center">
                                        <figure>
                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/40.jpg`)} alt="40" border="0" />
                                            {/* <figcaption className="pt-1 font-italic">The Upload Diamond page is displayed.</figcaption> */}
                                        </figure>
                                    </div>
                                    <p className="pl-3">Step 2: Select an Upload mode and click <strong>Save Changes</strong></p>
                                    <p className="pl-4"><strong className="pl-5">API</strong> <strong className="pl-2">FTP</strong> <strong className="pl-2">File</strong></p>
                                    <p className="pl-3">The selected upload mode will be saved in our website for the future. For more information about uploading diamonds see
                                         <HashLink to="#upload-diamond">Uploading Diamond.</HashLink>
                                    </p>
                                    <p className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20 mt-3" style={{borderLeft:"5px solid"}}><strong>Note:</strong> If you want to change the upload mode, please contact your Account Manager.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
