import React from "react";
import { Field } from "formik";

export const Shade = () => {
    
    const ShadeData = ([
        {
            id: 1,
            shade:"NONE"
        },
        {
            id: 2,
            shade:"LIGHT BROWN"
        },
        {
            id: 3,
            shade:"BROWN"
        },
        {
            id: 4,
            shade:"LIGHT GREEN"
        },
        {
            id: 5,
            shade:"GREEN"
        },
        {
            id: 6,
            shade:"GREY"
        },
        {
            id: 7,
            shade:"BLACK"
        },
        {
            id: 8,
            shade:"PINK"
        },
        {
            id: 9,
            shade:"BLUE"
        },
        {
            id: 10,
            shade:"MIX TINGE"
        },
        {
            id: 11,
            shade:"UNKNOWN"
        }
    ]);
    const MilkyData = ([
        {
            id: 1,
            milky:"No Milky"
        },
        {
            id: 2,
            milky:"Light Milky"
        },
        {
            id: 3,
            milky:"Milky"
        },
        {
            id: 4,
            milky:"UNKNOWN"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-md-2">
                    <p className="font-size-h6 font-weight-bold mb-0">SHADE &amp; MILKY</p>
                </div>
                <div className="col-md-6 d-flex flex-wrap">
                    <div className="w-100 mr-5">
                        <label className="col-12 p-0" htmlFor="">Shade</label>
                        {ShadeData.map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="SHADE" id={`${item.shade}_shade`} value={item.shade}/>
                                <div className="flourBox selectBox">
                                    <span>{item.shade}</span>
                                </div>
                            </label>
                         ))}
                    </div>
                </div>
                <div className="col-md-4 d-flex flex-wrap">
                    <div className="w-100 mr-5">
                        <label className="col-12 p-0" htmlFor="">Milky</label>
                        {MilkyData.map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Milky" id={`${item.milky}_shade`} value={item.milky}/>
                                <div className="flourBox selectBox">
                                    <span>{item.milky}</span>
                                </div>
                            </label>
                         ))}
                    </div>
                </div>
            </div>
        </>
    );
};
