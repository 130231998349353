import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { SwalWarn } from "./SwalWarn";
// import { toAbsoluteUrl } from "../../_helpers";


export const AddAccount = (props) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [otpFrom, setOtpForm] = useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [addButton,setAddButton] = useState(true)
    const [centeredSwal,seCenteredSwal] = useState(true)
    const [alertMessage,setAlertMessage] = useState()
    const [resData,setResData] = useState([])
    
    const initialValues = {
        email: "",
        password: "",
    };
    
    const initialOTPValues = {
        otp: "",
    };
    // console.log(props.userinfo);
    useEffect(() => {
        // setShowPopup(props.show);
        setSuccessMsg()
    }, [props.show]);

    const AddAccountSchema = Yup.object().shape({
        email: Yup.string().required("Email id or username is required"),
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Password is required"),
    });

    const OTPSchema = Yup.object().shape({
        otp: Yup.string().required("OTP must Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        if (formikOTP.touched[fieldname] && formikOTP.errors[fieldname]) {
            return "is-invalid";
        }

        if (formikOTP.touched[fieldname] && !formikOTP.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const sendVerificationAlert = (val,) =>{
        // console.log(props)
        // console.log(val)
        setOtpForm(true)

        const finalData = {
            "from_supp":val.from_supp,
            "to_supp":val.to_supp,
            "firstname":val.to_supplier_firstname,
            "lastname":val.to_supplier_lastname,
            "email":val.to_supplier_email,
            "c_name":props.userinfo.supplier_name
        };

        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/confirmSwitchSupp`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then((res) => {
            // console.log(res.data.msg)
            disableLoading();
            if (res && res.data) {
                setErrorMsg()
                // setResData(res.data.data)
                // setSuccessMsg(res.data.msg)
                setSwalWarnOpen(false)
            }
        })
        .catch((error) => {
            // console.log(error.response, "ERROR");
            setErrorMsg(error.response.data.msg)
            setSuccessMsg()
            // setLoading(false);
        });
        
    }

    const formik = useFormik({
        initialValues,
        validationSchema: AddAccountSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            // setLoading(true);
            const finalData = {
                email: values.email,
                password: values.password,
                from_supp: props.userinfo.id,
            };
            // console.log(finalData);
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/addSwitchSupp`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            })
            .then((res) => {
                disableLoading();
                setSubmitting(false);
                // props.setAddAccountClose(false)
                if (res && res.data) {
                    setErrorMsg()
                    // setOtpForm(true)
                    setResData(res.data.data)
                    setAlertMessage(`Are you sure you want to connect account with ${res.data.data.to_supplier_name} ${res.data.data.type}`)
                    setSwalWarnOpen(true)
                    // setTimeout(() => {
                    //    if (swalWarnOpen){
                    //     console.log("1212121211")
                    //         props.addClose();
                    //     }
                    // }, 1000);
                    // if (swalWarnOpen==true){
                    //     props.addClose();
                    // }
                }
            })
            .catch((error) => {
                // console.log(error.response, "ERROR");
                disableLoading(false);
                // setSwalWarnOpen(false)
                setSubmitting(false);
                setErrorMsg(error.response.data.msg)
                setSuccessMsg()
                // setOtpForm()
                // setLoading(false);
            });
        },
    });

    const formikOTP = useFormik({
        initialValues:initialOTPValues,
        validationSchema: OTPSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            // setLoading(true);
            const finalData = {
                "token":values.otp,
                "firstname":props.userinfo.firstname,
                "lastname":props.userinfo.lastname,
                "requester":props.userinfo.supplier_name,
                "requester_email":props.userinfo.email
              };
            // console.log(finalData);
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/acceptSwitchSupp`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            })
            .then((res) => {
                disableLoading();
                setSubmitting(false);
                // console.log(res.data.msg)
                if (res && res.data) {
                    setSuccessMsg(res.data.msg)
                    setErrorMsg()
                }
            })
            .catch((error) => {
                // console.log(error.response.data.error, "ERROR");
                disableLoading(false);
                setSubmitting(false);
                setErrorMsg(error.response.data.error)
                setSuccessMsg()
            });
        },
    });

    const confirmBtn =()=>{
        window.location.replace("/dashboard")
    }

    const closeModel = () => {
        props.addClose();
        // console.log("here1")
        // setShowPopup(false);
        formik.resetForm();
    };

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.addClose}
                backdrop="static"
                keyboard={false}
            >
                {otpFrom ? 
                    <form
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        onSubmit={formikOTP.handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                <span className="font-weight-bold">
                                    Confirm OTP
                                </span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    To delink the account you can always go to setting and delink the account anytime
                                </div>
                            </div>
                            <div className="row my-5">
                                
                                 {successMsg ?
                                    <>
                                        <div className="col-12 mb-5 text-center">
                                            <div className="mb-5 alert alert-custom alert-light-success mb-4 py-4 alert-dismissible">
                                                <div className="alert-text font-weight-bold">
                                                {successMsg}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button className="btn btn-primary px-8" onClick={()=> confirmBtn(props)}>Ok</button>
                                        </div>
                                    </>
                                    : 
                                    <>
                                        {errorMsg ?
                                            <div className="col-12 mb-5">
                                                <div className="mb-5 alert alert-custom alert-light-danger mb-4 py-4 alert-dismissible">
                                                    <div className="alert-text font-weight-bold">
                                                    {errorMsg}
                                                    </div>
                                                </div>
                                            </div>
                                        : ""}
                                        <div className="col-12 font-size-lg text-center"> Otp sent to {resData.to_supplier_email}, please verify OTP </div>
                                        <div className="col-12 text-muted mb-5 text-center"> Please do not close or refresh browser </div>
                                        <div className="col-12">
                                            <div className="form-group fv-plugins-icon-container row m-0 align-items-center">
                                                <label className="col-md-3 font-weight-bold" htmlFor="password">
                                                    Enter OTP
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        placeholder="Enter otp"
                                                        type="text"
                                                        className={`form-control ${getInputClasses(
                                                            "otp"
                                                        )}`}
                                                        name="otp"
                                                        {...formikOTP.getFieldProps(
                                                            "otp"
                                                        )}
                                                    />
                                                    {formikOTP.touched.otp &&
                                                    formikOTP.errors.otp ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">
                                                                {formikOTP.errors.otp}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </> }
                            </div>
                        </Modal.Body>
                        {!successMsg ? 
                            <Modal.Footer className="py-3 justify-content-end">
                                <button
                                    type="submit"
                                    className="btn btn-primary font-weight-bold px-9"
                                >
                                    <span>Confirm</span>
                                </button> 
                            </Modal.Footer>
                        :""}
                    </form>
               :
                    <form
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        onSubmit={formik.handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                <span className="font-weight-bold">
                                    Connect New Account
                                </span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12 font-size-sm">
                                    Connect all your accounts at one place, enter the details of another supplier account below. 
                                    <span className="text-primary">Once connected you can easily switch between multiple accounts</span>
                                </div>
                            </div>
                            <div className="row my-8">
                                <div className="col-12">
                                {errorMsg && 
                                    <div className="mb-5 alert alert-custom alert-light-danger mb-4 py-4 alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                            {errorMsg}
                                        </div>
                                    </div>
                                }
                                </div>
                                <div className="col-12 mb-5">
                                    <div className="form-group fv-plugins-icon-container row align-items-center">
                                        <label htmlFor="email" className="col-md-3 font-weight-bold"> Email ID  </label>
                                        <div className="col-md-9">
                                            <input
                                                placeholder="Enter your email"
                                                type="text"
                                                className={`form-control ${getInputClasses(
                                                    "email"
                                                )}`}
                                                name="email"
                                                {...formik.getFieldProps(
                                                    "email"
                                                )}
                                            />
                                            {formik.touched.email &&
                                            formik.errors.email ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.email}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group fv-plugins-icon-container row align-items-center">
                                        <label className="col-md-3 font-weight-bold" htmlFor="password">
                                            Password
                                        </label>
                                        <div className="col-md-9">
                                            <input
                                                placeholder="Enter Password"
                                                type="password"
                                                className={`form-control ${getInputClasses(
                                                    "password"
                                                )}`}
                                                name="password"
                                                {...formik.getFieldProps(
                                                    "password"
                                                )}
                                            />
                                            {formik.touched.password &&
                                            formik.errors.password ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.password}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="py-3 justify-content-between">
                            <Button
                                variant="danger"
                                className="font-weight-bold"
                                onClick={() => closeModel(props)}
                            >
                                Close
                            </Button>
                            {!successMsg ? 
                                <button
                                    type="submit"
                                    className="btn btn-primary font-weight-bold px-9"
                                >
                                    <span>Add</span>
                                    {loading && (
                                        <span className="ml-3 spinner spinner-white"></span>
                                    )}
                                </button> 
                            : " "}
                        </Modal.Footer>
                    </form>
                }
                <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} addButton={addButton} onClose={() => setSwalWarnOpen(false)} responseOnOk={()=>sendVerificationAlert(resData,props.userinfo)} notCenteredSwal={centeredSwal}/> 
            </Modal>
        </div>
    );
};
