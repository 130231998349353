import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button,Alert } from "react-bootstrap";
import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';
// import { toAbsoluteUrl } from "../../_helpers";

const initialValues = {
    noBgm: false,
    eyeClean: false,
    noBroken: false,
    acceptTerms: false,
    comments: "",
};

export const HoldApprove = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errmessage,setErrorMessage] = useState()
    // console.log(props)
    useEffect(() => {
        setShowPopup(props.show);
    }, [props.show]);

    const RegistrationSchema = Yup.object().shape({
        acceptTerms: Yup.bool().oneOf([true],"You need to accept the terms and conditions"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            var msg = (msg1 = msg2 = "");
            var right = 0;
            var msg = "";
            var comment = values.comments;
            if (values.noBgm) {
                var msg = "NO BGM ";
            }
            if (values.eyeClean) {
                var msg1 = "100% Eye Clean ";
            }
            if (values.noBroken) {
                var msg2 = "No Broken ";
            }
            if ( values.comments && !values.noBgm && !values.eyeClean && !values.noBroken ) {
                var right = 1;
                   setStatus("")
                   setSubmitting(false);
                   disableLoading();
            } else if ( values.comments && values.noBgm && values.eyeClean && values.noBroken ) {
                var right = 2;
                setStatus("")
                setSubmitting(false);
                disableLoading();
            } else if ( !values.comments && values.noBgm && values.eyeClean && values.noBroken ) {
                var right = 3;
                setStatus("")
                setSubmitting(false);
                disableLoading();
            } else if ( values.comments && (values.noBgm || values.eyeClean || values.noBroken) ) {
                var right = 5;
                setStatus("")
                setSubmitting(false);
                disableLoading();
            } else if ( !values.comments && (!values.noBgm || !values.eyeClean || !values.noBroken) ) {
                setStatus("Please accept all conditions or mention the reason in comment box to approve.");
                setSubmitting(false);
                disableLoading();
                return false;
            } else if (!values.comments &&(!values.noBgm || !values.eyeClean || !values.noBroken)) {
                setStatus("Please accept all conditions or mention the reason in comment box to approve.");
                setSubmitting(false);
                disableLoading();
                return false;
            }
            if (right === 2) {
                comment = comment.replace(/&|_/g, "and");
                comment = msg + msg1 + msg2;
            } else {
                comment = comment.replace(/&|_/g, "and");
                comment = msg + msg1 + msg2 + comment;
            }
            if ( right === 1 || right === 2 || right === 3 || right === 4 || right === 5 ) {
                // console.log(values);
                // console.log(comment);
            }
            const finalData = {
                "userid":props.data.client_id,
                "diamond_type": props.data.diamond_type === "L" ? "L" : "N" ,
                "certino":props.data.Certi_NO,
                "statsusid":1,
                "is_hold":`${props.data.is_hold}`,
                "hold_duration":props.data.hold_time,
                "comment":comment,
                "confirm_id":props.data.confirm_goods_id
            }
            // console.log(finalData)

            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/holdApproveOrRej`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then((res) => {
                disableLoading();
                setSubmitting(false);
                // console.log("res123",res)
                if (res && res.data) {
                //     // setData(res.data.data)
                // props.holdListData();
                    props.onHide();
                    props.setTemp(!props.temp)
                    props.setSuccessMsg(`Your hold request approved for  stone id : ${props.data.Loat_NO}`)
                    props.setLoader(true)
                    // window.location.reload()
                    setErrorMessage()
                }
            }).catch(error => {
                setSubmitting(false);
                // console.log(error.response.data.error,"ERROR")
                disableLoading();
                setErrorMessage(error.response.data.error)
            })
            
        },
    });

    const closeModel = () => {
        props.onHide();
        setShowPopup(false);
    };

    return (
        <div>
            <Modal
                show={showPopup}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form
                    id=""
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span className="font-weight-bolder text-warning">
                                Order ID :{props.data.confirm_goods_id}
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                Please accept all conditions or mention the reason in comment box to approve
                                {errmessage ? <Alert variant="danger"> {errmessage} </Alert>:""}
                            </div>
                            <div className="col-12 mt-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="pr-3"
                                                    name="acceptTerms"
                                                    {...formik.getFieldProps("noBgm")}
                                                />
                                                <span></span>
                                                <p className="mb-0 ml-3">No BGM</p>
                                            </label>
                                            {formik.touched.noBgm &&
                                            formik.errors.noBgm ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.noBgm}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group  mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="pr-3"
                                                    name="eyeClean"
                                                    {...formik.getFieldProps(
                                                        "eyeClean"
                                                    )}
                                                />
                                                <span></span>
                                                <p className="mb-0 ml-3">100% EYE Clean</p>
                                            </label>
                                            {formik.touched.eyeClean &&
                                            formik.errors.eyeClean ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.eyeClean}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="pr-3"
                                                    name="noBroken"
                                                    {...formik.getFieldProps("noBroken" )}
                                                />
                                                <span></span>
                                                <p className="mb-0 ml-3">No Broken</p>
                                            </label>
                                            {formik.touched.noBroken &&
                                            formik.errors.noBroken ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.noBroken}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="acceptTerms"
                                                    className="m-1"
                                                    {...formik.getFieldProps("acceptTerms")}
                                                />
                                                <span></span>
                                                <p className="mb-0 ml-3">
                                                    I agree the
                                                    <a
                                                        href="/terms"
                                                        target="_blank"
                                                        className="ml-1"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Terms & Conditions
                                                    </a>
                                                </p>
                                            </label>
                                            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.acceptTerms}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {formik.status && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.status}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group mb-1">
                                            <label htmlFor="comments">
                                                Comments
                                            </label>
                                            <textarea
                                                className={`form-control ${getInputClasses("comments")}`}
                                                name="comments"
                                                id="comments"
                                                placeholder="comments"
                                                rows="3"
                                                {...formik.getFieldProps("comments")}
                                            ></textarea>
                                        </div>
                                        <div className="form-group d-flex justify-content-end mb-2">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="py-3 justify-content-between">
                        <Button
                            variant="danger"
                            className="font-weight-bold"
                            onClick={() => closeModel(props)}
                        >
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9"
                        >
                            <span>Approve hold request</span>
                            {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};
