import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios"
import {NODE_API_URL} from "../../../../env_config";
import "../../../../../node_modules/react-phone-input-2/lib/style.css";
import {DropzoneArea} from 'material-ui-dropzone'
import { FaCloudUploadAlt } from 'react-icons/fa';

function ContactForm({userinfo}) {
// const [open,setOpen] = useState(false)
const [contactFile,setContactFile] = useState(null)
// console.log(userinfo)
const initialValues = {
    companyname: userinfo.supplier_name,
    fullname: `${userinfo.firstname} ${userinfo.lastname}`,
    email: userinfo.email,
    category: "",
    message: "",
    Attachment:"",
};

    const [loading, setLoading] = useState(false);
    const [success , setSuccess] = useState(false)

    const ContactSchema = Yup.object().shape({
        companyname: Yup.string().required("Company Name is Required"),
        fullname: Yup.string().required("Last Name is Required"),
        email: Yup.string()
            .email("Wrong email format")
            .required("email is Required"),
        category: Yup.string().required("Catagory is Required"),
        message: Yup.string().required("Message is Required"),
    });
    
    function handleSave(files) {

        if(files.length !== 0 ){
            // console.log("files",files[0].path)
            const filename = files[0].path
            const file = new File(files, filename);
            setContactFile(files[0].path)
        }
    }
    // console.log(contactFile)
    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: ContactSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // console.log("values",values)
            const formData = new FormData();
            formData.append("contactFile", contactFile)
            const DataObj = {
                companyname:values.companyname,
                fullname:values.fullname,
                email:values.email,
                catagory:values.category,
                message:values.message
            }

            for ( var key in DataObj ) {
                formData.append(key, DataObj[key]);
            }

            // console.log(DataObj,"DataObj")
            // console.log(contactFile,"contactFile")

            // setTimeout(() => {
            // axios({
            //     method: 'POST',
            //     url: `${NODE_API_URL}/SUPP/registration`,
            //     headers: { 
            //         'Content-Type': 'multipart/form-data'
            //     },
            //     data: formData
            // })
            //     .then(function (res) {
            //         console.log(res)
            //     })
            //     .catch((res) => {
                //     console.log(res.response)
            //     });
            // }, 1000);
            
        },
    });

    return (
        <div className="card card-custom card-stretch">
            <div className="card-header">
                <div className="card-title">Generate Support Ticket</div>
            </div>
            <div className="card-body">
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {formik.status}
                        </div>
                    </div>
                )}
               <>
                    <form
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        onSubmit={formik.handleSubmit}
                    >
                        {/* begin: Alert */}
                        {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">
                                    {formik.status}
                                </div>
                            </div>
                        )}
                        {/* end: Alert */}
                        <div className="row">
                            {/* begin: companyname */}
                            <div className="form-group col-12 fv-plugins-icon-container">
                                <div className="row align-items-center">
                                    <label className="form-label col-md-4 font-weight-bold" htmlFor="companyname">Company Name</label>
                                    <div className="col-md-8">
                                        <input
                                            placeholder="Company Name"
                                            type="text"
                                            className={`form-control h-auto py-4 px-6 ${getInputClasses(
                                                "companyname"
                                            )}`}
                                            name="companyname"
                                            value={userinfo.supplier_name}
                                            disabled
                                            {...formik.getFieldProps("companyname")}
                                        />
                                        {formik.touched.companyname && formik.errors.companyname ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.companyname}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* end:companyname */}
        
                            {/* begin: fullname */}
                            <div className="form-group col-12 fv-plugins-icon-container">
                               <div className="row align-items-center" >
                                    <label className="form-label col-md-4 font-weight-bold" htmlFor="companyname">Full Name</label>
                                    <div className="col-md-8">
                                        <input
                                            placeholder="fullname"
                                            type="fullname"
                                            className={`form-control h-auto py-4 px-6 ${getInputClasses(
                                                "fullname"
                                            )}`}
                                            value={`${userinfo.firstname} ${userinfo.lastname}`}
                                            disabled
                                            name="fullname"
                                            {...formik.getFieldProps("fullname")}
                                        />
                                        {formik.touched.fullname && formik.errors.fullname ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.fullname}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                               </div>
                            </div>
                            {/* end: fullname */}
                            {/* begin: email */}
                            <div className="form-group col-12 fv-plugins-icon-container">
                                <div className="row">
                                    <label className="form-label col-md-4 font-weight-bold" htmlFor="email">Email</label>
                                    <div className="col-md-8">
                                        <input
                                            placeholder="email"
                                            type="email"
                                            className={`form-control h-auto py-4 px-6 ${getInputClasses(
                                                "email"
                                            )}`}
                                            name="email"
                                            disabled
                                            {...formik.getFieldProps("email")}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.email}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* end: email */}
                            {/* begin: Category */}
                            <div className="form-group col-12 fv-plugins-icon-container">
                                <div className="row align-items-center">
                                    <label className="form-label col-md-4 font-weight-bold" htmlFor="category"> Category </label>
                                    <div className="col-md-8">
                                        <select className="form-control form-control-lg "
                                            name="category"
                                            {...formik.getFieldProps(
                                                "category"
                                            )}>
    										<option value="">Select Category</option>
    										<option>Diamond upload</option>
    										<option>Search diamond</option>
    										<option>Image & video</option>
    										<option>Orders</option>
    										<option>Delivery</option>
    										<option>Payments</option>
    										<option>Others</option>
    									</select>
                                        {formik.touched.category && formik.errors.category ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.category}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* end: Category */}
                            {/* begin: email */}
                            <div className="form-group col-12 fv-plugins-icon-container">
                                <div className="row">
                                    <label className="form-label col-md-4 font-weight-bold" htmlFor="message">Message *</label>
                                    <div className="col-md-8">
                                        <textarea
                                            placeholder="Enter your Message"
                                            className={`form-control h-auto py-4 px-6 ${getInputClasses(
                                                "message"
                                            )}`}
                                            name="message"
                                            rows="4"
                                            {...formik.getFieldProps("message")}
                                        />
                                        {formik.touched.message && formik.errors.message ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.message}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* end: email */}
                            {/* begin: email */}
                            <div className="form-group col-12 fv-plugins-icon-container">
                                <div className="row">
                                    <label className="form-label col-md-4 font-weight-bold" htmlFor="Attachment">Attachment *</label>
                                    <div className="col-md-8 contactUpload">
                                        <DropzoneArea
                                            // onSave={handleSave}
                                            // acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                            onChange={handleSave}
                                            showPreviews={false}
                                            maxFileSize={2100000}
                                            showFileNames={true}
                                            filesLimit={1}
                                            showFileNamesInPreview={false}
                                            showPreviewsInDropzone={false}
                                            previewText={true}
                                            dropzoneText={
                                                <div>
                                                    <FaCloudUploadAlt className="text-primary font-size-h2 mr-2" /> Drag & Drop here or <span className="text-primary cursor-pointer">Browse</span >
                                                </div>}
                                        />
                                        {contactFile ? <span>{contactFile}</span>: ""}
                                        <div className="custom-file input-group-solid text-truncate">
                                            {/* <input
                                                type="file"
                                                className={`form-control form-control-lg custom-file-input form-control-solid ${getInputClasses(
                                                    "Attachment"
                                                )}`}
                                                id="Attachment"
                                                name="Attachment"
                                                {...formik.getFieldProps(
                                                    "Attachment"
                                                )}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="Attachment"
                                            >
                                                Choose Attachment
                                            </label> */}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end: email */}
                        </div>
        
                        <div className="form-group d-flex justify-content-end mb-2">
                            <button
                                type="submit"
                                className="btn btn-primary font-weight-bold px-9"
                            >
                                <span>Send</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>
                        </div>
                    </form>
               </>
            </div>
        </div>
    );
}

export default ContactForm;
