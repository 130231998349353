import React from "react";
import { Link } from "react-router-dom";
// import {BASE_URL_OLD} from "../../../../env_config"
import Scrollspy from 'react-scrollspy'

export const ParameterHelp = () => {


    return (
        <>
           <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">Parameters</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span> Parameters</span>
                    </div>
                </div>
                <div className="col-12">
                <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <div className="card card-custom card-stretch">
                                <div className="card-body px-1">
                                    <div className="sticky-top" style={{top:'100px'}}>
                                        <Scrollspy className="help-scrollspy" items={ ['Parameters','RoundDiamond', 'FancyShapeDiamond', 'FancyColoredDiamond'] } currentClassName="is-current">
                                            <li><a href="#Parameters">Parameters</a></li>
                                            <li><a href="#RoundDiamond">Round Diamond</a></li>
                                            <li><a href="#FancyShapeDiamond">Fancy Shape Diamond</a></li>
                                            <li><a href="#FancyColoredDiamond">Fancy Colored Diamond</a></li>
                                        </Scrollspy>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10">
                            <div className="card card-custom card-stretch">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 boreder-bottom mb-5" id="Parameters">
                                            <h3 ><strong>Parameters</strong></h3>
                                            <p>In Diamond On Call Supplier Centre, each Diamond needs to certified It undergoes a thorough, independent evaluation. There are certain parameters for each Diamond type which affects the Diamond's price. The parameters are listed in each of the page assigned to that particular type of diamond. Make sure that the document uploaded via <b>Upload Diamonds</b> page is in sync with the parameter information mentioned here for each type of diamond.</p>
                                            <p>Each type of diamond has a table with the Field Names and Acceptable Field Values.</p>
                                            <p>To upload Diamond using Excel, FTP or API, follow the parameters below.</p>
                                        </div>
                                        <div className="col-12 border-bottom mb-5" id="RoundDiamond">
                                            <h3 ><strong>Round Diamond</strong></h3>
                                            <p>It is a round super-ideal cut diamond. It is most popular cut in rings, followed by the princess cut and the cushion cut. It has 57 angular facets divided amongst its crown, girdle and pavillion.</p>
                                            <div className="table-responsive">
                                                <h4><b>Round Diamond</b></h4>
                                                <p>It is a round super-ideal cut diamond. It is most popular cut in rings, followed by the princess cut and the cushion cut. It has 57 angular facets divided amongst its crown, girdle and pavillion.</p>
                                                <table className="table table-striped helpTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Field Names</th>
                                                            <th>Acceptable Field values</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-primary"><b>Stock #</b></td>
                                                            <td>Supplier Stock ID of Diamond</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Availability</b></td>
                                                            <td>Guaranteed Availability =<b> G, AVAILABLE, A, AV, GA</b><br />
                                                                MEMO, BUSY, HOLD =<b> B, M, H, NA</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Shape</b></td>
                                                            <td>Round =<b> B, BR, RB, RBC, R, RD, ROUND BRILLIANT, RND</b><br />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Weight</b></td>
                                                            <td><b>0.18 - 10+ ct</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Color</b></td>
                                                            <td><b>D, E, F, G, H, I, J, K, L, M, N, OP, QR, ST, UV, WX, YZ</b><br/>* To enter two colors, separate with a hyphen or no space.Applied after color <b>"N"</b>.<br/><em>For Example, "O-P" or "OP"</em>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Clarity</b></td>
                                                            <td><b>FL, IF, VVS1, VVS2, VS1, VS2, SI1, SI2, I1, I2, I3</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Cut </b></td>
                                                            <td>Ideal =<b> I, ID</b><em>( Will be in Excellent)</em><br />
                                                                Excellent =<b> EX, EXC</b><br />
                                                                Very Good =<b> VG</b><br />
                                                                Good =<b> G, GD</b><br />
                                                                Fair =<b> F, FR</b><br />
                                                                Poor =<b> P, PR</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Polish</b></td>
                                                            <td>Excellent =<b> EX, EXC</b><br />
                                                                Very Good =<b> VG</b><br />
                                                                Good =<b> G, GD</b><br />
                                                                Fair =<b> F, FR</b><br />
                                                                Poor =<b> P, PR</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Symmetry</b></td>
                                                            <td>Excellent =<b> EX, EXC</b><br />
                                                                Very Good =<b> VG</b><br />
                                                                Good =<b> G, GD</b><br />
                                                                Fair =<b> F, FR</b><br />
                                                                Poor =<b> P, PR</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fluorescence Intensity</b></td>
                                                            <td>None =<b> N, NON, NIL, NONE</b><br />
                                                                Very Strong =<b> VS, VST</b><br />
                                                                Strong =<b> S, STG, ST</b><br />
                                                                Medium =<b> M, MED</b><br />
                                                                Faint =<b> F, FNT</b><br />
                                                                Slight =<b> SL, SLT, SLI</b><br />
                                                                Very Slight =<b> VSL, VSLI, VSLT</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Fluorescence Color</b><br/><em> (can be left blank)</em></td>
                                                            <td>Blue =<b> B</b><br />
                                                                White =<b> W</b><br />
                                                                Yellow =<b> Y</b><br />
                                                                Orange =<b> O</b><br />
                                                                Red =<b> R</b><br />
                                                                Green =<b> G</b><br />
                                                                None, NIL, NON =<b> N</b><br /><br />
                                                                * Default is "N" if left blank.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Measurements</b></td>
                                                            <td>Symbols accepted  are <b>- * x</b><br/><em>For Example, 6.40 x 6.43 * 3.97  or  6.40 - 6.43 x 3.97</em></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Lab</b></td>
                                                            <td>GIA =<b> G, GIA</b><br />
                                                                HRD =<b> H, HRD</b><br />
                                                                IGI =<b> I, IGI</b><br />
                                                                AGS =<b> A, AGS</b><br />
                                                                GCAL =<b> GCAL</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Certificate #</b></td>
                                                            <td>Enter the number on the certificate from the grading lab.</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>$/CT</b></td>
                                                            <td>Price per carat (only in USD)<br/><br />
                                                                Enter numbers with 2 decimals with no sign of currency.<br/>Do not Include "$" Sign.<br/> Total price will be calculated by multiplying $/CT & Carat Weight</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color</b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color Intensity</b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color Overtone</b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Depth %</b><br/></td>
                                                            <td>Example: 62.4 *<br /><br />
                                                                *Give percentage with only one number after the decimal.<br /> Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Table %</b><br/></td>
                                                            <td>Example:  55.5  *<br /><br />
                                                                *Give percentage with no digit after decimal.<br /> Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Thin</b><br/><em>(can be left blank)</em></td>
                                                            <td>Extremely Thick =<b> XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK, XK </b><br />
                                                                Very Thick =<b> VTK, VTHCK, VTHK, VTHICK</b><br />
                                                                Thick =<b> TK, THK, THIK, THIC</b><br />
                                                                Slightly Thick =<b> STK, SLTK, SLTHK</b><br />
                                                                Medium =<b> M, MED, MD</b><br />
                                                                Thin =<b> TN, THN</b><br />
                                                                Slightly Thin =<b> STN, SLTN, SLTHN</b><br />
                                                                Very Thin =<b> VTN, VTHN, VTHIN, VN</b><br />
                                                                Extremely Thin =<b> XTN, XTHN, EXTN, ETN, EN, X</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Thick</b><br/><em>(can be left blank)</em></td>
                                                            <td>Extremely Thick =<b> XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK, XK</b><br />
                                                                Very Thick =<b> VTK, VTHCK, VTHK, VTHICK</b><br />
                                                                Thick =<b> TK, THK, THIK, THIC</b><br />
                                                                Slightly Thick =<b> STK, SLTK, SLTHK</b><br />
                                                                Medium =<b> M, MED, MD</b><br />
                                                                Thin =<b> TN, THN</b><br />
                                                                Slightly Thin =<b> STN, SLTN, SLTHN</b><br />
                                                                Very Thin =<b> VTN, VTHN, VTHIN, VN</b><br />
                                                                Extremely Thin =<b> XTN, XTHN, EXTN, ETN, EN, X</b><br /><br />
                                                                * Enter both Girdle Thin and Girdle Thick in this different field.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle %</b><br/><em>(can be left blank)</em></td>
                                                            <td>Example: 3.5*<br/><br/>
                                                                * Must be a positive number. Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Condition</b><br/><em>(can be left blank)</em></td>
                                                            <td>Polished  =<b> P</b><br />
                                                                Faceted =<b> F</b><br />
                                                                Bruted  =<b> B</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Culet Condition</b><br/><em>(can be left blank)</em></td>
                                                            <td>Polished  =<b> P</b><br />
                                                                Abraded =<b> A</b><br />
                                                                Chipped =<b> C</b><br />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td ><b className="text-primary">Crown Height</b><br/></td>
                                                            <td>Example: 14.5*<br/><br/>
                                                                *Give percentage with only one number after the decimal. Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Crown Angle</b><br/></td>
                                                            <td>Example: 36.3*<br/><br/>
                                                                * Give a value to one decimal place. Do not include any degree symbol .
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Pavilion Depth</b><br/></td>
                                                            <td>Example: 42.5*<br/><br/>
                                                                * Give a value to one decimal place. Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Pavilion Angle</b><br/></td>
                                                            <td>Example: 40.6*<br/><br/>
                                                                * Give a value to one decimal place. Do not include any degree symbol .
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Country</b><br/></td>
                                                            <td>India =<b> IN, Mumbai, Mum</b><br />
                                                                Israel =<b> IS, ISR</b><br />
                                                                USA =<b> US, USA, NY, LA</b><br />
                                                                Hong Kong = <b> HK</b><br />
                                                                Belgium = <b> BL, BG, ANT</b><br />
                                                                United Kingdom = <b>UK, London</b><br /><br />
                                                                Other Countries = <b> Mention the full name of country</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">DiamondImage</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Video</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter a URL of the Video.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Heart Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Arrow Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Aset Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Key to symbols</b><br/><em>(can be left blank)</em></td>
                                                            <td>Bearding, Brown patch of color, Bruise, Cavity, Chip, Cleavage, Cloud, Crystal, Crystal Surface, Etch Channel, Extra Facet, Feather, Flux Remnant, Indented Natural, Internal Graining, Internal Inscription, Internal Laser Drilling, Knot, Laser Drill Hole, Manufacturing Remnant, Minor Details of Polish, Natural, Needle, No Inclusion, Pinpoint, Reflacting Surface Graining, Surface Graining, Twinning Wisp
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Report Comments</b><br/><em>(can be left blank)</em></td>
                                                            <td>Comments or Remarks mentioned in the Certificate
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Shade</b><br/><em>(can be left blank)</em></td>
                                                            <td>
                                                                None =<b> noshade, white, yellow</b><br />
                                                                BROWN =<b> brown,b2,brn</b><br />
                                                                LIGHT BROWN =<b> LB, BRN1, B1</b><br />
                                                                GREEN =<b> green, mediumgreen, gy</b><br />
                                                                LIGHT GREEN =<b> faintgreen, lightgreen, vlg</b><br />
                                                                GREY =<b> faintgrey, lightgrey, gry</b><br />
                                                                BLACK =<b> black, faintblack, lightblack</b><br />
                                                                PINK =<b> pink, lightpink, pinkish</b><br />
                                                                BLUE =<b> blue, lightblue, faintblue</b><br />
                                                                MIX TINGE =<b> mixtinch, mixt, mix</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Milky</b><br/><em>(can be left blank)</em></td>
                                                            <td>NO MILKY =<b> EX, NO, N, NONE, NON</b><br />
                                                                LIGHT MILKY =<b> VG, L, M1, Light</b><br />
                                                                MILKY =<b> Yes, M2</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Eye Clean</b><br/><em>(can be left blank)</em></td>
                                                            <td>100% Eye Clean =<b> Yes, Y, 100</b><br />
                                                                Not 100% Eye Clean =<b> No, N</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b >Brand</b><br/><i>(can be left blank)</i></td>
                                                            <td>
                                                                Argyle =<b>Argyle</b><br />
                                                                Canada Mark =<b> CM, CME, C</b><br />
                                                                Forever Mark =<b> FM, F</b><br />
                                                                88 Cut =<b>88 cut</b><br />
                                                                Arctic Fox =<b>  Arctic Fox</b><br />
                                                                Canadian Ice =<b>  Canadian Ice</b><br />
                                                                Heart's On Fire =<b>Heart's On Fire</b><br />
                                                                Polar Bear =<b> Polar Bear</b><br />
                                                                Polar Ice =<b> Polar Ice</b><br />
                                                                Canada Mark eligible=<b> Canada Mark eligible</b><br />
                                                                other =<b>O, X</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b >Origin</b><br/><i>(can be left blank)</i></td>
                                                            <td>
                                                                Australia =<b>AU, AUS</b><br />
                                                                Botswana =<b>BW, BWA, Botswana DTC</b><br />
                                                                Brazil =<b>BR, BRA</b><br />
                                                                Canada =<b>CA, CAN, Canadian</b><br />
                                                                Congo =<b>CG, COG</b><br />
                                                                DTC =<b>Diamond Trading Company</b><br />
                                                                Lesotho =<b>LS, LSO</b><br />
                                                                Liberia =<b>LR, LBR</b><br />
                                                                Namibia =<b>NA, NAM</b><br />
                                                                Russia =<b>RU, RUS, Alrosa</b><br />
                                                                Sierra Leone =<b>SL, SLE</b><br />
                                                                South africa =<b>ZA, ZAF, BONAS</b><br />
                                                                Tanzania =<b>TZ, TZA</b><br />
                                                                Zimbabwe =<b>ZW, XWA</b><br />
                                                                ANGOLA = <b>Angola</b><br />
                                                                Other =<b>OTHERS</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Growth type</b><br/><i>(can be left blank)</i></td>
                                                            <td>
                                                                CVD =<b>C</b><br />
                                                                HPHT =<b>H</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Treatment</b></td>
                                                            <td>
                                                                As Grown =<b>As Grown</b><br />
                                                                Laser Drilled =<b>LD, Laser</b><br />
                                                                Irradiated =<b>IRR</b><br />
                                                                Clarity Enhanced =<b>CE, Fracture Filled</b><br />
                                                                Color Enhanced =<b>COL</b><br />
                                                                High Pressure High Temperature =<b>HPHT</b><br />
                                                                Other =<b>O</b><br />
                                                                None(blank) =<b>N</b><br />
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 border-bottom mb-5" id="FancyShapeDiamond">
                                            <h3 ><strong>Fancy Shape Diamond</strong></h3>
                                            <p>The diamond which are not round are referred as fancy shaped diamond. It has various options like princess, emerald, cushion, marquise, radiant, oval, pear, heart etc.</p>
                                            <div className="table-responsive">
                                                <table class="table helpTable table-striped" >
                                                    <thead>
                                                        <tr>
                                                            <th>Field Names</th>
                                                            <th>Acceptable Field values</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-primary"><b>Stock #</b></td>
                                                            <td>Supplier Stock ID of Diamond</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Availability</b></td>
                                                            <td>Guaranteed Availability = <b>G, AVAILABLE, A , AV , GA</b><br />
                                                                MEMO, BUSY, HOLD = <b>B, M, H, NA</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Shape</b></td>
                                                            <td>

                                                                Pear =<b>Â P, PS, PB, PMB, PE</b><br />
                                                                Emerald =<b>Â E, EM, EC</b><br />
                                                                Square Emerald, Asscher =Â <b>Â SQE, SQEM,Â A, AC, AS, SQ EMERALD, SE, SEM</b><br />
                                                                Princess =<b>Â PRN, PR, PRIN, PC, SMB, SQUARE MODIFIED, SQUARE MODIFIED BRILLIANT</b><br />
                                                                Marquise =<b> M, MQ, MQB, MB</b><br />
                                                                Cushion =<b> Cushion Brilliant, CB, C, CU, CUSH, CUBR</b><br />
                                                                Cushion Modified =<b> CM, CMB, CSMB</b><br />
                                                                Heart =<b> H, HS, HT, HB, HRT</b><br />
                                                                Oval =<b> O, OV, OMB, OB</b><br />
                                                                Long Radiant =<b> RT, RAD, RA, RN, RADIANT, LR_BRILLIANT</b><br />
                                                                Square Radiant =<b> SR, Sq Radiant, SQR, SQRA</b><br />
                                                                Trilliant =<b> T, TR, TRIL,TRL</b><br />
                                                                Triangle= <b> TA, TRA, TRI</b><br />
                                                                Other = <b> Any other code will go under Other shapes , X</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Weight</b></td>
                                                            <td><b>0.18 - 10+ ct</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Color</b></td>
                                                            <td><b>D, E, F, G, H, I, J, K, L, M, N, OP, QR, ST, UV, WX, YZ</b><br/>
                                                                * To enter two colors, separate with a hyphen or no space.Applied after color <b>"N"</b>.<br/>For Example, "O-P" or "OP"</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Clarity</b></td>
                                                            <td><b>FL, IF,  VVS1, VVS2, VS1, VS2, SI1, SI2, I1,  I2, I3</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Cut </b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Polish</b></td>
                                                            <td>Excellent =<b> EX, EXC</b><br />
                                                                Very Good =<b> VG</b><br />
                                                                Good =<b> G, GD</b><br />
                                                                Fair =<b> F, FR</b><br />
                                                                Poor =<b> P, PR</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Symmetry</b></td>
                                                            <td>Excellent =<b> EX, EXC</b><br />
                                                                Very Good =<b> VG</b><br />
                                                                Good =<b> G, GD</b><br />
                                                                Fair =<b> F, FR</b><br />
                                                                Poor =<b> P, PR</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fluorescence Intensity</b></td>
                                                            <td>None =<b> N, NON, NIL, NONE</b><br />
                                                                Very Strong =<b> VS, VST</b><br />
                                                                Strong =<b> S, STG, ST</b><br />
                                                                Medium =<b> M, MED</b><br />
                                                                Faint =<b> F ,FNT</b><br />
                                                                Slight =<b> SL, SLT, SLI</b><br />
                                                                Very Slight =<b> VSL, VSLI, VSLT</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Fluorescence Color</b><br/><em> (can be left blank)</em></td>
                                                            <td>Blue =<b> B</b><br />
                                                                White =<b> W</b><br />
                                                                Yellow =<b> Y</b><br />
                                                                Orange =<b> O</b><br />
                                                                Red =<b> R</b><br />
                                                                Green =<b> G</b><br />
                                                                None,NIL,NON =<b> N</b><br /><br />
                                                                * Default is "N" if left blank.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Measurements</b></td>
                                                            <td>Symbols accepted are <b>- * x</b><br/>
                                                                <em>For Example 6.40 x 6.43 * 3.97  or  6.40 - 6.43 x 3.97</em></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Lab</b></td>
                                                            <td>GIA =<b> G, GIA</b><br />
                                                                HRD =<b> H, HRD</b><br />
                                                                IGI =<b> I, IGI</b><br />
                                                                AGS =<b> A, AGS</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Certificate #</b></td>
                                                            <td>Enter the number on the certificate from the grading lab.</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>$/CT</b></td>
                                                            <td>Price per carat (only in USD)<br/><br />
                                                                Enter numbers with 2 decimals with no sign of currency.<br/>Do not Include "$" Sign.<br/> Total price will be calculated by multiplying $/CT & Carat Weight</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color</b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color Intensity</b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color Overtone</b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Depth %</b><br/></td>
                                                            <td>Example: 62.4 *<br /><br />
                                                                *Give percentage with only one number after the decimal.<br /> Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Table %</b><br/></td>
                                                            <td>Example: 55.5 *<br /><br />
                                                                *Give percentage with no digit after decimal.<br /> Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Thin</b><br/><em>(can be left blank)</em></td>
                                                            <td>Extremely Thick =<b> XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK, XK</b><br />
                                                                Very Thick =<b> VTK, VTHCK, VTHK, VTHICK</b><br />
                                                                Thick =<b> TK, THK, THIK, THIC</b><br />
                                                                Slightly Thick =<b> STK, SLTK, SLTHK</b><br />
                                                                Medium =<b> M, MED, MD</b><br />
                                                                Thin =<b> TN, THN</b><br />
                                                                Slightly Thin =<b> STN, SLTN, SLTHN</b><br />
                                                                Very Thin =<b> VTN, VTHN, VTHIN, VN</b><br />
                                                                Extremely Thin =<b> XTN, XTHN, EXTN, ETN, EN, X</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Thick</b><br/><em>(can be left blank)</em></td>
                                                            <td>Extremely Thick =<b> XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK, XK</b><br />
                                                                Very Thick =<b> VTK, VTHCK, VTHK, VTHICK</b><br />
                                                                Thick =<b> TK, THK, THIK, THIC</b><br />
                                                                Slightly Thick =<b> STK, SLTK, SLTHK</b><br />
                                                                Medium =<b> M, MED, MD</b><br />
                                                                Thin =<b> TN, THN</b><br />
                                                                Slightly Thin =<b> STN, SLTN, SLTHN</b><br />
                                                                Very Thin =<b> VTN, VTHN, VTHIN, VN</b><br />
                                                                Extremely Thin =<b> XTN, XTHN, EXTN, ETN, EN, X</b><br /><br />
                                                                * Enter both Girdle Thin and Girdle Thick in this different field.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle %</b><br/><em>(can be left blank)</em></td>
                                                            <td>Example: 3.5*<br/><br/>
                                                                * Must be a positive number. Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Condition</b><br/><em>(can be left blank)</em></td>
                                                            <td>Polished  =<b> P</b><br />
                                                                Faceted =<b> F</b><br />
                                                                Bruted  =<b> B</b><br />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td ><b className="text-primary">Culet Condition</b><br/><em>(can be left blank)</em></td>
                                                            <td>Polished  =<b> P</b><br />
                                                                Abraded =<b> A</b><br />
                                                                Chipped =<b> C</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Crown Height</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Crown Angle</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Pavilion Depth</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Pavilion Angle</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Country</b><br/></td>
                                                            <td>India =<b> IN, Mumbai, Mum</b><br />
                                                                Israel =<b> IS, ISR</b><br />
                                                                USA =<b> US, USA, NY, LA</b><br />
                                                                Hong Kong =<b> HK</b><br />
                                                                Belgium =<b> BL, BG, ANT</b><br />
                                                                United Kingdom =<b> UK, London</b><br /><br />
                                                                Other Countries =<b> Mention the full name of country</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">DiamondImage</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Video</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter a URL of the Video.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Heart Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Arrow Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Aset Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Key to symbols</b><br/><em>(can be left blank)</em></td>
                                                            <td>Bearding, Brown patch of color, Bruise, Cavity, Chip, Cleavage, Cloud, Crystal, Crystal Surface, Etch Channel, Extra Facet, Feather, Flux Remnant, Indented Natural, Internal Graining, Internal Inscription, Internal Laser Drilling, Knot, Laser Drill Hole, Manufacturing Remnant, Minor Details of Polish, Natural, Needle, No Inclusion, Pinpoint, Reï¬‚ecting Surface Graining, Surface Graining, Twinning Wisp
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Report Comments</b><br/><em>(can be left blank)</em></td>
                                                            <td>Comments or Remarks mentioned in the Certificate
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Shade</b><br/><em>(can be left blank)</em></td>
                                                            <td>
                                                                None =<b> noshade, white, yellow</b><br />
                                                                BROWN =<b> brown,b2,brn</b><br />
                                                                LIGHT BROWN =<b> LB, BRN1, B1</b><br />
                                                                GREEN =<b> green, mediumgreen, gy</b><br />
                                                                LIGHT GREEN =<b> faintgreen, lightgreen, vlg</b><br />
                                                                GREY =<b> faintgrey, lightgrey, gry</b><br />
                                                                BLACK =<b> black, faintblack, lightblack</b><br />
                                                                PINK =<b> pink, lightpink, pinkish</b><br />
                                                                BLUE =<b> blue, lightblue, faintblue</b><br />
                                                                MIX TINGE =<b> mixtinch, mixt, mix</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Milky</b><br/><em>(can be left blank)</em></td>
                                                            <td>NO MILKY =<b> EX, NO, N, NONE, NON</b><br />
                                                                LIGHT MILKY =<b> VG, L, M1, Light</b><br />
                                                                MILKY =<b> Yes, M2</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Eye Clean</b><br/><em>(can be left blank)</em></td>
                                                            <td>100% Eye Clean =<b> Yes, Y, 100</b><br />
                                                                Not 100% Eye Clean =<b> No, N</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Growth type</b><br/><em>(can be left blank)</em></td>
                                                            <td>
                                                                CVD =<b>C</b><br />
                                                                HPHT =<b>H</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Treatment</b></td>
                                                            <td>
                                                                As Grown =<b>As Grown</b><br />
                                                                Laser Drilled =<b>LD, Laser</b><br />
                                                                Irradiated =<b>IRR</b><br />
                                                                Clarity Enhanced =<b>CE, Fracture Filled</b><br />
                                                                Color Enhanced =<b>COL</b><br />
                                                                High Pressure High Temperature =<b>HPHT</b><br />
                                                                Other =<b>O</b><br />
                                                                None(blank) =<b>N</b><br />
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 border-bottom mb-5" id="FancyColoredDiamond">
                                            <h3 ><strong>Fancy Colored Diamond</strong></h3>
                                            <p>Fancy coloured diamonds are extremely rare which are the most valuable variety of diamonds. Their most important feature is their striking colour, which can range from pink to yellow and even chameleon. It is very a unique piece of jewellery with its rare color.</p>
                                            <div class="table-responsive">
                                                <table class="table helpTable table-striped" >
                                                    <thead>
                                                        <tr>
                                                            <th>Field Names</th>
                                                            <th>Acceptable Field values</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-primary"><b>Stock #</b></td>
                                                            <td>Supplier Stock ID of Diamond</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Availability</b></td>
                                                            <td>Guaranteed Availability = <b> G, AVAILABLE, A, AV, GA</b><br />
                                                                MEMO, BUSY, HOLD =<b> B, M, H, NA</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Shape</b></td>
                                                            <td>
                                                                Round =<b> B, BR, RB, RBC, R, RD, ROUND BRILLIANT, RND</b><br />
                                                                Pear =<b>Â P, PS, PB, PMB, PE</b><br />
                                                                Emerald =<b> E, EM, EC</b><br />
                                                                Square Emerald, Asscher =Â <b> SQE, SQEM,Â A, AC, AS, SQ EMERALD, SE, SEM</b><br />
                                                                Princess =<b>Â PRN, PR, PRIN, PC, SMB, SQUARE MODIFIED, SQUARE MODIFIED BRILLIANT</b><br />
                                                                Marquise =<b> M, MQ, MQB, MB</b><br />
                                                                Cushion =<b> Cushion Brilliant, CB, C, CU, CUSH, CUBR</b><br />
                                                                Cushion Modified =<b> CM, CMB, CSMB</b><br />
                                                                Heart =<b>Â H, HS, HT, HB, HRT</b><br />
                                                                Oval =<b> O, OV, OMB, OB</b><br />
                                                                Long Radiant =<b>Â RT, RAD, RA, RN, RADIANT, LR_BRILLIANT</b><br />
                                                                Square Radiant =<b> SR, Sq Radiant, SQR, SQRA</b><br />
                                                                Trilliant =<b> T, TR, TRIL,TRL</b><br />
                                                                Triangle=<b> TA, TRA, TRI</b><br />
                                                                Other =<b> Any other code will go under Other shapes , X</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Weight</b></td>
                                                            <td><b>0.18 - 10+ ct</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Color</b></td>
                                                            <td><b>FANCY , *</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Clarity</b></td>
                                                            <td><b>FL, IF,  VVS1, VVS2, VS1, VS2, SI1, SI2, I1,  I2, I3</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Cut </b></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Polish</b></td>
                                                            <td>Excellent =<b> EX, EXC</b><br />
                                                                Very Good =<b> VG</b><br />
                                                                Good =<b> G, GD</b><br />
                                                                Fair =<b> F, FR</b><br />
                                                                Poor =<b> P, PR</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Symmetry</b></td>
                                                            <td>Excellent =<b> EX, EXC</b><br />
                                                                Very Good =<b> VG</b><br />
                                                                Good =<b> G, GD</b><br />
                                                                Fair =<b> F, FR</b><br />
                                                                Poor =<b> P, PR</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fluorescence Intensity</b></td>
                                                            <td>None =<b>Â N, NON, NIL, NONE</b><br />
                                                                Very Strong =<b> VS, VST</b><br />
                                                                Strong =<b> S, STG, ST</b><br />
                                                                Medium =<b> M, MED</b><br />
                                                                Faint =<b> F ,FNT</b><br />
                                                                Slight =<b> SL, SLT, SLI</b><br />
                                                                Very Slight =<b> VSL, VSLI, VSLT</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Fluorescence Color</b><br/><em> (can be left blank)</em></td>
                                                            <td>Blue =<b> B</b><br />
                                                                White =<b> W</b><br />
                                                                Yellow =<b> Y</b><br />
                                                                Orange =<b> O</b><br />
                                                                Red =<b> R</b><br />
                                                                Green =<b> G</b><br />
                                                                None,NIL,NON =<b> N</b><br /><br />
                                                                * Default is "N" if left blank.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Measurements</b></td>
                                                            <td>Symbols accepted  are <b>- * x</b><br/>
                                                                <em>For Example, 6.40 x 6.43 * 3.97  or  6.40 - 6.43 x 3.97</em>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Lab</b></td>
                                                            <td>GIA =<b> G, GIA</b><br />
                                                                HRD =<b> H, HRD</b><br />
                                                                IGI =<b> I, IGI</b><br />
                                                                AGS =<b> A, AGS</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Certificate #</b></td>
                                                            <td>Enter the number on the certificate from the grading lab.</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>$/CT</b></td>
                                                            <td>Price per carat (only in USD)<br/><br />
                                                                Enter numbers with 2 decimals with no sign of currency.<br/>Do not Include "$" Sign.<br/> Total price will be calculated by multiplying $/CT & Carat Weight</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color</b></td>
                                                            <td>Black =<b> BK</b><br />
                                                                Blue =<b> B</b><br />
                                                                Brown =<b> BN</b><br />
                                                                Chameleon =<b> CH</b><br />
                                                                Cognac =<b> CG</b><br />
                                                                Gray, Grey =<b> GY</b><br />
                                                                Green =<b> KG</b><br />
                                                                Orange =<b> O</b><br />
                                                                Pink =<b> P</b><br />
                                                                Purple =<b> PL</b><br />
                                                                Red =<b> R</b><br />
                                                                Violet =<b> V</b><br />
                                                                Yellow =<b> Y</b><br />
                                                                White =<b> W</b><br />
                                                                Other,OT =<b> X</b><br /><br />
                                                                * To enter two colors, separate with a hyphen or a space.<br/><br/>
                                                                For Example: Blue-Green, Blue Green, BG, B G
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color Intensity</b></td>
                                                            <td>Faint =<b> F</b><br />
                                                                Very Light =<b> VL</b><br />
                                                                Light =<b> L</b><br />
                                                                Fancy Light =<b> FL, FCL</b><br />
                                                                Fancy Dark =<b> FCD, DARK</b><br />
                                                                Fancy Intense =<b> I,FI, INTENSE </b><br />
                                                                Fancy Vivid =<b> V, FV, VIVID</b><br />
                                                                Fancy Deep =<b> D, FD, DEEP</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-primary"><b>Fancy Color Overtone</b><br/><em>(can be left blank)</em></td>
                                                            <td> List any overtones in the color as mentioned below in full<br/>
                                                                No Overtone = <b>NONE *</b><br/>
                                                                <b>
                                                                    YELLOW<br/>
                                                                    YELLOWISH<br/>
                                                                    PINK<br/>
                                                                    PINKISH<br/>
                                                                    BLUE<br/>
                                                                    BLUISH<br/>
                                                                    RED<br/>
                                                                    REDDISH<br/>
                                                                    GREEN<br/>
                                                                    GREENISH<br/>
                                                                    PURPLE<br/>
                                                                    PURPLISH<br/>
                                                                    ORANGE<br/>
                                                                    ORANGEY<br/>
                                                                    VIOLET<br/>
                                                                    GRAY<br/>
                                                                    GRAYISH<br/>
                                                                    BLACK<br/>
                                                                    BROWN<br/>
                                                                    BROWNISH<br/>
                                                                    CHAMPANGE<br/>
                                                                    COGNAC<br/>
                                                                    CHAMELEON<br/>
                                                                    VIOLETISH<br/>
                                                                    WHITE<br/>
                                                                </b>
                                                                Any other Ovetone =<b> OTHERS</b><br/><br/>
                                                                * To enter two ovetones, Separate with a hyphen or a space.<br/>
                                                                <em>For Example: Brownish-Yellowish  or Brownish Yellowish</em><br/><br/>
                                                                * if left blank will be considered as No Overtone.<br/>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Depth %</b><br/></td>
                                                            <td>Example: 62.4 *<br /><br />
                                                                *Give percentage with only one number after the decimal.<br /> Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Table %</b><br/></td>
                                                            <td>Example: 55.5 *<br /><br />
                                                                *Give percentage with no digit after decimal.<br /> Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Thin</b><br/><em>(can be left blank)</em></td>
                                                            <td>Extremely Thick =<b> XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK, XK</b><br />
                                                                Very Thick =<b> VTK, VTHCK, VTHK, VTHICK</b><br />
                                                                Thick =<b> TK, THK, THIK, THIC</b><br />
                                                                Slightly Thick =<b> STK, SLTK, SLTHK</b><br />
                                                                Medium =<b> M, MED, MD</b><br />
                                                                Thin =<b> TN, THN</b><br />
                                                                Slightly Thin =<b> STN, SLTN, SLTHN</b><br />
                                                                Very Thin =<b> VTN, VTHN, VTHIN, VN</b><br />
                                                                Extremely Thin =<b> XTN, XTHN, EXTN, ETN, EN, X</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Thick</b><br/><em>(can be left blank)</em></td>
                                                            <td>Extremely Thick =<b> XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK, XK</b><br />
                                                                Very Thick =<b >VTK, VTHCK, VTHK, VTHICK</b><br />
                                                                Thick =<b> TK, THK, THIK, THIC</b><br />
                                                                Slightly Thick =<b>STK, SLTK, SLTHK</b><br />
                                                                Medium =<b> M, MED, MD</b><br />
                                                                Thin =<b> TN, THN</b><br />
                                                                Slightly Thin =<b> STN, SLTN, SLTHN</b><br />
                                                                Very Thin =<b> VTN, VTHN, VTHIN, VN</b><br />
                                                                Extremely Thin =<b> XTN, XTHN, EXTN, ETN, EN, X</b><br /><br />
                                                                * Enter both Girdle Thin and Girdle Thick in this different field.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle %</b><br/><em>(can be left blank)</em></td>
                                                            <td>Example: 3.5*<br/><br/>
                                                                * Must be a positive number. Do not include % sign.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Girdle Condition</b><br/><em>(can be left blank)</em></td>
                                                            <td>Polished  =<b> P</b><br />
                                                                Faceted =<b> F</b><br />
                                                                Bruted  =<b> B</b><br />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td ><b className="text-primary">Culet Condition</b><br/><em>(can be left blank)</em></td>
                                                            <td>Polished  =<b> P</b><br />
                                                                Abraded =<b> A</b><br />
                                                                Chipped =<b> C</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Crown Height</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Crown Angle</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Pavilion Depth</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Pavilion Angle</b><br/></td>
                                                            <td className="text-danger"><b>Must be empty</b><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Country</b><br/></td>
                                                            <td>India =<b> IN, Mumbai, Mum</b><br />
                                                                Israel =<b> IS, ISR</b><br />
                                                                USA =<b>US, USA, NY, LA</b><br />
                                                                Hong Kong = <b>HK</b><br />
                                                                Belgium = <b>BL, BG, ANT</b><br />

                                                                United Kingdom = <b>UK, London</b><br /><br />
                                                                Other Countries = <b>Mention the full name of country</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">DiamondImage</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Video</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter a URL of the Video.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Heart Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Arrow Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Aset Image</b><br/><em>(can be left blank)</em></td>
                                                            <td>Enter the URL of the image - only .JPG and . PNG links allowed.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Key to symbols</b><br/><em>(can be left blank)</em></td>
                                                            <td>Bearding, Brown patch of color, Bruise, Cavity, Chip, Cleavage, Cloud, Crystal, Crystal Surface, Etch Channel, Extra Facet, Feather, Flux Remnant, Indented Natural, Internal Graining, Internal Inscription, Internal Laser Drilling, Knot, Laser Drill Hole, Manufacturing Remnant, Minor Details of Polish, Natural, Needle, No Inclusion, Pinpoint, Reï¬‚ecting Surface Graining, Surface Graining, Twinning Wisp.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Report Comments</b><br/><em>(can be left blank)</em></td>
                                                            <td>Comments or Remarks mentioned in the Certificate.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Shade</b><br/><em>(can be left blank)</em></td>
                                                            <td>
                                                                None =<b> noshade, white, yellow</b><br />
                                                                BROWN =<b> brown,b2,brn</b><br />
                                                                LIGHT BROWN =<b> LB, BRN1, B1</b><br />
                                                                GREEN =<b> green, mediumgreen, gy</b><br />
                                                                LIGHT GREEN =<b> faintgreen, lightgreen, vlg</b><br />
                                                                GREY =<b> faintgrey, lightgrey, gry</b><br />
                                                                BLACK =<b> black, faintblack, lightblack</b><br />
                                                                PINK =<b> pink, lightpink, pinkish</b><br />
                                                                BLUE =<b> blue, lightblue, faintblue</b><br />
                                                                MIX TINGE =<b> mixtinch, mixt, mix</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Milky</b><br/><em>(can be left blank)</em></td>
                                                            <td>NO MILKY =<b> EX, NO, N, NONE, NON</b><br />
                                                                LIGHT MILKY =<b> VG, L, M1, Light</b><br />
                                                                MILKY =<b> Yes, M2</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Eye Clean</b><br/><em>(can be left blank)</em></td>
                                                            <td>100% Eye Clean =<b> Yes, Y,100</b><br />
                                                                Not 100% Eye Clean =<b> No, N</b><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Growth type</b><br/><em>(can be left blank)</em></td>
                                                            <td>
                                                                CVD =<b>C</b><br />
                                                                HPHT =<b>H</b><br />
                                                                </td>
                                                        </tr>
                                                        <tr>
                                                            <td ><b className="text-primary">Treatment</b></td>
                                                            <td>
                                                                As Grown =<b>As Grown</b><br />
                                                                Laser Drilled =<b>LD, Laser</b><br />
                                                                Irradiated =<b>IRR</b><br />
                                                                Clarity Enhanced =<b>CE, Fracture Filled</b><br />
                                                                Color Enhanced =<b>COL</b><br />
                                                                High Pressure High Temperature =<b>HPHT</b><br />
                                                                Other =<b>O</b><br />
                                                                None(blank) =<b>N</b><br />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
