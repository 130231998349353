/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { ModalProgressBar } from "../../../controls";
import { NODE_API_URL } from "../.././../../../env_config";
import axios from "axios";
import { Alert } from "react-bootstrap";

export function AddUser({ userinfo,updateTemp }) {
    // console.log(updateTemp)
    const [loading, setloading] = useState(false);

    const initialValues = {
        username:"",
        password: "",
        confirmpassword: "",
        email:"",
    };

    const Schema = Yup.object().shape({
        username: Yup.string("Enter username")
            .required("Username is required"),
        password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
        confirmpassword: Yup.string("retype your new password")
            .oneOf([Yup.ref("password"), null], "Password must match")
            .min(8, "Password should be of minimum 8 characters length")
            .required("retype Password is Required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
        email: Yup.string()
            .email("Wrong email format")
            .required("email is Required"),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting ,resetForm}) => {
            const finalData = {
                "supp_id": userinfo.id,
                "username": values.username,
                "password": values.password,
                "email":values.email,
                "sup_name":`${userinfo.firstname} ${userinfo.lastname}`,
                "sup_cname":userinfo.supplier_name
            }
            setloading(true);
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/inserIntoSuppGroup`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            })
                .then((res) => {
                    setloading(false);
                    if (res && res.data) {
                        // console.log("data", res);
                        updateTemp()
                        setSubmitting(false);
                        setSuccessMessage(res.data.msg);
                        setErrorMessage();
                        resetForm();
                        const timeId = setTimeout(() => {
                            setSuccessMessage()
                        }, 5000)
                    
                        return () => {
                            clearTimeout(timeId)
                        }
                    }
                })
                .catch((res) => {
                    setloading(false);
                    setSubmitting(false);
                    // console.error("error", res.response);
                    setSuccessMessage();
                    setErrorMessage(res.response.data.msg);
                    setSuccessMessage();
                    const timeId = setTimeout(() => {
                        setErrorMessage()
                    }, 5000)
                
                    return () => {
                        clearTimeout(timeId)
                    }
                });
        },
    });
    const [errmessage, setErrorMessage] = useState();
    const [successmsg, setSuccessMessage] = useState();
    return (
        <div className="row">
            <div className="col-12">
                <Accordion className="search_accordion" defaultActiveKey="">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" key="1">
                            Add user
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className="pb-0">
                                <form onSubmit={formik.handleSubmit} >
                                    {/* begin::Form */}
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            {errmessage ? (
                                                <div className="mb-5 alert alert-custom alert-light-danger mb-4 py-4 alert-dismissible">
                                                    <div className="alert-text font-weight-bold">
                                                    {errmessage}
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {successmsg ? (
                                                <div className="mb-5 alert alert-custom alert-light-success mb-4 py-4 alert-dismissible">
                                                    <div className="alert-text font-weight-bold">
                                                    {successmsg}
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                                    Username
                                                </label>
                                                <div className="col-lg-9 col-xl-6">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Username"
                                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                            "username"
                                                        )}`}
                                                        name="username"
                                                        {...formik.getFieldProps(
                                                            "username"
                                                        )}
                                                    />
                                                    {formik.touched.username &&
                                                    formik.errors.username ? (
                                                        <div className="invalid-feedback">
                                                            { formik.errors.username}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                                    Password
                                                </label>
                                                <div className="col-lg-9 col-xl-6">
                                                    <input
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                            "password"
                                                        )}`}
                                                        name="password"
                                                        {...formik.getFieldProps(
                                                            "password"
                                                        )}
                                                    />
                                                    {formik.touched.password &&
                                                    formik.errors.password ? (
                                                        <div className="invalid-feedback">
                                                            {
                                                                formik.errors.password
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                                    Verify Password
                                                </label>
                                                <div className="col-lg-9 col-xl-6">
                                                    <input
                                                        type="text"
                                                        placeholder="Verify Password"
                                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                            "confirmpassword"
                                                        )}`}
                                                        name="confirmpassword"
                                                        {...formik.getFieldProps(
                                                            "confirmpassword"
                                                        )}
                                                    />
                                                    {formik.touched.confirmpassword &&
                                                    formik.errors.confirmpassword ? (
                                                        <div className="invalid-feedback">
                                                            {
                                                                formik.errors.confirmpassword
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                                    Email
                                                </label>
                                                <div className="col-lg-9 col-xl-6">
                                                    <input
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                            "email"
                                                        )}`}
                                                        name="email"
                                                        {...formik.getFieldProps(
                                                            "email"
                                                        )}
                                                    />
                                                    {formik.touched.email &&
                                                    formik.errors.email ? (
                                                        <div className="invalid-feedback">
                                                            {
                                                                formik.errors.email
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5 border-top text-right pt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary px-8 mr-2"
                                                disabled={
                                                    formik.isSubmitting ||
                                                    (formik.touched &&
                                                        !formik.isValid)
                                                }
                                            >
                                                Add User
                                                {formik.isSubmitting}
                                            </button>
                                        </div>
                                    </div>
                                    {/* end::Form */}
                                </form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>
    );
}
