import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
// import SVG from "react-inlinesvg";
import moment from "moment-timezone";
import { FaInfoCircle } from 'react-icons/fa';
import {HiOutlineBadgeCheck} from "react-icons/hi"
import { FcShipped } from "react-icons/fc";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// function jugaadDate(x) {
//     // const tempDateNew = moment.tz(x, "America/St_Johns").format("YYYY-MM-DD HH:mm:ss");
//     // const dateNew = moment.tz(tempDateNew, "America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss");
//     // return dateNew

//     let storedDate = new Date(x);
//     storedDate = new Date(storedDate.getTime() - (330 * 60 * 1000));
//     return storedDate
// }

export const SalesHistoryColumn = (userinfo) => [

   
    {
        Header: "Status",
        accessor: (d) => {
            return (
                <>
                    {d.v_customer === "12"  ? 
                        <div className="d-flex align-items-center justify-content-center">
                            <OverlayTrigger placement="bottom" overlay={<Tooltip > Delivered </Tooltip>} >
                                <HiOutlineBadgeCheck  className="font-size-h3 text-success"/> 
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
                            >
                                <FcShipped className="ml-2 font-size-h4"/>
                            </OverlayTrigger>
                        </div>
                    : 
                        <OverlayTrigger placement="bottom" overlay={<Tooltip > Delivered </Tooltip>} >
                            <HiOutlineBadgeCheck  className="font-size-h3 text-success"/> 
                        </OverlayTrigger>
                    }
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Bill/Payment Status",
        accessor: (d) => {
            return (
                <>
                {d.payment_status === "BILL_PENDING" ?
                    <>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >
                            <p className="text-nowrap"><strong>Pickup Done Date : </strong>{  d.status_date ? moment.tz(d.status_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Bill Received Date : </strong>{  d.bill_created_time ? moment.tz(d.bill_created_time, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Bill No. : </strong>{d.bill_no ? d.bill_no :"-"}</p>
                            <p className="text-nowrap"><strong>Payment Due Date : </strong>{  d.bill_due_date ? moment.tz(d.bill_due_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Payment Done Date : </strong>{  d.bill_payment_date ? moment.tz(d.bill_payment_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                        </Tooltip>} >
                            <span className="label label-lg label-outline-warning label-pill label-inline bg-warning-o-20 font-weight-bold text-nowrap">Bill Pending <FaInfoCircle className="ml-2 cursor-pointer" /></span>
                        </OverlayTrigger>
                    </>
                : d.payment_status === "BILL_RECEIVED" ? 
                    <>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >
                            <p className="text-nowrap"><strong>Pickup Done Date : </strong>{  d.status_date ? moment.tz(d.status_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Bill Received Date : </strong>{  d.bill_created_time ? moment.tz(d.bill_created_time, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Bill No. : </strong>{d.bill_no ? d.bill_no :"-"}</p>
                            <p className="text-nowrap"><strong>Payment Due Date : </strong>{  d.bill_due_date ? moment.tz(d.bill_due_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Payment Done Date : </strong>{  d.bill_payment_date ? moment.tz(d.bill_payment_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                        </Tooltip>} >
                            <span className="label label-lg label-outline-primary label-pill label-inline bg-primary-o-20 font-weight-bold text-nowrap">Bill Received <FaInfoCircle className="ml-2 cursor-pointer" /></span>
                        </OverlayTrigger>
                    </>          
                :  d.payment_status === "PAYMENT_DONE" ? 
                    <>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >
                            <p className="text-nowrap"><strong>Pickup Done Date : </strong>{  d.pickup_done_date ? moment.tz(d.pickup_done_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Bill Received Date : </strong>{  d.bill_created_time ? moment.tz(d.bill_created_time, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Bill No. : </strong>{d.bill_no ? d.bill_no :"-"}</p>
                            <p className="text-nowrap"><strong>Payment Due Date : </strong>{  d.bill_due_date ? moment.tz(d.bill_due_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                            <p className="text-nowrap"><strong>Payment Done Date : </strong>{  d.bill_payment_date ? moment.tz(d.bill_payment_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                        </Tooltip>} >
                            <span className="label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Payment Paid <FaInfoCircle className="ml-2 cursor-pointer" /></span>
                        </OverlayTrigger>
                    </>  
                : "-"}
                </>
            );
        },
        className: "text-center align-middle paymentStatus",
    },
    {
        Header: "Pickup Date",
        // accessor: "pickup_date",
        accessor: (d) => {
            return (
                <>
                    {  d.pickup_date ? moment.tz(d.pickup_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD") : "-"}
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Order ID",
        accessor: "confirm_goods_id",
        className: "text-center align-middle",
    },
    {
        Header: "Diamond Detail",
        accessor: (d) => (
            <>
            {d.diamond_type === "PN" || d.diamond_type === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.C_Shape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.C_Shape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamond_type}_diamond mr-2 small`}>
                                {d.diamond_type}
                            </span>
                            <span className={`text-primary small ml-3`}>
                               {d.diamond_type}-{d.parcel_id}-{d.split_id}{d.cg_add_split_accept ?<>/{d.cg_add_split_accept}</> : ""}
                            </span>
                            <span className={`text-primary text-capitalize small ml-3`}>
                                {d.length_mm} - {d.width_mm} MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {(d.C_Weight).toFixed(2)}Ct.{" "}
                            {d.C_Color}{" "}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.aws_image &&  d.aws_image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={d.aws_image}
                                width="50px"
                                onError={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                )}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "W" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamond_type === "W" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                {d.diamond_type === "W" ? d.C_Color === "fancy"  ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <span  className={`text-primary small ml-3`}>
                                {d.Loat_NO}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.Lab === "IGI"
                                        ? `https://www.igi.org/viewpdf.php?r=${d.Certi_NO}`
                                        : d.Lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${d.Certi_NO}`
                                        : d.Lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.Certi_NO}`
                                        : d.Lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.Certi_NO}`
                                        : d.Certi_link
                                }
                            >
                                {d.Lab}-{d.Certi_NO}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {(d.C_Weight).toFixed(2)}Ct.{" "}
                            {d.C_Color === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.C_Color}{" "}</>
                            )}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    },
    // {
    //     Header: "Stone ID" ,
    //     accessor: "Loat_NO",
    //     className: "text-center align-middle",
        
    // },
    // {
    //     Header: "Carat",
    //     accessor: "C_Weight",
    //     className: "text-center align-middle",
    // },
    // {
    //     Header: "Shape",
    //     accessor: "C_Shape",
    //     className: "text-center align-middle p-2",
    //     Cell: (row) => (
    //         <>
    //             <SVG
    //                 width="22px"
    //                 height="22px"
    //                 src={toAbsoluteUrl(
    //                     `/media/shape/svg_shape/${row.row.original.C_Shape.toLowerCase()}.svg`
    //                 )}
    //             />
    //             <div className="small">{row.row.original.C_Shape}</div>
    //         </>
    //     ),
    // },
    // {
    //     Header: "Color",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                 {d.C_Color === "fancy"?
    //                 <>
    //                     <OverlayTrigger placement="bottom" overlay={<Tooltip >{d.f_intensity} {d.f_overtone} {d.f_color}</Tooltip>} >
    //                         <span className="text-truncate d-inline-block" style={{maxWidth:"80px"}}>{d.f_intensity} {d.f_overtone} {d.f_color}</span>
    //                     </OverlayTrigger>
    //                 </>: d.C_Color}
    //             </>
    //         );
    //     },
    //     minwidth: 50,
    //     className: "text-center align-middle",
    // },
    // {
    //     Header: "Clarity",
    //     accessor: "C_Clarity",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Cut",
    //     accessor: "C_Cut",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Polish",
    //     accessor: "C_Polish",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Symmetry",
    //     accessor: "C_Symmetry",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Fluo.",
    //     accessor: "C_Fluorescence",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Lab",
    //     accessor: "Lab",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Certificate",
    //     accessor: "Certi_NO",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return (
    //             <>
    //                 <a
    //                     target="_blank"
    //                     rel="noreferrer"
    //                     className="text-primary mx-2 text-left"
    //                     href={
    //                         row.row.original.Lab === "IGI"
    //                             ? `https://www.igi.org/viewpdf.php?r=${row.row.original.Certi_NO}`
    //                             : row.row.original.Lab === "GIA"
    //                             ? `https://www.gia.edu/report-check?reportno=${row.row.original.Certi_NO}`
    //                             : row.row.original.Lab === "HRD"
    //                             ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.row.original.Certi_NO}`
    //                             : row.row.original.Lab === "GCAL"
    //                             ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.row.original.Certi_NO}`
    //                             : row.row.original.Certi_link
    //                     }
    //                 >
    //                     {row.row.original.Certi_NO}
    //                 </a>
    //             </>
    //         )
    //     },
        
    // },
    {
        Header: "Price",
        className: "text-right  text-nowrap align-middle pr-5",
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    $ {numberWithCommas(parseFloat(d.a_price))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT: <span style={{fontFamily:"sans-serif"}}> </span> {numberWithCommas(d.a_price / d.C_Weight)}
                </span>
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
        // Cell: (row) => {
        //     return <>${parseFloat(row.value).toFixed(2)}</>;
        // },
    },
    // {
    //     Header: "$/CT",
    //     className: "text-right align-middle pr-5",
    //     // accessor: "a_C_Rate",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                 {/* {parseFloat(d.a_price).toFixed(2) / parseFloat(d.C_Weight).toFixed(2)} */}
    //                 {parseFloat(d.a_price / d.C_Weight).toFixed(2)}
    //             </>
    //         );
    //     },
    //     sortMethod: (a, b) => Number(a) - Number(b),
    //     // Cell: (row) => {
    //     //     return <>${parseFloat(row.value).toFixed(2)}</>;
    //     // },
    // },
    // {
    //     Header: "Total",
    //     className: "text-right align-middle pr-5",
    //     accessor: "a_price",
    //     sortMethod: (a, b) => Number(a) - Number(b),
    //     Cell: (row) => {
    //         return <>${parseFloat(row.value).toFixed(2)}</>;
    //     }
    // },
    {
        Header: "Location",
        className: "text-center align-middle",
        accessor: "first_location",
    },
    {
        Header: "Exchange Rate",
        className: "text-center align-middle",
        accessor: (d) => {
            return (
                <>
                   {d.cur_inr <= 1 ?  "$" : <span style={{fontFamily:"sans-serif"}}>₹</span>}{parseFloat(d.cur_inr).toFixed(2)}
                </>
            );
        },
    },
    {
        Header: "Local Price",
        className: "text-right text-nowrap align-middle",
        accessor: (d) => {
            return (
                <>
                    {d.first_location === 'Mumbai' || d.first_location === 'Surat' ? 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in INR</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span> {numberWithCommas(d.a_price * d.cur_inr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span>/CT: <span style={{fontFamily:"sans-serif"}}> ₹</span> {numberWithCommas(d.cur_inr * (d.a_price / d.C_Weight))}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                            
                        </> : 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in USD</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        $ {numberWithCommas(d.a_price * d.cur_inr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        $/CT: <span style={{fontFamily:"sans-serif"}}> </span> {numberWithCommas(d.cur_inr * (d.a_price / d.C_Weight))}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                        </> 
                    }
                </>
            );
        },
        // Cell: (row) => {
        //     return <>${parseFloat(row.value).toFixed(2)}</>;
        // },
    }
];
