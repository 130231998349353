import React from 'react';
import { Grid } from '@material-ui/core';
import { InputField,  SelectField,PhoneInput } from '../FormFields';

const designation_data = [
  {
    value: 'Director',
    label: 'Director'
  },
  {
    value: 'CEO',
    label: 'CEO'
  },
  {
    value: 'Partner',
    label: 'Partner'
  },
  {
    value: 'Owner',
    label: 'Owner'
  },
  {
    value: 'Manager',
    label: 'Manager'
  },
  {
    value: 'Sales Executive',
    label: 'Sales Executive'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

export default function PersonalInfoForm(props) {
  const {
    formField: {
      email,
      firstname,
      lastname,
      moblie_no,
      designation,
    },
    setFieldValue,
   } = props;

  return (
    <React.Fragment>
      
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid className='text-center' item xs={12} sm={7}>
          <h3 className="card-label font-weight-bolder text-dark mb-0">Personal Information</h3>
          <span className="text-muted font-weight-bold font-size-sm ">Update your personal informaiton</span>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} className="font-weight-bold font-size-lg">Email:</Grid>
            <Grid item xs={9}>
              <InputField name={email.name} label={email.label} fullWidth disabled={true}/>
            </Grid>
          </Grid>
          <hr className="w-100 m-0 mt-8" />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} className="font-weight-bold font-size-lg">First Name:</Grid>
            <Grid item xs={9}>
              <InputField name={firstname.name} label={firstname.label} fullWidth disabled={true}/>
            </Grid>
          </Grid>
          <hr className="w-100 m-0 mt-8" />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} className="font-weight-bold font-size-lg">Last Name:</Grid>
            <Grid item xs={9}>
              <InputField name={lastname.name} label={lastname.label} fullWidth disabled={true}/>
            </Grid>
          </Grid>
          <hr className="w-100 m-0 mt-8" />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} className="font-weight-bold font-size-lg">Mobile:</Grid>
            <Grid item xs={9}>
              <PhoneInput name={moblie_no.name} label={moblie_no.label} fullWidth onChange={(value) => setFieldValue("moblie_no",value)}/>
              {/* <InputField name={lastname.name} label={lastname.label} fullWidth /> */}
            </Grid>
          </Grid>
          <hr className="w-100 m-0 mt-8" />
        </Grid>
       
        <Grid item xs={12} sm={7}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} className="font-weight-bold font-size-lg">Designation:</Grid>
            <Grid item xs={9}>
              <SelectField
                name={designation.name}
                label={designation.label}
                data={designation_data}
                fullWidth
              />
              {/* <InputField name={lastname.name} label={lastname.label} fullWidth /> */}
            </Grid>
          </Grid>
           {/* <MuiUploader /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
