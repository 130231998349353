import React from "react";
import { NavLink } from "react-router-dom";
import ContactCard from "./ContactCard";
import ContactData from "./CotactData";
import {  FaPhoneAlt } from "react-icons/fa";
import {  FaEnvelope } from "react-icons/fa";


const Contact = () =>{
    return (
        <>
            <div className="container-fluid banner-border"></div>
            <span className="anchorspace" id="contact"> </span>
            <div id="welcome" className="text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Welcome to Supplier Centre</h2>
                        </div>
                    </div>
                </div>
                <NavLink className="btn action-button py-2 px-8 font-size-lg" role="button" to="/auth/registration">Join now</NavLink>
            </div>
            <div className="text-center contact-sec">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="text-center col-md-12 py-4 mb-2">
                        <span className="conIcon" ><FaPhoneAlt /></span>
                    </div>
                    {ContactData.map((val) => {
                        return (
                            <ContactCard
                                key={val.id}
                                imgsrc={val.imgsrc}
                                locationName={val.locationName}
                                contactNum={val.contactNum}
                            />
                        )
                    })}
                    <div className="text-center col-md-12 py-4 mt-md-3">
                        <span className="conIcon" ><FaEnvelope /></span>
                    </div>
                    <div className="col-md-12 align-self-center">
                        <h5><a href="mailto:supplier@diamondsoncall.com">supplier@diamondsoncall.com</a></h5>
                    </div>
                </div>
            </div>
        </div>
            
        </>
    )
}
export default Contact;