/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom"; 
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../_helpers";
import { FaChartPie } from "react-icons/fa";

export function SaleHistoryTile({salesHistoryCount,userinfo}) {
  return (
    <>
      <div className={`card card-custom salehistory card-stretch gutter-b`} style={{ height: "120px" }} >
      {userinfo.is_pending === 1 ?
      <>
        <div>
          <div className="card-body">
            <div className="text-white font-weight-bolder font-size-h2 mt-3">{salesHistoryCount !== "" ? salesHistoryCount :"0" }</div>
            <span className="text-light font-weight-bold font-size-lg mt-1">No. of Stones in sales history</span>
            <div className="bgIcon">
              <FaChartPie />
            </div>
          </div>
        </div>
      </>
      :
        <Link  to="/sales-history" >
          <div className="card-body">
            <div className="text-white font-weight-bolder font-size-h2 mt-3">{salesHistoryCount !== "" ? salesHistoryCount :"0" }</div>
            <span className="text-light font-weight-bold font-size-lg mt-1">No. of Stones in sales history</span>
            <div className="bgIcon">
              <FaChartPie />
            </div>
          </div>
        </Link>
      }
      </div>
    </>
  );
}
