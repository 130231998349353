import React from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField,  PhoneInput } from "../FormFields";
import MuiUploader from '../FormFields/MuiUploader';
import { BASE_URL,BASE_URL_OLD } from "./../../../../../env_config"

export default function CompanyInfoForm(props) {
    const {
        formField: {
            supplier_logo,
            supplier_name_o,
            address,
            pincode,
            city,
            state,
            country,
            cc_email,
            website,
            telphone,
            c_register_no,
            c_register_upload,
            c_partnet_name,
            c_document_type,
            c_partnet_doc,
        },
        setSecondFile,
        setFirstFile,
        FirstFile,
        ThirdFile,
        setThirdFile,
        setFieldValue,
        values,
        setCompanyRegName,
        setSupplierLogoName,
        setDocTypeName
    } = props;
    const SelectDate = (value) => {
        // console.log(value,"DATE")
        setFieldValue("business_est_date",value)
    }
    return (
        <React.Fragment>
            
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid className='text-center' item xs={12} sm={7}>
                    <h3 className="card-label font-weight-bolder text-dark mb-0">Company Information</h3>
                    <span className="text-muted font-weight-bold font-size-sm ">Update your Company informaiton</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{supplier_name_o.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={supplier_name_o.name}
                                label={supplier_name_o.label}
                                fullWidth
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">Company Logo:</Grid>
                        <Grid item xs={9}>
                            <MuiUploader
                                name={supplier_logo.name}
                                label={supplier_logo.label}
                                fullWidth
                                maxFileSize={102500}
                                FirstFile={FirstFile}
                                setFirstFile={setFirstFile}
                                filefield={"supplier_logo"}
                                setFieldValue={props.setFieldValue}
                                setSupplierLogoName={setSupplierLogoName}
                                values={values}
                            />
                            {values.supplier_logo ? 
                                <span> Previously Uploaded : {values.supplier_logo ? 
                                    <a href={`${BASE_URL_OLD}/assets/documents/${values.supplier_logo}`} target="_blank" rel="noreferrer" className="font-weight-bold">{values.supplier_logo}</a>:"N/A"}
                                </span> 
                            : ""}
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{address.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                className="mb-2"
                                name={address.name}
                                label={address.label}
                                fullWidth
                            />
                            <InputField
                                className="mb-2"
                                name={pincode.name}
                                label={pincode.label}
                                fullWidth
                            />
                            <InputField
                                className="mb-2"
                                name={city.name}
                                label={city.label}
                                fullWidth
                            />
                            <InputField
                                className="mb-2"
                                name={state.name}
                                label={state.label}
                                fullWidth
                            />
                            <InputField
                                className="mb-2"
                                name={country.name}
                                label={country.label}
                                fullWidth
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{website.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                className="mb-2"
                                name={website.name}
                                label={website.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{cc_email.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                className="mb-2"
                                name={cc_email.name}
                                label={cc_email.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{telphone.label}</Grid>
                        <Grid item xs={9}>
                            <PhoneInput name={telphone.name} label={telphone.label} fullWidth onChange={(value) => setFieldValue("telphone",value)} />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{c_register_no.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={c_register_no.name}
                                label={c_register_no.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg"> Business Reg. Document:</Grid>
                        <Grid item xs={9}>
                            <MuiUploader
                                name={c_register_upload.name}
                                label={c_register_upload.label}
                                fullWidth
                                maxFileSize={2100000}
                                setSecondFile={setSecondFile}
                                filefield={"c_register_upload"}
                                setFieldValue={props.setFieldValue}
                                setCompanyRegName={setCompanyRegName}
                                values={values}
                            />
                            {values.c_register_upload ? 
                                <span> Previously Uploaded : {values.c_register_upload ? 
                                    <a href={`${BASE_URL_OLD}/assets/documents/${values.c_register_upload}`} target="_blank" rel="noreferrer" className="font-weight-bold">{values.c_register_upload}</a>:"N/A"}
                                </span> 
                            : ""}
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{c_partnet_name.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={c_partnet_name.name}
                                label={c_partnet_name.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{c_document_type.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={c_document_type.name}
                                label={c_document_type.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">Type Of Document File</Grid>
                        <Grid item xs={9}>
                            <MuiUploader
                                name={c_partnet_doc.name}
                                label={c_partnet_doc.label}
                                data={c_partnet_doc}
                                ThirdFile={ThirdFile}
                                maxFileSize={2100000}
                                setThirdFile={setThirdFile}
                                filefield={"c_partnet_doc"}
                                setFieldValue={props.setFieldValue}
                                setDocTypeName={setDocTypeName}
                                values={values}
                            />
                            {values.c_partnet_doc ? 
                                <span> Previously Uploaded : {values.c_partnet_doc ? 
                                    <a href={`${BASE_URL_OLD}/assets/documents/${values.c_partnet_doc}`} target="_blank" rel="noreferrer" className="font-weight-bold">{values.c_partnet_doc}</a>
                                        :"N/A"}
                                </span> 
                            : ""}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
