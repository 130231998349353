import React, { useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { SelectUploadMode } from "./SelectUploadMode";
import { ApiMode } from "./ApiMode";
import { FtpMode } from "./FtpMode";
import { FileMode } from "./FileMode";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";

export const UploadDiamond = ({userinfo,subId}) => {

    const suhbeader = useSubheader();
    suhbeader.setTitle("Upload Diamonds");

    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)
    const [loader, setLoader] = React.useState(false)
    // console.log(userinfo)
    useEffect(() => {
        document.title = "Upload Diamonds | Diamonds on Call - Suppliers Center";
        let isMounted = true;
// console.log(subId)
        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":2
            };
            axios({
              method: "POST",
              url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
              headers: { "Content-Type": "application/json" },
              data: permiFetchData,
            }).then((res) => {
                setLoader(false)
                
                // console.log(res.data.data[0])
                if (res && res.data) {
                    isMounted && setEditPerm(res.data.data[0].edit_permission);
                    isMounted && setReadPerm(res.data.data[0].read_permission);
                }
            }).catch(error => {
                setLoader(false)
                // console.log(error,"ERROR")
            });
          }
    }, [subId]);

    return (
      <>
      <div className="card card-custom gutter-b">
            <div className="card-header py-3 mb-3">
                <div className="card-title">
                    <span className="card-label font-weight-bolder text-dark">
                        Upload Diamond
                    </span>
                </div>
            </div>
            {!loader ?
                <>
                   
                    {userinfo.upload_mode === "others"  ? <div className="card-body text-center">You do not have access for this page, for any query please contact your purchase executive.</div>: userinfo.upload_mode === "custom API"  ? <div className="card-body text-center">Your selected mode is Custom API for any query please contact your purchase executive.</div>: userinfo.upload_mode === "File" 
                    ? <> {(readPerm && editPerm) || !subId ? <FileMode userinfo={userinfo} /> : <div className="card-body text-center"> You do not have permission to access, Please contact your administrator for help</div> }</>
                    : userinfo.upload_mode === "API" 
                        ?  <> {readPerm || subId === null ? <ApiMode userinfo={userinfo} readPerm={readPerm} editPerm={editPerm} subId={subId}/> : <div className="card-body text-center"> You do not have permission to access, Please contact your administrator for help</div> }</>
                    : (userinfo.upload_mode === "FTP" || userinfo.upload_mode === "custom FTP")  
                        ?  <> {readPerm || subId === null ? <FtpMode userinfo={userinfo}  readPerm={readPerm} editPerm={editPerm} subId={subId} /> : <div className="card-body text-center"> You do not have permission to access, Please contact your administrator for help</div> }</>
                    :  <> {readPerm || subId === null ? <SelectUploadMode userinfo={userinfo} /> : <div className="card-body text-center"> You do not have permission to access, Please contact your administrator for help</div> }</>}
                        
                </>
           : <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">Loading...<div className="spinner spinner-primary ml-5"></div></div> }   
        </div>
      </>
    );
};
