/* eslint-disable */
export default {
    formId: 'kycForm',
    formField: {
      email: {
        name: 'email',
        label: 'Email*',
        requiredErrorMsg: 'Email is required'
      },
      firstname: {
        name: 'firstname',
        label: 'First name*',
        requiredErrorMsg: 'First name is required'
      },
      lastname: {
        name: 'lastname',
        label: 'Last name*',
        requiredErrorMsg: 'Last name is required'
      },
     
      designation: {
        name: 'designation',
        label: 'Designation*',
        requiredErrorMsg: 'Designation is required'
      },
      supplier_name_o: {
        name: 'supplier_name_o',
        label: 'Company Name:',
        requiredErrorMsg: 'Company Name is required'
      },
      moblie_no: {
        name: 'moblie_no',
        label: 'Mobile No',
        requiredErrorMsg: 'Mobile no. is required'
      },
      supplier_logo: {
        name: 'supplier_logo',
        label: '',
        requiredErrorMsg: 'File is required'
      },
     
      address: {
        name: 'address',
        label: 'Address*',
        requiredErrorMsg: 'Address is required'
      },
      city: {
        name: 'city',
        label: 'City*',
        requiredErrorMsg: 'City is required'
      },
      state: {
        name: 'state',
        label: 'State*',
        requiredErrorMsg: 'State is required'
      },
      pincode: {
        name: 'pincode',
        label: 'Zip code*',
        requiredErrorMsg: 'Zip code is required',
        invalidErrorMsg: 'Zip code is not valid (e.g. 395001)'
      },
      country: {
        name: 'country',
        label: 'Country*',
        requiredErrorMsg: 'Country is required',
      },
      website: {
        name: 'website',
        label: 'Company Website',
        requiredErrorMsg: 'Company Website is required'
      },
      cc_email: {
        name: 'cc_email',
        label: 'Company Email',
        requiredErrorMsg: 'Company Email is required'
      },
      telphone: {
        name: 'telphone',
        label: 'Telephone',
        requiredErrorMsg: 'Telephone is required'
      },
      c_register_no: {
        name: 'c_register_no',
        label: 'Business Reg. Number',
        requiredErrorMsg: 'Business Reg. Number is required'
      }, 
      c_register_upload: {
        name: 'c_register_upload',
        label: 'Business Reg. Doc.*',
        requiredErrorMsg: 'Business Reg. Doc. File is required'
      },
      c_partnet_name: {
        name: 'c_partnet_name',
        label: 'Director/Partner Name*',
        requiredErrorMsg: 'Director/Partner Name is required'
      },
      c_partnet_doc: {
        name: 'c_partnet_doc',
        label: 'Partner Document',
        requiredErrorMsg: 'Partner Document is required'
      },
      c_document_type: {
        name: 'c_document_type',
        label: 'Type Of Document*',
        requiredErrorMsg: 'Type Of Document is required'
      },
      comp_name_1: {
        name: 'comp_name_1',
        label: 'Company Name',
        requiredErrorMsg: 'Company Name is required'
      },
      person_name_1: {
        name: 'person_name_1',
        label: 'Person Name',
        requiredErrorMsg: 'Address Line 1 is required'
      },
      designation_1: {
        name: 'designation_1',
        label: 'Designation',
        requiredErrorMsg: 'Address Line 2 is required'
      },
      email_id_1: {
        name: 'email_id_1',
        label: 'Email Id:*',
        requiredErrorMsg: 'email is required'
      },
      contact_no_1: {
        name: 'contact_no_1',
        label: 'Contact Number:',
        requiredErrorMsg: 'Contact Number is required'
      },
      comp_name_2: {
        name: 'comp_name_2',
        label: 'Company Name',
        requiredErrorMsg: 'Company Name is required'
      },
      person_name_2: {
        name: 'person_name_2',
        label: 'Person Name',
        requiredErrorMsg: 'Address Line 1 is required'
      },
      designation_2: {
        name: 'designation_2',
        label: 'Designation',
        requiredErrorMsg: 'Address Line 2 is required'
      },
      email_id_2: {
        name: 'email_id_2',
        label: 'Email Id:*',
        requiredErrorMsg: 'email is required'
      },
      contact_no_2: {
        name: 'contact_no_2',
        label: 'Contact Number:',
        requiredErrorMsg: 'Contact Number is required'
      },
    }
  };
  