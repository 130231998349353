import React from "react"
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import "./css/style.css";
const FooterBootom =()=>{
    return(
        <>
             <div className="container-fluid footer">
                <div className="row justify-content-between text-nowrap align-items-center footerbottom">
                    <div className="col-lg-6 col-md-6 col-sm-12 align-items-center p-1 text-md-left text-center">
                        <p>&#169; {new Date().getFullYear()} Diamondsoncall. All Rights Reserved.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 text-md-right text-center p-1">
                        <a target="_blank" href="https://facebook.com/diamondsoncall" title="Facebook" rel="noopener noreferrer">
                            <FaFacebookSquare />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/diamondsoncall" title="Linkedin" rel="noopener noreferrer">
                            <FaLinkedin />
                        </a>
                        <a target="_blank" href="https://twitter.com/diamondsoncall" title="twitter" rel="noopener noreferrer">
                            <FaTwitterSquare />
                        </a>
                        <a target="_blank" href="https://instagram.com/diamondsoncall" title="instagram" rel="noopener noreferrer">
                            <FaInstagramSquare />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterBootom;