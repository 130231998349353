/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {toAbsoluteUrl} from "../../../../_helpers";
import { FaPhoneSquareAlt,FaWhatsapp,FaEnvelope } from "react-icons/fa";

export function DashboardProfile(supplierData) {
  // console.log(supplierData)
  return (
    <>
      {/* begin::Advance Table Widget 9 */}
      <div className={`border shadow-xs card card-custom card-stretch gutter-b`}>
        {/* begin::Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-d-flex justify-content-between flex-column pt-4 h-100">
            <div className="d-flex flex-column flex-center mt-10">
                <div className="symbol symbol-120 symbol-circle symbol-success overflow-hidden border-success border border-2">
                    <img className="h-75 align-self-end" alt="user image" src={toAbsoluteUrl("/media/dummy-user.jpg")}/>
                </div>
                <p className="card-title text-capitalize font-weight-bolder text-dark-75 font-size-h4 m-0 pt-7 pb-1">{supplierData.supplierData.sales_person_name ? supplierData.supplierData.sales_person_name :"Diamonds on call"}</p>
                <div className="font-weight-bold text-dark-50 font-size-sm pb-6">Purchase Executive</div>
            </div>
            <div className="d-flex justify-content-between align-items-center py-4">
                <FaPhoneSquareAlt className="text-primary display-4"/>
                <a className="text-dark-75 font-size-h6 font-weight-bold" href={`tel:${supplierData.supplierData.mobile ? supplierData.supplierData.mobile:"+852 4644 4677"} `}>{supplierData.supplierData.mobile ? supplierData.supplierData.mobile:"+852 4644 4677"}</a>
            </div>
            <div className="separator separator-solid"></div>
            <div className="d-flex justify-content-between align-items-center py-4">
                <FaWhatsapp className="text-success display-4"/>
                <a className="text-dark-75 font-size-h6 font-weight-bold" href={`https://wa.me/${supplierData.supplierData.whatsapp ? supplierData.supplierData.whatsapp:"85246444677"} `}>{supplierData.supplierData.whatsapp ? supplierData.supplierData.whatsapp:"+852 4644 4677"}</a>
            </div>
            <div className="separator separator-solid"></div>
            <div className="d-flex justify-content-between align-items-center py-4">
                <FaEnvelope className="text-danger display-4"/>
                <a className="text-dark-75 font-size-h6 font-weight-bold" href={`mailto:${supplierData.supplierData.mmail ? supplierData.supplierData.mmail:"supplier@diamondsoncall.com"} `}>{supplierData.supplierData.mmail ? supplierData.supplierData.mmail:"supplier@diamondsoncall.com"}</a>
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}
