import React,{useState} from "react";
// import { at } from "lodash";
import {DropzoneDialog} from 'material-ui-dropzone'
import { useField } from "formik";
// import { TextField } from '@material-ui/core';
// import {useDropzone} from 'react-dropzone';
// import { FaCloudUploadAlt } from 'react-icons/fa';
import {Button} from '@material-ui/core';
// import path from "path";

export default function MuiUploader(props){
  const [open,setOpen] = useState(false)
  const [field, meta] = useField(props);
    function handleSave(files) {
        //Saving files to state for further use and closing Modal.
        const originalname = files[0].path;
        const extension = originalname.substring(originalname.lastIndexOf(".")+1);
        const basename = originalname.substring(0, originalname.lastIndexOf('.')) || originalname;
        let filename =basename+Date.now()+"."+extension
        // let filename =files[0].path

        // const filename = new Date().getTime() + "-" + files[0].path
        const file = new File(files, filename);
        if(props.filefield === "supplier_logo"){
          props.setFirstFile([file]);
          props.setSupplierLogoName(filename)
          props.setFieldValue("supplier_logo",filename)
          }
          if(props.filefield === "c_register_upload"){
              props.setSecondFile([file]);
              props.setCompanyRegName(filename)
              props.setFieldValue("c_register_upload",filename)
          }
          if(props.filefield === "c_partnet_doc"){
            props.setThirdFile([file]);
            props.setDocTypeName(filename)
            props.setFieldValue("c_partnet_doc",filename)
    
          }
          setOpen(false)
       
    }
    return (
        <div className={`muiuploader-border p-3 rounded muiUploader ${meta.error ? "uploaderErrorBorder" : ""}`}>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
              Add File
            </Button>
            <span className="ml-3">{props.filefield === "supplier_logo" && props.values && props.values.supplier_logo?props.values.supplier_logo:""}</span >
            <span className="ml-3">{props.filefield === "c_register_upload" && props.values && props.values.c_register_upload?props.values.c_register_upload:""}</span >
            <span className="ml-3">{props.filefield === "c_partnet_doc" && props.values && props.values.c_partnet_doc?props.values.c_partnet_doc:""}</span >
            {/* <i className="small d-block mt-2">File Format : Png, Jpeg, Jpg . Max size:2.00Mb</i> */}
            {meta.error?<span className="muiuploader-error">{meta.error}</span>:""}
            <DropzoneDialog
                open={open}
                onSave={handleSave}
                acceptedFiles={['image/jpeg','image/jpg', 'image/png', 'image/bmp','application/pdf']}
                showPreviews={false}
                maxFileSize={props.maxFileSize}
                onClose={() => setOpen(false)}
                filesLimit={1}
                showPreviewsInDropzone={true}
                previewText={true}
                dropzoneText={<span>Drag & Drop <br /> <span className="small font-italic text-muted">File Format : Png, Jpeg, Jpg, Pdf</span ></span>}
            />
        </div>
    );
}