/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { AddUser } from "./ManageUser/AddUser";
import { ViewUser } from "./ManageUser/ViewUser";

export function ManageUser({ userinfo }) {
    const [temp , setTemp] = React.useState(true)

    const updateTemp =()=> {
        setTemp(false)
        setTimeout(() => {
            setTemp(true)
          }, 1000);
    }
    
    return (
        <div className="card card-custom">
            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        Manage User
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        Manage your users
                    </span>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="card-body">
                <div className="mb-10"><ViewUser userinfo={userinfo}  updateTemp={()=> updateTemp()} temp={temp} /></div>
                <AddUser userinfo={userinfo} updateTemp={()=> updateTemp()} />
            </div>
            {/* end::Form */}
        </div>
    );
}
