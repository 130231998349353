import React from "react";
import { Link } from "react-router-dom";
import {BASE_URL_OLD} from "../../../../env_config"
import { HashLink } from 'react-router-hash-link';
import Scrollspy from 'react-scrollspy'
import { KTCodeExample} from "../../controls";
import { toAbsoluteUrl } from "../../../_helpers";

export const LabApiHelp = () => {

    return (
        <>
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">API Documentation</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span> API Documentation</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <h3 className="card-title" id="uploading-API">Uploading the API (Application Programming Interface) file</h3>
                        </div>
                        <div className="card-body">
                            <p>Do the steps provided below for uploading the API document to our server;</p>
                            <p>Step 1: Repeat step 1 and step 2, see <Link to="/diamondupload#uploading-the-stock">Uploading the Stock</Link></p>
                            <p>Step 2: Upload the stock list using API. See <strong><HashLink to="#Introduction">Read API Documentation</HashLink></strong> </p>
                            <p> Refer to the steps provided in the API Documentation to upload the API file</p>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/14.jpg`)} alt="14" border="0" />
                                </figure>
                            </div>
                            <p>Step 3: Enter the API access key to upload the data file on to our server.</p>
                            <p>You have successfully completed uploading the API file.</p>
                            <p>Step 4: Step 4: [Optional] To generate a new API key click <b>Generate new API</b></p>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/15.jpg`)} alt="15" border="0" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <div className="card card-custom card-stretch">
                                <div className="card-body px-1">
                                    <div className="sticky-top" style={{top:'100px'}}>
                                        <Scrollspy className="help-scrollspy" items={ ['Introduction','Authentication', 'AddDiamonds', 'RoundDiamond','FancyShapeDiamond','FancyColoredDiamond','FancyColoredDiamond','DeleteDiamonds','error'] } currentClassName="is-current">
                                            <li><a href="#Introduction">Introduction</a></li>
                                            <li><a href="#Authentication">Authentication</a></li>
                                            <li><a href="#AddDiamonds">Add Diamonds</a></li>
                                            <li><a href="#RoundDiamond">Round Diamond</a></li>
                                            <li><a href="#FancyShapeDiamond">Fancy Shape Diamond</a></li>
                                            <li><a href="#FancyColoredDiamond">Fancy Colored Diamond</a></li>
                                            <li><a href="#UpdateDiamond">Update Diamond</a></li>
                                            <li><a href="#DeleteDiamonds">Delete Diamonds</a></li>
                                            <li><a href="#error">Error</a></li>
                                        </Scrollspy>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10">
                            <div className="card card-custom card-stretch">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 border-bottom mb-5" id="Introduction">
                                            <h3 ><strong>Introduction</strong></h3>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>The Diamonds On Call Supplier Centre API is organized around <b>REST</b>. Our API has predictable resource-oriented URLs, accepts <b>form-encoded</b> request bodies, returns <b>JSON-encoded</b> responses, and uses standard HTTP response codes, authentication, and verbs.</p>
                                                    <p>In the freeze mode, you can use the API key to test, it will not effect your live data. The API key you use to <b>authenticate</b> the request determines whether the request is active mode or test mode</p>
                                                    <p>The Diamonds On Call Supplier Centre API differs for every account as we release new <b>versions</b> and tailor functionality.</p>
                                                    <p><b>Base URL:</b> <a href={`${BASE_URL_OLD}/API/action.php`}>{`${BASE_URL_OLD}/API/action.php`}</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 border-bottom mb-5" id="Authentication">
                                            <h3 ><strong>Introduction</strong></h3>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>The Diamonds On Call Supplier Centre API uses API keys to authenticate requests.</p>
                                                    <p>Your API keys carry many privileges, so be sure to keep them secure. The secret API keys should not be shared in publicly accessible areas such as GitHub, client-side code, and so forth. We at Diamonds on Call Supplier Centre allow to keep the API key secure by allowing you to generate new keys by using the <b>Generate Key</b> command.</p>
                                                    <p>Authentication to the API is performed via <b>HTTP Basic Auth.</b> Provide your API key as the basic auth username value. You do not need to provide a password</p>
                                                    <p>All API requests must be made over <b>HTTPS</b>. Calls made over plain HTTP will fail. API requests without authentication will also fail.</p>
                                                    <h6><b>HTTP Method</b></h6>
                                                    <p>The https-based protocol-RESTCONF (RFC 8040), which is a stateless protocol, uses secure HTTP methods to provide CREATE, READ, UPDATE and DELETE (CRUD) operations on a conceptual datastore containing YANG-defined data.</p>
                                                    <h6><b>POST</b> (REQUEST AND RESPONSE) <b>for Uploading Diamonds </b></h6>
                                                    <p>The Diamond On Call Supplier Centre uses the API <b>POST</b> operations to create (add), update and delete diamonds. The <b>REQUEST</b> command lists all the diamond parameters required for each diamond. Refer <b>HELP &#62; Parameter</b> section by logging in to Diamonds On Call Supplier Centre, to add the parameter information.</p>
                                                    <p className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20 mt-3" style={{borderLeft:"5px solid"}}><em><strong>Note:</strong> All Parameter Name and Parameter Value are case sensitive.</em></p>
                                                    <p>Both success and failure messages are displayed in <b>RESPONSE</b> message after executing the <b>REQUEST</b> command. The success messages, will show the number of counts of valid, invalid, update, success or unsuccess etc. For more information on failure or error messages see <HashLink to="#error">Error Messages.</HashLink></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 border-bottom mb-5 pb-5" id="AddDiamonds">
                                            <h3 ><strong>Create (Add) Diamonds</strong></h3>
                                            <p>The Create (Add) operation is used to upload the list of diamonds. Every time this operation is executed it will upload the entire list of diamonds. For example, if you add a list of diamonds today by using the REQUEST command, the new list uploaded today will overwrite the existing list of diamonds.</p>
                                        </div>
                                        <div className="col-12 border-bottom border-secondary mb-5 pb-5" id="RoundDiamond">
                                            <h3 ><strong>Round Diamond</strong></h3>
                                            <p>To upload or create(add) the diamonds execute the below command:</p>
                                            <p>For round diamond parameters <Link to="/lab-apimode-help#RoundDiamond">click here</Link></p>
                                            <h5 className="mt-5">API REQUEST CODE</h5>
                                            <KTCodeExample jsCode={ApiRequestRoundCode} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <h5 className="mt-5">Response:</h5>
                                            <KTCodeExample jsCode={ApiResponseRoundCode} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                        <div className="col-12 border-bottom border-secondary mb-5 pb-5" id="FancyShapeDiamond">
                                            <h3 ><strong>Fancy Shape Diamond</strong></h3>
                                            <p>To upload or create(add) the diamonds execute the below command:</p>
                                            <p>For Fancy Shaped Diamond parameters <Link to="/lab-apimode-help#FancyShapeDiamond">click here</Link></p>
                                            <h5 className="mt-5">API REQUEST CODE</h5>
                                            <KTCodeExample jsCode={ApiRequestFancyShapeCode} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <h5 className="mt-5">Response:</h5>
                                            <KTCodeExample jsCode={ApiResponseFancyShapeCode} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                        <div className="col-12 border-bottom border-secondary mb-5 pb-5" id="FancyColoredDiamond">
                                            <h3 ><strong>Fancy Shape Diamond</strong></h3>
                                            <p>To upload or create(add) the diamonds execute the below command:</p>
                                            <p>For round diamond parameters <Link to="/lab-apimode-help#FancyColoredDiamond">click here</Link></p>
                                            <h5 className="mt-5">API REQUEST CODE</h5>
                                            <KTCodeExample jsCode={ApiRequestFancyColoredCode} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <h5 className="mt-5">Response:</h5>
                                            <KTCodeExample jsCode={ApiResponseFancyColoredCode} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                        <div className="col-12 border-bottom mb-5 pb-5" id="UpdateDiamond">
                                            <h3 ><strong>Update Diamond</strong></h3>
                                            <p>The update operation is used to update the list of diamonds. You can update an entire diamond parameter list or a single parameter.</p>
                                            <div className="px-5 border-bottom border-secondary mb-5 pb-5" id="RoundDiamond_up">
                                                <h3 ><strong>Fancy Shape Diamond</strong></h3>
                                                <p>To update a diamond parameter list, execute the below command:</p>
                                                <p>For parameters <Link to="/lab-apimode-help">click here</Link></p>
                                                <h5 className="mt-5">API REQUEST CODE</h5>
                                                <KTCodeExample jsCode={ApiRequestUpdateCode} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                                <h5 className="mt-5">Response:</h5>
                                                <KTCodeExample jsCode={ApiResponseUpdateCode} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                            </div>
                                        </div>
                                        <div className="col-12 border-bottom mb-5 pb-5" id="DeleteDiamonds">
                                            <h3 ><strong>Delete Diamonds (POST)</strong></h3>
                                            <p>The delete operation is used to delete the list of diamonds. If you want to delete a diamond which is invalid, or cancelled or delivered, you can use this command to delete it from the existing list of diamonds in the upload history.</p>
                                            <div className="px-5 border-bottom border-secondary mb-5 pb-5" id="RoundDiamond_dd">
                                                <h5 className="mt-5">API REQUEST CODE</h5>
                                                <KTCodeExample jsCode={ApiRequestDeletCode} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                                <h5 className="mt-5">Response:</h5>
                                                <KTCodeExample jsCode={ApiResponseDeletCode} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-5 pb-5" id="error">
                                            <h3 ><strong>Error Messagese</strong></h3>
                                            <p>Diamond On Call Supplier Centres uses conventional HTTP response codes to indicate the success or failure of an API request. </p>
                                            <div class="table-responsive deleteTable">
                                                <table class="table table-bordered">
                                                    <thead className="bg-gray-100">
                                                        <tr>
                                                            <th>Error Types </th>
                                                            <th>Error Messages Displayed </th>
                                                            <th>Description </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Authentication Failed</td>
                                                            <td>
<pre>
    <code>{
`{
    "success":false,
    "status": "fail",
    "Message":"Authentication Failed"
}
`}</code>
</pre>
                                                            </td>
                                                            <td>
                                                                When you enter a wrong email or API key this message will be displayed, as shown below<br/>
<pre>
<code>{
`{
    "email":"supplier@supplier.com",
    "apikey":"312645XXXX"
}
`}</code>
</pre>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Please Enter valid Parameters </td>
                                                            <td>
<pre>
<code>{
`{
    "success":"false,
    "status":"fail",
    "Message":"Please Enter valid Parameters"
}`
}</code>
</pre>
                                                            </td>
                                                            <td>When one of the parameters requested is wrong or invalid. For example, under the following code if you write the <b><em>“carat”</em></b> spelling wrong or you miss any syllable under <b><em>“stock_no”</em></b>
<pre>
<code>{
`"availability":"G",
"stock_no":"132",
"carat":"2.21",
"shape":"Round",
"color":"G",
"clarity":"VS2",
`}</code>
</pre>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Please Enter Action valid Parameters </td>
                                                            <td>
<pre>
<code>{
`{
    "success":false,
    "status":"fail",
    "Message":"Please Enter action valid Parameters"
}
`}</code>
</pre>
                                                            </td>
                                                            <td>If you enter the wrong code under the <b><em>“Action”</em></b> parameter this error will show. For example, under the following code if you write wrong code
<pre>                                                            
<code>{
`{
    "lab_grown_update"
    "action":"lab_grown_update"
}`
}</code>
</pre>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Something went wrong </td>
                                                            <td>
<pre>
<code>{
`{
    "success":false,
    "status":"fail",
    "Message":"Something went wrong"
}`
}
</code>
</pre>
                                                            </td>
                                                            <td>
                                                                If during the process of requesting data, suddenly you loose connection or it stops of any other reason it will show something went wrong.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ApiRequestRoundCode =`
Body: {
    "action":""lab_grown_upload",
    "email":"supplier.abc@xyz.com",
    "apikey":"39805123565652226",
    "stone":[
        {
            "availability":"G",
            "stock_no":"13263",
            "carat":"2.21",
            "shape":"Round",
            "color":"G",
            "clarity":"VS2",
            "cut":"EX",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"None",
            "fluorescence_color":" ",
            "Lab":"IGI",
            "certi_NO":"123456789",
            "length":"3.88",
            "width":"3.84",
            "depth":"2.63",
            "tableP":"55.5",
            "defthP":"62.2",
            "cr_ag":"36.3",
            "cr_ht":"14.5",
            "pv_ag":"40.6",
            "pv_dp":"42.5",
            "per_carat":"14500",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"1.0",
            "culet":"",
            "image":"http://imgbb.com/img_13263.jpeg",
            "video":"http://imgbb.com/video_13263.mp4",
            "heart":"http://imgbb.com/heart_13263.jpeg",
            "arrow":"http://imgbb.com/arrow_13263.jpeg",
            "aset":"http://imgbb.com/asset_13263.jpeg",
            "country":"Hong Kong",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Natural",
            "fancy_color":"",
            "fancy_intensity":"",
            "fancy_overtone":"",
            "treatment":"As Grown",
            "growth type":"CVD"
        },
        {
            "availability":"A",
            "stock_no":"45456",
            "carat":"1.81",
            "shape":"Round",
            "color":"G",
            "clarity":"VS2",
            "cut":"EX",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"None",
            "fluorescence_color":" ",
            "lab":"IGI",
            "certi_no":"54544",
            "length":"7.85",
            "width":"7.81",
            "depth":"4.85",
            "tableP":"55",
            "defthP":"61.9",
            "cr_ag":"34",
            "cr_ht":"15.5",
            "pv_ag":"43",
            "pv_dp":"40.8",
            "per_carat":"14500",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"3.3",
            "culet":"",
            "image":"http://imgbb.com/img_45456.jpeg",
            "video":"http://imgbb.com/video_45456.mp4",
            "heart":"http://imgbb.com/heart_45456.jpeg",
            "arrow":"http://imgbb.com/arrow_45456.jpeg",
            "aset":"http://imgbb.com/asset_45456.jpeg",
            "country":"india",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Indented Natural",
            "fancy_color":"",
            "fancy_intensity":"",
            "fancy_overtone":"",
            "treatment":"As Grown",
            "growth type":"CVD"
        }
	]
}

`;

const ApiResponseRoundCode =`
{
    "success": true,
    "status": "success",
    "Message": "insert : 3 || Updated : 0 || Invalid : 32 || Total: 35 "
}

`;

const ApiRequestFancyShapeCode=`
Body: {
    "action":"lab_grown_upload",
    "email":"supplier.abc@xyz.com",
    "apikey":"39805123565652226",
    "stone":[
        {
            "availability":"A",
            "stock_no":"155232",
            "carat":"1.5",
            "shape":"Princess",
            "color":"None",
            "clarity":"SI1",
            "cut":" ",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"VS",
            "fluorescence_color":" ",
            "lab":"GIA",
            "certi_no":"123456889",
            "length":"3.84",
            "width":"3.75",
            "depth":"2.55",
            "tableP":"68",
            "defthP":"72.3",
            "cr_ag":" ",
            "cr_ht":" ",
            "pv_ag":" ",
            "pv_dp":" ",
            "per_carat":"1500",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"4.5",
            "culet":"",
            "image":"http://imgbb.com/img_155232.jpeg",
            "video":"http://imgbb.com/video_155232.mp4",
            "heart":"http://imgbb.com/heart_155232.jpeg",
            "arrow":"http://imgbb.com/arrow_155232.jpeg",
            "aset":"http://imgbb.com/asset_155232.jpeg",
            "country":"HONGKONG",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Cloud, Crystal",
            "fancy_color":"",
            "fancy_intensity":"",
            "fancy_overtone":"",
            "treatment":"As Grown",
            "growth type":"CVD"
        },
        {
            "availability":"H",
            "stock_no":"1233141",
            "carat":"0.71",
            "shape":"Cushion",
            "color":"E",
            "clarity":"SI1",
            "cut":" ",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"None",
            "fluorescence_color":" ",
            "lab":"GIA",
            "certi_no":"123456889",
            "length":"5.07",
            "width":"4.94",
            "depth":"3.37",
            "tableP":"56",
            "defthP":"68.3",
            "cr_ag":" ",
            "cr_ht":" ",
            "pv_ag":" ",
            "pv_dp":" ",
            "per_carat":"585",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"3.5",
            "culet":"",
            "image":"http://imgbb.com/img_1233141.jpeg",
            "video":"http://imgbb.com/video_1233141.mp4",
            "heart":"http://imgbb.com/heart_1233141.jpeg",
            "arrow":"http://imgbb.com/arrow_1233141.jpeg",
            "aset":"http://imgbb.com/asset_1233141.jpeg",
            "country":"INDIA",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Pinpoint Internal Graining",
            "fancy_color":"",
            "fancy_intensity":"",
            "fancy_overtone":"",
            "treatment":"As Grown",
            "growth type":"CVD"
        }
	]
}
`;

const ApiResponseFancyShapeCode=`
{
    "success": true,
    "status": "success",
    "Message": "insert : 3 || Updated : 0 || Invalid : 32 || Total: 35 "
}
`;

const ApiRequestFancyColoredCode =`
Body: {
    "action":"lab_grown_upload",
    "email":"supplier.abc@xyz.com",
    "apikey":"39805123565652226",
    "stone":[
        {
            "availability":"H",
            "stock_no":"1255621",
            "carat":"1.5",
            "shape":"Round",
            "color":"Fancy",
            "clarity":"VVS1",
            "cut":" ",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"None",
            "fluorescence_color":" ",
            "lab":"GIA",
            "certi_no":"1234566789",
            "length":"7.34",
            "width":"7.28",
            "depth":"4.55",
            "tableP":"57",
            "defthP":"72.3",
            "cr_ag":" ",
            "cr_ht":" ",
            "pv_ag":" ",
            "pv_dp":" ",
            "per_carat":"8131",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"3.3",
            "culet":"",
            "image":"http://imgbb.com/img_1255621.jpeg",
            "video":"http://imgbb.com/video_1255621.mp4",
            "heart":"http://imgbb.com/heart_1255621.jpeg",
            "arrow":"http://imgbb.com/arrow_1255621.jpeg",
            "aset":"http://imgbb.com/asset_1255621.jpeg",
            "country":"UNITED STATES",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Cloud Pinpoint",
            "fancy_color":"Pink",
            "fancy_intensity":"Fancy Intense",
            "fancy_overtone":"Purplish",
            "treatment":"As Grown",
            "growth type":"CVD"
    },
            "stone":[
    {
            "availability":"A",
            "stock_no":"1233132",
            "carat":"1.5",
            "shape":"Round",
            "color":"Fancy",
            "clarity":"SI1",
            "cut":" ",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"Very Strong",
            "fluorescence_color":" ",
            "lab":"GIA",
            "certi_no":"1234556789",
            "length":"7.29",
            "width":"7.23",
            "depth":"4.60",
            "tableP":"58",
            "defthP":"63.4",
            "cr_ag":" ",
            "cr_ht":" ",
            "pv_ag":" ",
            "pv_dp":" ",
            "per_carat":"2842",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"1.0",
            "culet":"",
            "image":"http://imgbb.com/img_1233132.jpeg",
            "video":"http://imgbb.com/video_1233132.mp4",
            "heart":"http://imgbb.com/heart_1233132.jpeg",
            "arrow":"http://imgbb.com/arrow_1233132.jpeg",
            "aset":"http://imgbb.com/asset_1233132.jpeg",
            "country":"INDIA",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Knot",
            "fancy_color":"Yellow",
            "fancy_intensity":"Fancy Light",
            "fancy_overtone":"Greyish Greenish",
            "treatment":"As Grown",
            "growth type":"CVD"
        }
    ]
}
`;

const ApiResponseFancyColoredCode =`
{
    "success": true,
    "status": "success",
    "Message": "insert : 3 || Updated : 0 || Invalid : 32 || Total: 35 "
}
`;

const ApiRequestUpdateCode =`
Body: {
    "action":"lab_grown_update",
    "email":"supplier.abc@xyz.com",
    "apikey":"39805123565652226",
    "stone":[
        {
            "availability":"G",
            "stock_no":"13263",
            "carat":"2.21",
            "shape":"Round",
            "color":"G",
            "clarity":"VS2",
            "cut":"EX",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"None",
            "fluorescence_color":" ",
            "lab":"IGI",
            "certi_no":"123456789",
            "length":"3.88",
            "width":"3.84",
            "depth":"2.63",
            "tableP":"55.5",
            "defthP":"62.2",
            "cr_ag":"36.3",
            "cr_ht":"14.5",
            "pv_ag":"40.6",
            "pv_dp":"42.5",
            "per_carat":"14500",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"1.0",
            "culet":"",
            "image":"http://imgbb.com/img_13263.jpeg",
            "video":"http://imgbb.com/video_13263.mp4",
            "heart":"http://imgbb.com/heart_13263.jpeg",
            "arrow":"http://imgbb.com/arrow_13263.jpeg",
            "aset":"http://imgbb.com/asset_13263.jpeg",
            "country":"Hong Kong",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Natural",
            "fancy_color":"",
            "fancy_intensity":"",
            "fancy_overtone":"",
            "treatment":"As Grown",
            "growth type":"CVD"

        },
		{
            "availability":"A",
            "stock_no":"45456",
            "carat":"1.81",
            "shape":"Round",
            "color":"G",
            "clarity":"VS2",
            "cut":"EX",
            "polish":"EX",
            "symmetry":"EX",
            "fluorescence":"None",
            "fluorescence_color":" ",
            "lab":"IGI",
            "certi_no":"54544",
            "length":"7.85",
            "width":"7.81",
            "depth":"4.85",
            "tableP":"55",
            "defthP":"61.9",
            "cr_ag":"34",
            "cr_ht":"15.5",
            "pv_ag":"43",
            "pv_dp":"40.8",
            "per_carat":"14500",
            "girdle_thin":" ",
            "girdle_thick":"",
            "girdle_con":"",
            "gridle":"3.3",
            "culet":"",
            "image":"http://imgbb.com/img_45456.jpeg",
            "video":"http://imgbb.com/video_45456.mp4",
            "heart":"http://imgbb.com/heart_45456.jpeg",
            "arrow":"http://imgbb.com/arrow_45456.jpeg",
            "aset":"http://imgbb.com/asset_45456.jpeg",
            "country":"india",
            "shade":"noshade",
            "milky":"NO MILKY",
            "eye_clean":"YES",
            "kts":"Indented Natural",
            "fancy_color":"",
            "fancy_intensity":"",
            "fancy_overtone":"",
            "treatment":"As Grown",
            "growth type":"CVD"
        }
	]
}
`;

const ApiResponseUpdateCode =`
{
    "success": true,
    "status": "success",
    "Message": " Updated : 4 ||  Not found : 3 ||  Invalid : 2 "
}
`;

const ApiRequestDeletCode =`
Body: {
    "action":"lab_grown_delete",
    "email":"supplier.abc@xyz.com",
    "apikey":"39805123565652226",
    "stone":[
        {
            "certi_no": "154456789",
            "carat":"2.21"
        },
        {
            "certi_no": "154456000",
            "carat":"2.21"
        }
      ]
    }
`;

const ApiResponseDeletCode =`
{
    "success": true,
    "status": "success",
    "Message": " successful :5 || Unsuccessful : 3"
  }
    
`;