import React from "react"

const FeaturesCard = (props) =>{
    return(
        <>
            <div className="col-sm-6 col-md-5 col-lg-4 item">
                <div className="box">
                    <img src={props.imgsrc} alt="automated-inventory-upload" title="Automated Inventory Upload" />
                    <h3 className="name bold">{props.title}</h3>
                    <p className="description">{props.content}</p>
                </div>
            </div>
        </>
    )
}
export default FeaturesCard;