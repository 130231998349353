/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React ,{useEffect} from "react";
import { FaGem } from "react-icons/fa";
// import RecentOrderData from "./RecentOrderData";
import RecentOrderTbody from "./RecentOrderTbody";
export function RecentOrderDetails(props) {
// console.log(props.recentOrderData.created_date)
//   useEffect(() => {
//     const currentDate = new Date();  // current date and time
//     let alertTriggered = false;  // flag to track if an alert has been triggered

//     props.recentOrderData.forEach(entry => {
//       const entryDate = new Date(entry.created_date);  // convert created_date to Date object
//       const timeDifference = currentDate - entryDate;  // calculate time difference in milliseconds
//       const daysDifference = timeDifference / (1000 * 60 * 60 * 24);  // convert milliseconds to days

//       if (daysDifference > 30 && !alertTriggered) {
//         // Trigger alert message for the first entry that is older than 30 days
//         alert(`Alert: One or more entries are 30 days or older!`);
//         alertTriggered = true;  // set the flag to true, so no more alerts will be triggered
//       }else{
//         alert(`Alert:all set`);
//         alertTriggered = true;  // set the flag to true, so no more alerts will be triggered
//       }
//     });
//   }, []);

  // console.log(recentOrderData.recentOrderData)
  return (
    <>
      {/* begin::Advance Table Widget 9 */}
      <div className={`shadow-xs card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <div className="card-header">
          <div className="card-title align-items-center">
            <FaGem /> <h3 className="ml-3 mb-0" >Recent Order Details</h3>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            {/* begin::Table */}
            <div className="table-responsive">
              <table className="table table-hover kycTable">
                <thead>
                  <tr>
                    <th className="">Status</th>
                    <th className="text-center">Date</th>
                    <th className="">Diamond details</th>
                    {/* <th className="text-center">Stone ID</th>
                    <th className="text-center">Weight</th>
                    <th className="text-center">Shape</th>
                    <th className="text-center">Color</th>
                    <th className="text-center">Clarity</th>
                    <th className="text-center">Cut</th>
                    <th className="text-center">Polish</th>
                    <th className="text-center">Sym</th>
                    <th className="text-center">Flo</th>
                    <th className="text-center">LAB</th>
                    <th className="text-center">Certi No.</th> 
                    <th className="text-center">$/CT</th>
                    <th className="text-center">Total Price</th>*/}
                    <th className="text-right">Price</th>
                  </tr>
                </thead>
                {props.recentOrderData.length !== 0 ? 
                  <tbody>
                      {props.recentOrderData.slice(0, 5).map((val) => {
                          return(
                              <RecentOrderTbody 
                                  key={val.id}
                                  userinfo_timezone={props.userinfo.userinfo.timezone}
                                  {...val}
                              />
                          );    
                      })}
                    </tbody> 
                  : <tbody><tr><td className="text-center border-bottom" colSpan="100%">No record found</td></tr></tbody> }
              </table>
            </div>
            {/* end::Table */}
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}
