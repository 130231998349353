import React, { useEffect } from "react";
import { useSubheader } from "../../layout";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { ApprovedFeed } from "./approvedFeed/ApprovedFeed";
import { RejectedFeed } from "./rejectedFeed/RejectedFeed";
import { PendingFeed } from "./pendingFeed/PendingFeed";
import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const DiamondFeed = ({ userinfo,subId }) => {
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)

    const [loader,setLoader] = React.useState(true)

    const suhbeader = useSubheader();
    suhbeader.setTitle("Diamond Feed");

    useEffect(() => {
        document.title = "Diamond Feed | Diamonds on Call - Suppliers Center";

        let isMounted = true;

        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":10
            };
            axios({
              method: "POST",
              url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
              headers: { "Content-Type": "application/json" },
              data: permiFetchData,
            }).then((res) => {
                setLoader(false)
            //   console.log(res.data.data[0])
                if (res && res.data) {
                    isMounted && setEditPerm(res.data.data[0].edit_permission);
                    isMounted && setReadPerm(res.data.data[0].read_permission);
                }
            }).catch(error => {
                setLoader(false)
                // console.log(error,"ERROR")
            });
          }else{
              setLoader(false)
          }
    }, [subId]);

    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <>
            {!loader ?
                <>
                    {readPerm || !subId ?
                        <>
                            {userinfo.stock_access_status === 2 ? (
                                <div className="card card-custom gutter-b">
                                    <div
                                        className="card-header tabbed-card pb-0"
                                        style={{ minHeight: "50px!important" }}
                                    >
                                        <div className="card-title">
                                            <span className="card-label font-weight-bolder text-dark">
                                                Diamond Feed
                                            </span>
                                        </div>
                                        <div className="card-toolbar m-0 flex-md-nowrap">
                                            <Paper
                                                position="static"
                                                className="shadow-none border-bottom"
                                            >
                                                <Tabs
                                                    value={value}
                                                    indicatorColor="secondary"
                                                    textColor="secondary"
                                                    onChange={handleTabChange}
                                                >
                                                    <Tab label="Pending Request" />
                                                    <Tab label="Approved Request" />
                                                    <Tab label="Rejected/Cancelled Request" />
                                                </Tabs>
                                            </Paper>
                                        </div>
                                    </div>
                                    {value === 0 && (
                                        <TabContainer>
                                            <PendingFeed userinfo={userinfo} subId={subId} editPerm={editPerm}/>
                                        </TabContainer>
                                    )}
                                    {value === 1 && (
                                        <TabContainer>
                                            <ApprovedFeed userinfo={userinfo}  subId={subId} editPerm={editPerm}/>
                                        </TabContainer>
                                    )}
                                    {value === 2 && (
                                        <TabContainer>
                                            <RejectedFeed userinfo={userinfo}  subId={subId} editPerm={editPerm}/>
                                        </TabContainer>
                                    )}
                                </div>
                            ) : userinfo.stock_access_status === 3 ? (
                                <div className="card card-custom gutter-b">
                                    <div className="card-body">
                                        Your Current Setting : 
                                        <span className="font-weight-bold pl-2">
                                            Turn off Access(Nobody can use your api)
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className="card card-custom gutter-b">
                                    <div className="card-body">
                                        Your Current Setting : 
                                        <span className="font-weight-bold pl-2">
                                            Access to all(This will allow all user to access your api)
                                        </span>
                                    </div>
                                </div>
                            )}
                        </>
                    : 
                        <div className="card card-custom gutter-b">
                            <div className="card-header py-3 mb-3">
                                <div className="card-title">
                                    <span className="card-label font-weight-bolder text-dark">
                                        Diamond Feed
                                    </span>
                                </div>
                            </div>
                            <div className="card-body text-center">
                                You do not have permission to access, Please contact your administrator for help
                            </div>
                        </div>
                    }
                </>
            : 
                <div className="card card-custom gutter-b">
                    <div className="card-header py-3 mb-3">
                        <div className="card-title">
                            <span className="card-label font-weight-bolder text-dark">
                               Diamond Feed
                            </span>
                        </div>
                    </div>
                    <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
                        Loading...<div className="spinner spinner-primary ml-5"></div>
                    </div>
                </div>
            }
        </>
    );
};
