import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';

export default function InputField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
  // console.log(field,"ERRORROROR")
  return (

    <PhoneInput
      country={'in'}
    //   value={this.state.phone}
    //   onChange={phone => this.setState({ phone })}
      error={meta.touched && meta.error && true}
      isValid={(value, country) => {
        if (meta.error) {
          return meta.error;
        } else {
          return true;
        }
      }}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
/>
  );
}
