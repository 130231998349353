import React, { useEffect } from "react";
import { useSubheader } from "../../layout";
import { toAbsoluteUrl } from "../../_helpers";
import { Link } from "react-router-dom";

export const Help = ({userinfo}) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Help");

    useEffect(() => {
        document.title = "Help | Diamonds on Call - Suppliers Center";
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="font-size-h2 font-weight-bold">Help</div>
                    <div className="font-size-lg font-weight-bold text-dark-50">
                        Browse the sections below for your question, to operate Diamonds On Call Supplier Center.
                    </div>
                </div>
                <div className="col-12 mt-10">
                    <div className="row">
                        <div className="col-lg-4 col-xl-3">
                            <div className="card card-custom gutter-b help-card">
                                <div className="card-body">
                                    <img className="" alt="help" src={toAbsoluteUrl("/media/General/3d-design.png")} />
                                    <div className="font-size-h4 font-weight-bold mt-5"> Getting Started </div>
                                    <div className="mt-5">
                                        <Link className="font-weight-lg" to="/kyc-help">KYC & Approval</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3">
                            <div className="card card-custom gutter-b help-card">
                                <div className="card-body">
                                    <img className="" alt="help" src={toAbsoluteUrl("/media/General/diamond_help.png")} />
                                    <div className="font-size-h4 font-weight-bold mt-5"> Diamond upload </div>
                                    <div className="mt-5">
                                        <Link className="font-weight-lg d-block" to="/uploadmode-help">Introduction</Link>
                                        <Link className="font-weight-lg d-block" to="/ftpmode-help">FTP</Link>
                                        {userinfo.type === "Natural" ? 
                                            <Link className="font-weight-lg d-block" to="/apimode-help">API Documentation</Link>
                                        :
                                            <Link className="font-weight-lg d-block" to="/lab-apimode-help">API Documentation</Link>
                                        }
                                        <Link className="font-weight-lg d-block" to="/filemode-help">Manual Upload</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3">
                            <div className="card card-custom gutter-b help-card">
                                <div className="card-body">
                                    <img className="" alt="help" src={toAbsoluteUrl("/media/General/Expensive-Investment.png")} />
                                    <div className="font-size-h4 font-weight-bold mt-5">Parameters </div>
                                    {userinfo.type === "Natural" ? 
                                        <div className="mt-5">
                                            <Link className="font-weight-lg d-block" to="/parameter-help">Round Diamond</Link>
                                            <Link className="font-weight-lg d-block" to="/parameter-help">Fancy Shape Diamond</Link>
                                            <Link className="font-weight-lg d-block" to="/parameter-help">Fancy Colored Diamond</Link>
                                        </div>
                                    :
                                        <div className="mt-5">
                                            <Link className="font-weight-lg d-block" to="/lab-parameter-help">Round Diamond</Link>
                                            <Link className="font-weight-lg d-block" to="/lab-parameter-help">Fancy Shape Diamond</Link>
                                            <Link className="font-weight-lg d-block" to="/lab-parameter-help">Fancy Colored Diamond</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3">
                            <div className="card card-custom gutter-b help-card">
                                <div className="card-body">
                                    <img className="" alt="help" src={toAbsoluteUrl("/media/General/award.png")} />
                                    <div className="font-size-h4 font-weight-bold mt-5"> Diamond Result </div>
                                    <div className="mt-5">
                                        <Link className="font-weight-lg d-block" to="/search-help">Search Diamonds</Link>
                                        <Link className="font-weight-lg d-block" to="/search-help">Invalid Diamonds</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3">
                            <div className="card card-custom gutter-b help-card">
                                <div className="card-body">
                                    <img className="" alt="help" src={toAbsoluteUrl("/media/General/Expensive-Investment.png")} />
                                    <div className="font-size-h4 font-weight-bold mt-5"> Order </div>
                                    <div className="mt-5">
                                    <Link className="font-weight-lg d-block" to="/order-help">Hold Request</Link>
                                    <Link className="font-weight-lg d-block" to="/order-help">Confirmed order</Link>
                                    <Link className="font-weight-lg d-block" to="/order-help">Sale history</Link>
                                    <Link className="font-weight-lg d-block" to="/order-help">Delivery & Payments</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3">
                            <div className="card card-custom gutter-b help-card">
                                <div className="card-body">
                                    <img className="" alt="help" src={toAbsoluteUrl("/media/General/api-icon.png")} />
                                    <div className="font-size-h4 font-weight-bold mt-5"> Api Request </div>
                                    <div className="mt-5">
                                        <Link className="font-weight-lg d-block" to="/help">Pending API Request</Link>
                                        <Link className="font-weight-lg d-block" to="/help">Approve API Request</Link>
                                        <Link className="font-weight-lg d-block" to="/help">Reject API Request</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3">
                            <div className="card card-custom gutter-b help-card">
                                <div className="card-body">
                                    <img className="" alt="help" src={toAbsoluteUrl("/media/General/need_help.png")} />
                                    <div className="font-size-h4 font-weight-bold mt-5"> Need Help? </div>
                                    <div className="mt-5">
                                        <Link className="font-weight-lg" to="/contact-help">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
