import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function UploadHistorySkeleton() {
    return (
        <>
            <div className="card-body pt-5 pb-0">
                <div className="d-flex justify-content-between">
                    <div className="card-title align-items-start">
                        <Skeleton variant="text" className="mr-3" width={100} />
                        <Skeleton variant="text" className="mr-3" width={280} height={10} />
                    </div>
                    <div className="card-toolbar d-flex flex-md-nowrap align-items-center">
                        <Skeleton className="mr-3" variant="rect" width={140} height={30} />
                        <Skeleton className="mr-3" variant="text" width={5} height={40} />
                    </div>
                </div>
                <div className="table-responsive custom-scrollbar w-100">
                    <table className="table table-hover table-striped">
                        <thead className="custom_datatable_head">
                            <tr>
                                {Array.from(Array(9), (e, i) => {
                                    return (
                                        <th key={i}><Skeleton className="mx-auto" variant="text" width={50} height={20} /></th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody >
                            {Array.from(Array(8), (e, i) => {
                                return (
                                    <tr key={i}>
                                        <td><Skeleton className="mx-auto" variant="text" width={130} height={20} /></td>
                                        <td><Skeleton className="mx-auto" variant="text" width={50} height={20} /></td>
                                        {Array.from(Array(2), (e, i) => {
                                            return (
                                                <td key={i}><Skeleton className="mx-auto" variant="text" width={100} height={20} /></td>
                                            );
                                        })}
                                        <td><Skeleton className="mx-auto" variant="text" width={150} height={25} /></td>
                                        {Array.from(Array(3), (e, i) => {
                                            return (
                                                <td key={i}><Skeleton className="mx-auto" variant="text" width={40} height={20} /></td>
                                            );
                                        })}
                                        <td key={i}><Skeleton className="mx-auto" variant="rect" width={80} height={25} /></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer border-0 d-flex align-items-center justify-content-between">
                <Skeleton className="mr-3" variant="text" width={100} height={40} />
                <Skeleton className="mr-3" variant="text" width={200} height={50} />
                <Skeleton className="mr-3" variant="text" width={200} height={50} />
            </div>
        </>
    );
}
