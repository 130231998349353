import AutometedInv from  "../Images/automated-inventory-upload.png"
import OrderMgt from  "../Images/order-management.png"
import NoReturn from  "../Images/no-return.png"

const FeaturesData= [
    {
        id:"1",
        imgsrc:AutometedInv,
        title:"Automated Inventory Upload",
        content:"Upload your diamond inventory using Excel, API or FTP with ease"
    },
    {
        id:"2",
        imgsrc:OrderMgt,
        title:"Order Management",
        content:"Manage your diamond orders and delivery all at one place & receive notification on the go."
    },
    {
        id:"3",
        imgsrc:NoReturn,
        title:"No returns",
        content:"We check all the aspects of diamond, so customers can get diamonds conflict free with no returns."
    }
]
export default FeaturesData;