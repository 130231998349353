import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import moment from "moment-timezone";
import { FcShipped } from "react-icons/fc";

// function jugaadDate(x) {
//     // const tempDateNew = moment.tz(x, "America/St_Johns").format("YYYY-MM-DD HH:mm:ss");
//     // const dateNew = moment.tz(tempDateNew, "America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss");
//     // return dateNew

//     let storedDate = new Date(x);
//     storedDate = new Date(storedDate.getTime() - (330 * 60 * 1000));
//     return storedDate
// }

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const confirmHistoryColumn = (userinfo) => [

   
    {
        Header: "Status",
        accessor: "status",
        className: "text-center align-middle",
        Cell: (row) => (
            <>
                {row.row.original.v_customer === "12"  ? 
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
                    >
                       <div className="d-flex align-items-center justify-content-center">
                           {row.row.original.status === 'QC REVIEW IN' || row.row.original.status === 'QC REVIEW OUT' ? <span className={`label label-lg label-outline-${row.row.original.supplier_mgr_appr.supplier_mgr_appr == 'N' ? "danger" : "warning"} label-pill label-inline bg-${row.row.original.supplier_mgr_appr.supplier_mgr_appr == 'N' ? "danger" : "warning"}-o-20 font-weight-bold text-nowrap`}>QC REVIEW</span> : null }
                           {row.row.original.status === 'QC_OK' ? <span className={`label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap`}>QC OK</span> : null}
                           {row.row.original.status === 'QC_OK_OUT' ? <span className={`label label-lg label-outline-warning label-pill label-inline bg-warning-o-20 font-weight-bold text-nowrap`}>QC OK OUT </span> :null}
                           {row.row.original.status === 'PENDING'||row.row.original.status === 'READY_TO_PICKUP' ? <span className="label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Confirmed</span> : null}
                            <FcShipped className="ml-2 font-size-h4"/>
                       </div>
                    </OverlayTrigger>
                : 
                    <>
                        {row.row.original.status === 'QC REVIEW IN' || row.row.original.status === 'QC REVIEW OUT' ? <span className={`label label-lg label-outline-${row.row.original.supplier_mgr_appr.supplier_mgr_appr == 'N' ? "danger" : "warning"} label-pill label-inline bg-${row.row.original.supplier_mgr_appr.supplier_mgr_appr == 'N' ? "danger" : "warning"}-o-20 font-weight-bold text-nowrap`}>QC REVIEW</span> : null }
                        {row.row.original.status === 'QC_OK' ? <span className={`label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap`}>QC OK</span> : null}
                        {row.row.original.status === 'QC_OK_OUT' ? <span className={`label label-lg label-outline-warning label-pill label-inline bg-warning-o-20 font-weight-bold text-nowrap`}>QC OK OUT </span> :null}
                        {row.row.original.status === 'PENDING'||row.row.original.status === 'READY_TO_PICKUP' ? <span className="label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Confirmed</span> : null}
                    </>
                }
                
            </>
        ),
    },
    {
        Header: "Date & Time",
        // accessor: "created_date",
        accessor: (d) => {
            return (
                <>
                    {  d.created_date ? moment.tz(d.created_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Order ID",
        accessor: "gid",
        className: "text-center align-middle",
    },
    // {
    //     Header: "Stone ID" ,
    //     accessor: "Loat_NO",
    //     className: "text-center align-middle",
        
    // },
    {
        Header: "Diamond Detail",
        accessor: (d) => (
            <>
            {d.diamond_type === "PN" || d.diamond_type === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.C_Shape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.C_Shape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamond_type}_diamond mr-2 small`}>
                                {d.diamond_type}
                            </span>
                            <span className={`text-primary small ml-3`}>
                                {d.diamond_type}-{d.parcel_id}-{d.split_id}{d.cg_add_split_accept ?<>/{d.cg_add_split_accept}</> : ""}
                            </span>
                            <span className={`text-primary text-capitalize small ml-3`}>
                                {d.length_mm} - {d.width_mm} MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {(d.C_Weight).toFixed(2)}Ct.{" "}
                            {d.C_Color}{" "}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.aws_image &&  d.aws_image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={d.aws_image}
                                width="50px"
                                onError={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                )}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "W" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamond_type === "W" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                {d.diamond_type === "W" ? d.C_Color === "fancy"  ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <span  className={`text-primary small ml-3`}>
                                {d.Loat_NO}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.Lab === "IGI"
                                        ? `https://www.igi.org/viewpdf.php?r=${d.Certi_NO}`
                                        : d.Lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${d.Certi_NO}`
                                        : d.Lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.Certi_NO}`
                                        : d.Lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.Certi_NO}`
                                        : d.Certi_link
                                }
                            >
                                {d.Lab}-{d.Certi_NO}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {(d.C_Weight).toFixed(2)}Ct.{" "}
                            {d.C_Color === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.C_Color}{" "}</>
                            )}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Price",
        className: "text-right  text-nowrap align-middle pr-5",
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    ${numberWithCommas(parseFloat(d.a_price))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT ${numberWithCommas(parseFloat(d.a_C_Rate))}
                </span>
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),

    },

    {
        Header: "Del.Location",
        className: "text-center align-middle",
        accessor: "first_location",
    },
    {
        Header: "Exchange Rate",
        className: "text-center align-middle",
        accessor: (d) => {
            return (
                <>
                   {d.cur_inr <= 1 ?  "$" : <span style={{fontFamily:"sans-serif"}}>₹</span>}{parseFloat(d.cur_inr).toFixed(2)}
                </>
            );
        },
       
    },
    {
        Header: "Local Price",
        className: "text-right  text-nowrap align-middle",
        accessor: (d) => {
            return (
                <>
                    {d.first_location === 'Mumbai' || d.first_location === 'Surat' ? 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in INR</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span> {numberWithCommas(d.a_price * d.cur_inr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span>/CT: <span style={{fontFamily:"sans-serif"}}> ₹</span> {numberWithCommas(d.cur_inr * d.a_C_Rate)}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                            
                        </> : 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in USD</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        $ {numberWithCommas(d.a_price * d.cur_inr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        $/CT: <span style={{fontFamily:"sans-serif"}}> </span> {numberWithCommas(d.cur_inr * d.a_C_Rate)}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                        </> 
                    }
                </>
            );
        },
       
    }
];
