import React from "react";
import Footer from "./Footer"
import Banner from "./Homepage/Banner";
import HowitworkSec from "./Homepage/HowitworkSec";
import Contact from "./Homepage/Contact";
import Whyus from "./Homepage/Whyus";


const Home = () =>{
    return(
        <>
            <title>Diamonds on Call Limited - Suppliers Centre</title>
            <Banner />
            <Whyus />
            <HowitworkSec />
            <Contact />
            <Footer />
        </>
    )
} 
export default Home;