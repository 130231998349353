import React from "react";

export const ManageAccountColumns = () => [
    {
        Header: "Account Name",
        accessor: "supplier_name",
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Type",
        accessor: "type",
        className: "text-center align-middle text-nowrap",
        Cell: (row) => {
            return <><span className={`${row.value === "Natural" ? "text-primary" : "text-success"}`}>{row.value}</span></>;
        },
    },
    {
        Header: "Email",
        accessor: "email",
        className: "text-center align-middle text-nowrap",
    },
    // {
    //     Header: "Connected Date",
    //     accessor: "date",
    //     className: "text-center align-middle text-nowrap",
    // },
];