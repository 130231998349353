import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid ,Button } from '@material-ui/core';
import {toAbsoluteUrl} from "../../../../_helpers";

export default function ProfileSuccess() {
  return (
    <React.Fragment>
        <Grid container justify='center' align="center">
          <Grid item xs={12} sm={7}>
            <Typography className="my-10" variant="h3" align="center">
                  Nice Work, Your Profile is Completed We'll Review your profile
              </Typography>
          </Grid>
          <Grid className="my-10" item xs={12} sm={7}>
              <img className="img-fluid" alt="profile complete" src={toAbsoluteUrl(`/media/General/profile_complete_icon.png`)}/>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography className="my-10 font-weight-bold" align="center">
                Thank you for completing your profile. Please wait for approval,
            </Typography>
            <Typography className="my-10 font-weight-bold" align="center">
                until then you can start uploading your stock. 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mb-10"
              component={Link} to="/upload-diamond"
            >
                Upload Diamonds
            </Button>
          </Grid>
        </Grid>
    </React.Fragment>
  );
}
