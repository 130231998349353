import React,{useState} from "react"
import LogoWhite from "./Images/white-logo.png"
import { NavLink } from "react-router-dom";
const Footer =()=>{
    const [isFooterCollapsed, setIsFooterCollapsed] = useState(true);
    const handleFooterCollapse = () => setIsFooterCollapsed(!isFooterCollapsed);
    return(
        <>
             {/* footer for large screen */}
            <div className="footer-large myFooter">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="text-left">
                            <a href="/">
                                <div className="footerlogo">
                                    <img src={LogoWhite} alt="diamondsoncall-supplier-centre-logo" title="Diamonds On Call - Supplier Centre" width="390px" />
                                </div>
                            </a>
                            <p className="mt-3 text-left">Sell your diamonds online with diamodnsoncall.com. <br />We offer an easy, fast & secure way to sell your<br /> diamonds to trusted buyers at the best possible prices.</p>
                        </div>
                        <div className="text-left">
                            <h5>COMPANY</h5>
                            <ul>
                                <li><a href="https://www.diamondsoncall.com/about">About Us</a></li>
                                <li><NavLink to="/login">Login</NavLink></li>
                                <li><NavLink to="/signup">Sign up</NavLink></li>
                            </ul>
                        </div>
                        <div className="text-left">
                            <h5>INFORMATION</h5>
                            <ul>
                                {/* <li><a href="javascript:void(0)" title="Blogs">Blogs</a></li>
                                <li><a href="javascript:void(0)" title="Faqs">Faqs</a></li>
                                <li><a href="javascript:void(0)" title="Sitemap">Sitemap</a></li> */}
                                 <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
                                <li><NavLink to="/terms">Terms &amp; Conditions</NavLink></li>
                            </ul>
                        </div>
                        <div className="text-left">
                            <h5>CONTACT US</h5>
                            <p className="m-0 pt-2 font-weight-bolder">DIAMONDS ON CALL LTD</p>
                            <p className="m-0 text-left">UNIT E-F, 8/F, 8 Hart Avenue,<br/>Tsim Sha Tsui,<br/>Kowloon, HONG KONG</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer for small screen  */}
            <div className="footer-small myFooter">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <a href="/">
                                <div className="footerlogo">
                                    <img src={LogoWhite} alt="diamondsoncall-supplier-centre-logo" title="Diamonds On Call - Supplier Centre" width="450px" />
                                </div>
                            </a>
                            <p className="mt-3 text-md-left text-center">Sell your diamonds online with diamodnsoncall.com<br />We offer an easy, fast & secure way to<br /> sell your diamonds to trusted buyers at <br />the best possible prices.</p>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <div id="accordion">
                                <div id="company-heading">
                                    <a className="collapsed footer-sm-head" role="button" data-toggle="collapse" onClick={handleFooterCollapse} href=" " aria-expanded={!isFooterCollapsed ? true : false} aria-controls="company">COMPANY</a>
                                </div>
                                <div id="company" className={`${isFooterCollapsed ? 'collapse' : ''} navbar-collapse`} data-parent="#accordion" aria-labelledby="company-heading">
                                    <ul>
                                        <li><a href="https://www.diamondsoncall.com/about">About Us</a></li>
                                        <li><NavLink to="/login">Login</NavLink></li>
                                        <li><NavLink to="/signup">Sign up</NavLink></li>
                                    </ul>
                                </div>
                                <div id="information-heading">
                                    <a className="collapsed footer-sm-head" role="button" data-toggle="collapse" onClick={handleFooterCollapse} href=" " aria-expanded={!isFooterCollapsed ? true : false} aria-controls="information">INFORMATION</a>
                                </div>
                                <div id="information" className={`${isFooterCollapsed ? 'collapse' : ''} navbar-collapse`} data-parent="#accordion" aria-labelledby="information-heading">
                                    <ul>
                                        {/* <li><a href="javascript:void(0)" title="Blogs">Blogs</a></li>
                                        <li><a href="javascript:void(0)" title="Faqs">Faqs</a></li>
                                        <li><a href="javascript:void(0)" title="Sitemap">Sitemap</a></li> */}
                                        <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
                                        <li><NavLink to="/terms">Terms &amp; Conditions</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mt-2">
                            <h5 className="mb-2">CONTACT US</h5>
                            <p className="m-0 font-weight-bold">DIAMONDS ON CALL LTD</p>
                            <p className="m-0 text-lg-left text-center">UNIT E-F, 8/F, 8 Hart Avenue,<br/>Tsim Sha Tsui,<br/>Kowloon, HONG KONG</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;