/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { NODE_API_URL } from "../../../../env_config";
import axios from "axios";
import {
    // FaTrashAlt,
    FaUserPlus
} from "react-icons/fa";
// import { SwalWarn } from "../../popup";
import { AddAccount } from "../../popup/AddAccount";
import { ManageAccountTable } from "./ManageAccount/ManageAccountTable";

export function ManageAccount({ userinfo,subId }) {
    const [accountData, setAccountData] = useState([]);
    const [editPerm, setEditPerm] = React.useState(0)
    // const [swalWarnOpen1,setSwalWarnOpen1] = useState(false)
    // const [alertMessage,setAlertMessage] = useState();
    // const [successMsg,setSuccessMsg]=useState('')
    // const [temp,setTemp]=useState(true)
    // const [valData ,setValData] = useState([])
      const [addAccountOpen,setAddAccountOpen] = useState(false)

    useEffect(() => {
        let isMounted = true;
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/fetchSwitchApp`,
            headers: { "Content-Type": "application/json" },
            data: { id: userinfo.id },
        })
            .then((res) => {
                // console.log(res);
                if (res && res.data) {
                    isMounted && setAccountData(res.data.data);
                }
            })
            .catch((res) => {
                // console.log(res.response, "ERROR");
            });

            if (subId){
                const permiFetchData = {
                  "sub_supp_id":subId,
                  "resource_id":14
                };
                axios({
                  method: "POST",
                  url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
                  headers: { "Content-Type": "application/json" },
                  data: permiFetchData,
                }).then((res) => {
                //   console.log(res.data.data[0])
                    if (res && res.data) {
                        isMounted && setEditPerm(res.data.data[0].edit_permission);
                    }
                }).catch(error => {
                    // console.log(error,"ERROR")
                });
              }
    }, [userinfo.id,subId]);
    const addAccount  = () => {
        setAddAccountOpen(true)
      }
    // const removeAccount = (val) =>{
    //     // console.log(val)
    //     setValData(val)
    //     setSwalWarnOpen1(true)
    //     setAlertMessage(`Are you sure wanted to remove ${val.supplier_name}(${val.type}) ?`)
    // }

    // const removeAccountfn = () =>{
    //     // console.log(valData)
    //     const finalData={
    //         "from_supp":userinfo.id ,
    //         "to_supp":valData.id
    //      }

    //     axios({
    //         method: "POST",
    //         url: `${NODE_API_URL}/SUPP/removeSwitchSupp`,
    //         headers: { "Content-Type": "application/json" },
    //         data: finalData,
    //     })
    //         .then((res) => {
    //             console.log(res);
    //             setTemp(!temp)
    //             setSwalWarnOpen1(false)
    //             // window.location.replace("/dashboard")
    //             if (res && res.data) {
    //                 setSuccessMsg(res.data.data.msg)
    //                 // isMounted && setAccountData(res.data.data);
    //             }
    //         })
    //         .catch((res) => {
    //             console.log(res.response, "ERROR");
    //         });
    // }
    // console.log(accountData)
    return (
       <>
            <div className="card card-custom">
                {/* begin::Header */}
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            Manage Switch Accounts Setting
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            Manage your Switch accounts
                        </span>
                    </div>
                    {!subId || editPerm?
                    <div className="card-toolbar">
                        {accountData.length < 3 ? 
                            <button className="btn btn-light-primary" onClick={()=> addAccount(userinfo)}><FaUserPlus className="mr-2 font-size-h2" />Connect Supplier Account</button>
                        :""}
                    </div>
                    :""}
                </div>
                {/* end::Header */}
                {/* begin::Form */}
                <ManageAccountTable accountData={accountData} userinfo={userinfo} subId={subId}/>
                {/* <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            {successMsg ? 
                                <div className="mb-5 alert alert-custom alert-light-success py-3 alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                        {successMsg}
                                    </div>
                                </div> 
                            : ""}
                        </div>
                        <div className="col-12">
                            {accountData.length !== 0
                                ? <ul>
                                        {accountData.map((val) => {
                                            return (
                                                <li className="font-weight-bold font-size-h5 mb-4" kry={val.id}>
                                                    {val.supplier_name}
                                                    <span
                                                        className={`${
                                                            val.type === "Natural"
                                                                ? "text-primary"
                                                                : "text-success"
                                                        } pl-1`}
                                                    >
                                                        ({val.type})
                                                    </span>
                                                    <button className="btn btn-icon btn-light-danger btn-sm ml-5">
                                                        <FaTrashAlt onClick={()=> removeAccount(val)} />
                                                    </button>
                                                </li>
                                            );
                                        })
                                    }
                                    </ul>
                                : 
                                    <div className="font-size-h6 font-weight-bold"> No account is connected</div>
                                }
                        </div>
                    </div>
                </div> */}
                {/* end::Form */}
            </div>
            <AddAccount show={addAccountOpen} setAddAccountClose={() => setAddAccountOpen(false)}  userinfo={userinfo} addClose={() => setAddAccountOpen(false)} /> 
            {/* <SwalWarn show={swalWarnOpen1} message={alertMessage} onHide={() => setSwalWarnOpen1(false)}  onClose={() => setSwalWarnOpen1(false)} responseOnOk={()=>removeAccountfn()} />  */}
       </>
    );
}
