/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Link } from "react-router-dom";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// import { ContentRoute } from "../../../../_metronic/layout";
import Regform from "./Regform";
// import Registration from "./Registration";
// import ForgotPassword from "./ForgotPassword";
import list from "./Images/list.svg";
import pay from "./Images/pay.svg";
import certification from "./Images/certification.svg";
import noreturn from "./Images/noreturn.svg";

import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function Registration() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside signup-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{ backgroundColor: "#1A3781" }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-center">
              {/* start:: Aside content */}
              <div className="row justify-content-center my-3 my-md-0 m-0 align-content-center">
                <div className="col-6 col-lg-10 mx-lg-2 box-aside d-md-flex align-items-center text-center text-lg-left">
                  <div className="box mx-auto mx-md-0">
                    <img src={list} alt="free-listing" title="Free Listing" />
                  </div>
                  <p>Free Listing</p>
                </div>
                <div className="col-6 col-lg-10 mx-lg-2 box-aside d-md-flex align-items-center text-center text-lg-left">
                  <div className="box mx-auto mx-md-0">
                    <img
                      src={pay}
                      alt="payment within 14 days"
                      title="14 Days Payment"
                    />
                  </div>
                  <p>Payments 14 Days</p>
                </div>
                <div className="col-6 col-lg-10 mx-lg-2 box-aside d-md-flex align-items-center text-center text-lg-left">
                  <div className="box mx-auto mx-md-0">
                    <img
                      src={certification}
                      alt="quality-check-process"
                      title="Quality Check"
                    />
                  </div>
                  <p>Quality Check</p>
                </div>
                <div className=" col-6 col-lg-10 mx-lg-2 box-aside d-md-flex align-items-center text-center text-lg-left">
                  <div className="box mx-auto mx-md-0">
                    <img src={noreturn} alt="noreturn" title="No Returns" />
                  </div>
                  <p>No Returns</p>
                </div>
              </div>
              {/* end:: Aside content */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-lg-0">
              <Regform />
              {/* <ContentRoute path="/auth/login" component={Login} /> */}
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

export default Registration;
