import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import moment from "moment-timezone";
import { FcShipped } from "react-icons/fc";

// function jugaadDate(x) {
//     // const tempDateNew = moment.tz(x, "America/St_Johns").format("YYYY-MM-DD HH:mm:ss");
//     // const dateNew = moment.tz(tempDateNew, "America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss");
//     // return dateNew

//     let storedDate = new Date(x);
//     storedDate = new Date(storedDate.getTime() - (330 * 60 * 1000));
//     return storedDate
// }
export const HoldListColumn = (userinfo) => [
    {
        Header: "Date & Time",
        // accessor: "cd",
        accessor: (d) => {
            return (
                <>
                    {  d.cd ? moment.tz(d.cd, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Order ID",
        accessor: "confirm_goods_id",
        className: "text-center align-middle",
    },
    {
        Header: "Diamond Detail",
        accessor: (d) => (
            <>
            {d.diamond_type === "PN" || d.diamond_type === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.C_Shape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.C_Shape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamond_type}_diamond mr-2 small`}>
                                {d.diamond_type}
                            </span>
                            <span className={`text-primary small ml-3`}>
                                {d.diamond_type}-{d.parcel_id}-{d.split_id}
                            </span>
                            <span className={`text-primary text-capitalize small ml-3`}>
                                {d.length_mm} - {d.width_mm} MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {d.C_Weight}Ct.{" "}
                            {d.C_Color}{" "}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.aws_image &&  d.aws_image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={d.aws_image}
                                width="50px"
                                onError={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                )}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "W" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamond_type === "W" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                {d.diamond_type === "W" ? d.C_Color === "fancy"  ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <span  className={`text-primary small ml-3`}>
                                {d.v_customer === "12" 
                                    ?  <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
                                        >
                                            <span className="text-primary font-weight-bold">{d.Loat_NO}<FcShipped className="ml-2 font-size-h4"/></span>
                                        </OverlayTrigger>
                                    : d.Loat_NO
                                }
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.Lab === "IGI"
                                        ? `https://www.igi.org/viewpdf.php?r=${d.Certi_NO}`
                                        : d.Lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${d.Certi_NO}`
                                        : d.Lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.Certi_NO}`
                                        : d.Lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.Certi_NO}`
                                        : d.Certi_link
                                }
                            >
                                {d.Lab}-{d.Certi_NO}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {d.C_Weight}{" "}
                            {d.C_Color === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.C_Color}{" "}</>
                            )}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    },    
    // {
    //     Header: "Stone ID" ,
    //     accessor: "Loat_NO",
    //     className: "text-center align-middle",
    //     Cell: (row) => (
    //         <>
    //             {row.row.original.v_customer === "12" 
    //             ?  <OverlayTrigger
    //                     placement="top"
    //                     overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
    //                 >
    //                    <span className="text-primary font-weight-bold"><FcShipped className="mr-2 font-size-h4"/>{row.value}</span>
    //                 </OverlayTrigger>
    //             : row.value
    //             }
                
    //         </>
    //     ),
    // },
    // {
    //     Header: "Carat",
    //     accessor: "C_Weight",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{parseFloat(row.value).toFixed(2)}</>;
    //     },
    // },
    // {
    //     Header: "Shape",
    //     accessor: "C_Shape",
    //     className: "text-center align-middle p-2",
    //     Cell: (row) => (
    //         <>
    //             <SVG
    //                 width="22px"
    //                 height="22px"
    //                 src={toAbsoluteUrl(
    //                     `/media/shape/svg_shape/${row.row.original.C_Shape.toLowerCase()}.svg`
    //                 )}
    //             />
    //             <div className="small">{row.row.original.C_Shape}</div>
    //         </>
    //     ),
    // },
    // {
    //     Header: "Color",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                 {d.C_Color === "fancy"?
    //                 <>
    //                     <OverlayTrigger placement="bottom" overlay={<Tooltip >{d.f_intensity} {d.f_overtone} {d.f_color}</Tooltip>} >
    //                         <span className="text-truncate d-inline-block" style={{maxWidth:"80px"}}>{d.f_intensity} {d.f_overtone} {d.f_color}</span>
    //                     </OverlayTrigger>
    //                 </>: d.C_Color}
    //             </>
    //         );
    //     },
    //     minwidth: 50,
    //     className: "text-center align-middle",
    // },
    // {
    //     Header: "Clarity",
    //     accessor: "C_Clarity",
    //     className: "text-center align-middle",
    //      Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Cut",
    //     accessor: "C_Cut",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Polish",
    //     accessor: "C_Polish",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Symmetry",
    //     accessor: "C_Symmetry",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Fluo.",
    //     accessor: "C_Fluorescence",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Lab",
    //     accessor: "Lab",
    //     className: "text-center align-middle",
    // },
    // {
    //     Header: "Certificate",
    //     accessor: "Certi_NO",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return (
    //             <>
    //                 <a
    //                     target="_blank"
    //                     rel="noreferrer"
    //                     className="text-primary mx-2 text-left"
    //                     href={
    //                         row.row.original.Lab === "IGI"
    //                             ? `https://www.igi.org/viewpdf.php?r=${row.row.original.Certi_NO}`
    //                             : row.row.original.Lab === "GIA"
    //                             ? `https://www.gia.edu/report-check?reportno=${row.row.original.Certi_NO}`
    //                             : row.row.original.Lab === "HRD"
    //                             ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.row.original.Certi_NO}`
    //                             : row.row.original.Lab === "GCAL"
    //                             ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.row.original.Certi_NO}`
    //                             : row.row.original.Certi_link
    //                     }
    //                 >
    //                     {row.row.original.Certi_NO}
    //                 </a>
    //             </>
    //         )
    //     },
        
    // },
    {
        Header: "Price",
        className: "text-right  text-nowrap align-middle pr-5",
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    ${parseFloat(d.O_Rate * d.C_Weight).toFixed(2)}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT ${parseFloat(d.O_Rate).toFixed(2)}
                </span>
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
        // Cell: (row) => {
        //     return <>${parseFloat(row.value).toFixed(2)}</>;
        // },
    },
    // {
    //     Header: "$/CT",
    //     className: "text-right align-middle pr-5",
    //     accessor:"O_Rate",
    //     sortMethod: (a, b) => Number(a) - Number(b),
    //     Cell: (row) => {
    //         return <>${parseFloat(row.value).toFixed(2)}</>;
    //     },
    // },
    // {
    //     Header: "Total",
    //     className: "text-right align-middle pr-5",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                ${parseFloat(d.O_Rate * d.C_Weight).toFixed(2)}
    //             </>
    //         );
    //     },
    //     sortMethod: (a, b) => Number(a) - Number(b),
    // }
];
