/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../controls";
import { NODE_API_URL } from "../../../../env_config";
import axios from "axios"
import {Alert} from "react-bootstrap";

export function ChangePassword({userinfo}) {
    // Fields
    const [loading, setloading] = useState(false);
    const [show, setShow] = useState(true);
    


    // UI Helpers
    const initialValues = {
        password: "",
        newpassword: "",
        confirmpassword: "",
    };
    const Schema = Yup.object().shape({
        password: Yup.string("Enter your current password").required(
            "Password is required"
        ),
        newpassword: Yup.string("Enter your new password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
        confirmpassword: Yup.string("retype your new password")
            .oneOf([Yup.ref("newpassword"), null], "Password must match")
            .min(8, "Password should be of minimum 8 characters length")
            .required("retype Password is Required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            setloading(true)
            const finalData = {
                "id":userinfo.id,
                oldPassword:values.password,
                newPassword:values.newpassword
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/updatePassword`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then((res) => {
                setloading(false)
                if (res && res.data) {
                    // console.log("data", res);
                    setSubmitting(false)
                    // setStatus(res.data.data)
                    setSuccessMessage(res.data)
                    setErrorMessage()
                    // console.log("successmsg",successmsg)
                }
            }).catch(res => {
                setloading(false)
                setSubmitting(false)
                // console.error("error", res.response);
                // setStatus(res.data.data)
                setErrorMessage(res.response.data)
                // console.log("errmessage",errmessage)
                setSuccessMessage()
            })
            // console.log(values);
        }
    });
    const [errmessage,setErrorMessage] = useState()
    const [successmsg,setSuccessMessage] = useState()
  
    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit}>
            {loading && <ModalProgressBar />}
            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Security</h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">Update your account password</span>
                </div>
                <div className="card-toolbar">
                    <button
                        type="submit"
                        className="btn btn-success mr-2"
                        disabled={
                            formik.isSubmitting ||
                            (formik.touched && !formik.isValid)
                        }
                    >
                        Save Changes
                        {formik.isSubmitting}
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {errmessage ? <Alert variant="danger" onClose={() => setShow(false)}  dismissible> {errmessage} </Alert>:""}
                    {successmsg ? <Alert variant="success" onClose={() => setShow(false)}  dismissible> {successmsg} </Alert>:""}
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            Current Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder="New Password"
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "password"
                                )}`}
                                name="password"
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                                <div className="invalid-feedback">
                                    {formik.errors.password}
                                </div>
                            ) : null}
                        </div>
                        
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            New Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder="Current Password"
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "newpassword"
                                )}`}
                                name="newpassword"
                                {...formik.getFieldProps("newpassword")}
                            />
                            {formik.touched.newpassword &&
                            formik.errors.newpassword ? (
                                <div className="invalid-feedback">
                                    {formik.errors.newpassword}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            Verify Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder="Verify Password"
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "confirmpassword"
                                )}`}
                                name="confirmpassword"
                                {...formik.getFieldProps("confirmpassword")}
                            />
                            {formik.touched.confirmpassword &&
                            formik.errors.confirmpassword ? (
                                <div className="invalid-feedback">
                                    {formik.errors.confirmpassword}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Form */}
        </form>
    );
}
