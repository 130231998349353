import React from "react";
import { Link } from "react-router-dom";
import {BASE_URL_OLD} from "../../../../env_config"
import { toAbsoluteUrl } from "../../../_helpers";

export const FileModeHelp = ({userinfo}) => {


    return (
        <>
           <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">Uploading the File (in manual mode)</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span> Manual Mode</span>
                    </div>
                </div>
                <div className="mt-4 col-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-body">
                            <h4 id="uploading-File-Manual"><b>Uploading the File (in manual mode)</b> </h4>
                            <p className="mt-5" >Do the steps provided below to upload files in manual mode;</p>
                            <p className="mb-2">Step 1: Repeat step 1 and step 2, see <Link to={`${BASE_URL_OLD}/diamondupload#adding-listing`}>Adding Listing</Link></p>
                            <p className="mb-2"> Step 2: Click on <strong><a href={`"${BASE_URL_OLD}/assets/${userinfo.type === "Natural" ? "doc_file.csv" : "doc_lab_grown.csv" }"`} download="doc_upload_file.csv">Download Sample File from here</a></strong> to download the .csv sample file You can save it as .csv or .xls file</p>
                            <p >Step 3: Enter the details of the required diamond parameters in the downloaded sample file and upload it </p>
                            <p >For more details on parameters go to HELP&#62;Parameter</p>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/16_1.jpg`)} alt="15" border="0" />
                                </figure>
                            </div>
                            <p > Step 4: Click Drag and drop the updated <strong>stock.csv</strong> file</p>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/17.jpg`)} alt="17" border="0" />
                                </figure>
                            </div>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/40.jpg`)} alt="40" border="0" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
