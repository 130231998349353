import React, { useEffect } from "react";

import { useSubheader } from "../../layout";
import { ProfilePage } from "./ProfilePage";
// import { ProfileEdit } from "./ProfileEdit";
import KycUpdate from "./KycUpdate/KycUpdate";

import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';

export function UserProfilePage({ userinfo, companyRef,subId }) {

    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)

    const [loader,setLoader] = React.useState(true)

    const suhbeader = useSubheader();
    suhbeader.setTitle("User profile");

    useEffect(() => {
        document.title = "User Profile | Diamonds on Call - Suppliers Center";
        let isMounted = true;
        setLoader(true)
        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":12
            };
            axios({
              method: "POST",
              url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
              headers: { "Content-Type": "application/json" },
              data: permiFetchData,
            }).then((res) => {
                setLoader(false)
            //   console.log(res.data.data[0])
                if (res && res.data) {
                    isMounted && setEditPerm(res.data.data[0].edit_permission);
                    isMounted && setReadPerm(res.data.data[0].read_permission);
                }
            }).catch(error => {
                setLoader(false)
                // console.log(error,"ERROR")
            });
          }else{
              setLoader(false)
          }

    }, [subId]);

    return (
        <>
        {!loader ?
            <>
                {readPerm || !subId ? 
                    <>
                        {/* <ProfileEdit /> */}
                        {userinfo.is_pending === 1 ? (
                            <KycUpdate userinfo={userinfo} companyRef={companyRef} />
                        ) : (
                            <ProfilePage userinfo={userinfo} companyRef={companyRef} />
                        )}
                    </>
                :
                    <div className="card card-custom gutter-b">
                        <div className="card-header py-3 mb-3">
                            <div className="card-title">
                                <span className="card-label font-weight-bolder text-dark">
                                    Profile
                                </span>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            You do not have permission to access, Please contact your administrator for help
                        </div>
                    </div>
                }
            </>
        :
            <div className="card card-custom gutter-b">
                <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
                    Loading...<div className="spinner spinner-primary ml-5"></div>
                </div>
            </div>
        }
        </>
    );
}
