import React from "react";
import notFound from "./Images/not-found.png"
import Footer from "./Footer";
import { NavLink } from "react-router-dom";


const Notfound = () =>{
    return(
        <>
           <div className="container">
                <div className="row notfound-page align-items-center justify-content-center py-4">
                    <div className="col-12 text-center">
                        <img className="text-center " src={notFound} alt="Not Found"/>
                        <h1 className="mt-3">Page not found</h1>
                        <p className="m-0">We're sorry, the page you requested could not be found.</p>
                        <p className="m-0 mb-3">Please go back to home-page or contact us at <a href="mailto:supplier@diamondsoncall.com">supplier@diamondsoncall.com</a></p>
                        <NavLink className="btn btn-light action-button px-3" to="/" >GO HOME </NavLink>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
} 
export default Notfound;