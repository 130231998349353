/* eslint-disable */
import * as Yup from 'yup';
// import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    email,
    firstname,
    lastname,
    designation,
    moblie_no,
    supplier_name_o,
    supplier_logo,
    address,
    pincode,
    city,
    state,
    country,
    website,
    cc_email,
    telphone,
    c_register_no,
    c_register_upload,
    c_partnet_name,
    c_document_type,
    c_partnet_doc,
    comp_name_1,
    person_name_1,
    designation_1,
    email_id_1,
    contact_no_1,
    comp_name_2,
    person_name_2,
    designation_2,
    email_id_2,
    contact_no_2,
  }
} = kycFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const phoneRegExp = /^[\+\d]?(?:[\d-.\s()]*)$/
const alphabatic = /^[aA-zZ\s]+$/
const pandcard =/^[A-Z0-9]*$/

export default [
  Yup.object().shape({
    [email.name]: Yup.string().email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${email.requiredErrorMsg}`),
    [firstname.name]: Yup.string().required(`${firstname.requiredErrorMsg}`).matches(alphabatic, 'Only Alphabets should allow'),
    [lastname.name]: Yup.string().required(`${lastname.requiredErrorMsg}`).matches(alphabatic, 'Only Alphabets should allow'),
    [designation.name]: Yup.string().required(`${designation.requiredErrorMsg}`).nullable(),
    [moblie_no.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').min(10, "Please enter at least 10 characters").required(`${moblie_no.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    // [supplier_logo.name]: Yup.string().required(`${supplier_logo.requiredErrorMsg}`),
    [supplier_name_o.name]: Yup.string().required(`${supplier_name_o.requiredErrorMsg}`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    // // [address2.name]: Yup.string().required(`${address2.requiredErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`).nullable(),
    [pincode.name]: Yup.string().required(`${pincode.requiredErrorMsg}`),
    [country.name]: Yup.string().required(`${country.requiredErrorMsg}`),
    [telphone.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').min(10, "Please enter at least 10 characters").required(`${telphone.requiredErrorMsg}`),
    // // [fax.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').required(`${fax.requiredErrorMsg}`),
    [cc_email.name]: Yup.string() .email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${cc_email.requiredErrorMsg}`),
    // // [website.name]: Yup.string() .matches( /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url!' ) .required(`${website.requiredErrorMsg}`),
    [c_register_no.name]: Yup.string().required(`${c_register_no.requiredErrorMsg}`),
    // // [c_register_upload.name]: Yup.string().required(`${c_register_upload.requiredErrorMsg}`),
    [c_partnet_name.name]: Yup.string().required(`${c_partnet_name.requiredErrorMsg}`),
    [c_document_type.name]: Yup.string().required(`${c_document_type.requiredErrorMsg}`),
    [c_register_upload.name]: Yup.string().required(`${c_register_upload.requiredErrorMsg}`),
    [c_partnet_doc.name]: Yup.string().required(`${c_partnet_doc.requiredErrorMsg}`),
   
  }),
  Yup.object().shape({
    [email_id_1.name]: Yup.string() .email("Wrong email format"),
    [email_id_2.name]: Yup.string() .email("Wrong email format") 
  }),
];
