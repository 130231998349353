import React from "react";
import { NavLink } from "react-router-dom";
import notFound from "../Auth/pages/Images/not-found.png"
// import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export function ErrorPage() {
  return (
    <div className="container h-100">
        <div className="row notfound-page align-items-center justify-content-center py-4 h-100">
            <div className="col-12 text-center">
                <img className="text-center mb-5" src={notFound} alt="Not Found"/>
                <h1 className="mt-3 mb-5 display-4">Page not found</h1>
                <p className="m-0 text-muted font-size-h6">We're sorry, the page you requested could not be found.</p>
                <p className="mb-5 text-muted font-size-h6">Please go back to home-page or contact us at <a href="mailto:supplier@diamondsoncall.com">supplier@diamondsoncall.com</a></p>
                <NavLink className="btn btn-primary " to="/" >GO TO DASHBOARD</NavLink>
            </div>
        </div>
    </div>
  );
}
