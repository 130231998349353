
    if(process.env.REACT_APP_ENV === 'qc'){
        // console.log(process.env,"process.env")
        var apilink = {
            NODE_API_URL:process.env.REACT_APP_NODE_API_URL,
            BASE_URL:process.env.REACT_APP_BASE_URL,
            BASE_URL_OLD:process.env.REACT_APP_BASE_URL_OLD,
            BASE_URL_FILE:process.env.REACT_APP_BASE_URL_FILE,
            NEW_BASE_URL_FILE:process.env.REACT_APP_BASE_URL_FILE_NEW
        };

    }else if (process.env.REACT_APP_ENV === 'local'){

        var apilink = {
            BASE_URL:process.env.REACT_APP_BASE_URL,  //beta link
            NODE_API_URL:process.env.REACT_APP_NODE_API_URL,
            BASE_URL_OLD:process.env.REACT_APP_BASE_URL_OLD,
            BASE_URL_FILE:process.env.REACT_APP_BASE_URL_FILE,
            NEW_BASE_URL_FILE:process.env.REACT_APP_BASE_URL_FILE_NEW
        };

    } else {
        
        var apilink = {
            NODE_API_URL:process.env.REACT_APP_NODE_API_URL_PROD,
            BASE_URL:process.env.REACT_APP_BASE_URL_PROD,
            BASE_URL_OLD:process.env.REACT_APP_BASE_URL_OLD_PROD,
            BASE_URL_FILE:process.env.REACT_APP_BASE_URL_FILE_PROD,
            NEW_BASE_URL_FILE:process.env.REACT_APP_BASE_URL_FILE_NEW_PROD
        };
    };

//exporting apilink

module.exports = apilink;