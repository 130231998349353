import React, { useMemo, useState,useEffect } from "react";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
import { ManageAccountColumns } from "./ManageAccountColumns";

import { 
    FaSortDown,
    FaSortUp,
    FaTrashAlt,
    // FaEdit,
    // FaKey
} from "react-icons/fa";

import axios from 'axios';
import { NODE_API_URL } from '../../../../../env_config';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SwalWarn } from "../../../popup"; 

export const ManageAccountTable = ({userinfo,subId}) => {

    const [swalWarnOpen1,setSwalWarnOpen1] = useState(false)
    const [valData ,setValData] = useState([])
    const [editOpen,setEditOpen] = useState(false)
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)
    const [loader,setLoader] = useState(true)
    // const [showUserData,setShowUserData] = useState()
    // const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    // const [loader,setLoader] = useState(true)
    const [data,setData] = useState([])
    const [successMsg ,setSuccessMsg] =useState()
    // const [errorMsg ,setErrorMsg] =useState()

    // console.log(data)
    useEffect(() => {
        let isMounted = true;
          
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/fetchSwitchApp`,
            headers: { "Content-Type": "application/json" },
            data: { id: userinfo.id },
        })
            .then((res) => {
                // console.log(res);
                if (res && res.data) {
                    isMounted && setData(res.data.data);
                }
            })
            .catch((res) => {
                // console.log(res.response, "ERROR");
            });

            if (subId){
                setLoader(true)
                const permiFetchData = {
                  "sub_supp_id":subId,
                  "resource_id":14
                };
                axios({
                  method: "POST",
                  url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
                  headers: { "Content-Type": "application/json" },
                  data: permiFetchData,
                }).then((res) => {
                    setLoader(false)
                //   console.log(res.data.data[0])
                    if (res && res.data) {
                        isMounted && setEditPerm(res.data.data[0].edit_permission);
                        isMounted && setReadPerm(res.data.data[0].read_permission);
                    }
                }).catch(error => {
                    setLoader(false)
                    // console.log(error,"ERROR")
                });
              }else{
                  setLoader(false)
              }
          
    }, [userinfo.id,subId]);

    const removeAccount = (row) =>{
        // console.log(row.original)
        setValData(row.original)
        setSwalWarnOpen1(true)
        setAlertMessage(`Are you sure wanted to remove ${row.original.supplier_name}(${row.original.type}) ?`)
    }

    const removeAccountfn = () =>{
        // console.log(valData)
        const finalData={
            "from_supp":userinfo.id ,
            "to_supp":valData.id,
            "requester":userinfo.supplier_name,
         }

        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/removeSwitchSupp`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                // console.log(res);
                setSwalWarnOpen1(false)
                if (res && res.data) {
                    setSuccessMsg(res.data.data)
                    // console.log(res.data.data);
                    window.location.replace("/dashboard")
                    // isMounted && setAccountData(res.data.data);
                }
            })
            .catch((res) => {
                // console.log(res.response, "ERROR");
            });
    }

    const columns = useMemo(() => ManageAccountColumns(userinfo), [userinfo]);

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        // nextPage,
        // previousPage,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // gotoPage,
        // pageCount,
        // setPageSize,
        // setGlobalFilter,
        // allColumns,
        // getToggleHideAllColumnsProps,
        // state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    ...columns,
                    
                    {
                        Header: () => "Action",
                        id: "delete",
                        Cell: ({ row }) => (
                            <>
                            {editPerm || !subId ?
                                    <span>
                                        <OverlayTrigger placement="bottom" overlay={<Tooltip >Click to DeLink Account</Tooltip>} >
                                            <button className="btn btn-sm btn-light-danger btn-icon ml-3" onClick={()=> removeAccount(row)}><FaTrashAlt /></button>
                                        </OverlayTrigger>
                                    </span>
                                : "-"}
                            </>
                        ),
                        className:"text-center align-middle",
                       
                    },
                ];
            });
        }
    );
    return (
        <>
        {/* {!loader ?  */}
            <div className="card-body">
                {successMsg && 
                    <div className="mb-5 alert alert-custom alert-light-success mb-4 py-4 alert-dismissible">
                        <div className="alert-text font-weight-bold">
                        {successMsg}
                        </div>
                    </div>
                }
                {/* {errorMsg && 
                    <div className="mb-5 alert alert-custom alert-light-danger mb-4 py-4 alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {errorMsg}
                        </div>
                    </div>
                } */}
                {readPerm || !subId ?
                    <div className="table-responsive custom-scrollbar w-100 mt-4">
                        <table className="table table-striped table-hover custom_datatable api_datatable border-bottom" {...getTableProps()} >
                            <thead className="custom_datatable_head">
                                {headerGroups.map((headerGroup, i) => (
                                    <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(
                                            (column, i) => (
                                                <th key={i} className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                    </span>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {data.length !== 0 ? page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} key={row.id}>
                                            {row.cells.map((cell, i) => {
                                                return (
                                                    <td className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className,})}key={i}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                }) :
                                <tr>
                                    <td className="text-center align-middle"colSpan="100%">
                                        No Accounts available for manage
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                :
                    <div className="row">
                        <div className="col-12 text-center">
                            You do not have permission to access, Please contact your administrator for help
                        </div>
                    </div>
                }
                <SwalWarn show={swalWarnOpen1} message={alertMessage} onHide={() => setSwalWarnOpen1(false)}  onClose={() => setSwalWarnOpen1(false)} responseOnOk={()=>removeAccountfn()} /> 
                {/* <PendingApiApprove show={approveApiOpen} onHide={() => setApproveApiOpen(false)} data={showDiamData} userinfo={userinfo} /> */}
            </div>
            {/* : <div className="card-body p-0 d-flex justify-content-center font-weight-bold font-size-lg">Loading...<div className="spinner spinner-primary ml-5"></div></div>} */}
        </>
    );
};
