import React from "react";
import { Link } from "react-router-dom";
import Scrollspy from 'react-scrollspy'
import {BASE_URL_OLD} from "../../../../env_config"
import { HashLink } from 'react-router-hash-link';
import { toAbsoluteUrl } from "../../../_helpers";

export const OrderHelp = () => {


    return (
        <>
           <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">Confirmed Order Help</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span>Confirmed Order</span>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <div className="card card-custom card-stretch">
                                <div className="card-body px-1">
                                    <div className="sticky-top" style={{top:'100px'}}>
                                        <Scrollspy className="help-scrollspy" items={ ['hold-request','Approving-request','CancellingRequest','confirm-order','sale-history','delivery-payments'] } currentClassName="is-current">
                                            <li><a href="#hold-request">Hold Request</a></li>
                                                <li className="ml-3"><a href="#Approving-request">Approving Request</a></li>
                                                <li className="ml-3"><a href="#CancellingRequest">Cancelling Request</a></li>
                                            <li><a href="#confirm-order">Confirmed Order</a></li>
                                            <li><a href="#sale-history">Sale History</a></li>
                                            <li><a href="#delivery-payments ">Delivery & Payment</a></li>
                                        </Scrollspy>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10">
                            <div className="card card-custom card-stretch">
                                <div className="card-body">
                                    <div className="row">
                                       <div className="col-12 border-bottom mb-5" id="hold-request">
                                            <h3 ><strong>Hold Request</strong></h3>
                                            <p>Once you receive an order from a customer, it comes to this on hold request page and a mail confirmation is sent to you. </p>
                                            <p>Generally, hold request process is done to verify and check the diamond quality, awaiting on payment availability, etc. You can approve or cancel a request in the<strong> Hold Request</strong> page. </p>
                                            <p>In the Hold Request page, there are two tabs;</p>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <strong>Hold List:</strong> In the Hold List tab, you can see the list of all the product request orders which are on hold. The <strong>Hold List</strong> tab has a table, which displays the information about each request. It has the <strong>Action</strong> column where you can choose to approve or cancel a particular request. It has other columns like <strong>Date &amp; Time, Order ID, Stone ID</strong> etc. When you click on the icon it displays the detailed information about the diamond.
                                                    </p>
                                                    <div className="text-center">
                                                        <figure>
                                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/21.jpg`)} alt="21" border="0" />
                                                        </figure>
                                                    </div>
                                                    <p>This page allows you to do the following actions;</p>
                                                </li>
                                                <li style={{listStyleType:"none"}}>
                                                    <ol type="a">
                                                        <li>
                                                            <strong>Approve:</strong> It allows you hold request of a diamond which is ordered.</li>
                                                        <li>
                                                            <strong>Cancel:</strong> It allows you to cancel the hold request of a diamond which is ordered. Once the product/ stock is cancelled it moves to the <strong>Hold Cancelled List</strong> tab.
                                                            <p className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20 mt-3" style={{borderLeft:"5px solid"}}><em><strong>Note:</strong>  If you have clicked the <strong>Cancel</strong> button by mistake, you can contact your Account Representative for further assistance.</em></p>
                                                        </li>
                                                        <li>
                                                            <strong>Release:</strong> A product/stock is generally moved to this state by purchase executive when there are some quality concerns or issues. Once a product is set to Release state, it will automatically be moved to <strong>Hold Cancelled List</strong> tab.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li><strong>Hold Cancelled List:</strong> In the Hold Cancelled List tab, you can see the list for all the cancelled or released diamond request.</li>
                                            </ul>
                                       </div>
                                       <div className="col-12 boreder-bottom mb-5">
                                            <h3 id="Approving-request"><strong>Approving a Request</strong></h3>
                                            <h5><em>To Approve Request</em></h5>
                                            <p>To approve a request order, follow the steps provided below;</p>
                                            <p>Step 1: Go to <strong>Hold Request</strong> in the left navigation panel of Diamonds On Call Supplier Centre</p>
                                            <p>The Hold Request page is displayed with the list of requests to be approved or cancelled</p>
                                            <p>Step 2: Click  <strong>Approve</strong> button to approve a request.</p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/22.jpg`)} alt="22" border="0" />
                                                </figure>
                                            </div>
                                            <p > Step 3: Select the required conditions and Click <strong>Add to Hold Approve List</strong>.</p>
                                            <p className="border-danger font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-danger-o-20 mt-3" style={{borderLeft:"5px solid"}}><em><strong>Note:</strong>  It is mandatory to select Terms and Condition check box to approve a request.</em></p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/23.jpg`)} alt="23" border="0" />
                                                </figure>
                                            </div>
                                            <p >A successful message <em>“Hold Approved Successfully”</em> will be displayed and the<strong> Approve</strong> button will displayed in Green Colour.</p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/24.jpg`)} alt="24" border="0" />
                                                </figure>
                                            </div>
                                            <p>The approved order will automatically move to Confirm List tab in the <strong>Confirm History</strong> page.  For more details see <HashLink to="#confirm-order">Confirmed Order</HashLink>.</p>
                                       </div>
                                       <div className="col-12 border-bottom mb-5">
                                            <h3 id="CancellingRequest"><strong>Cancelling a Request</strong></h3>
                                            <h5><em>To Cancel a request </em></h5>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/25.jpg`)} alt="25" border="0" />
                                                </figure>
                                            </div>
                                            <p>A confirmation dialog box is for confirming the cancellation request is displayed. </p>
                                            <p>Step 3: Add your comments for cancelling the request and click <strong>Yes</strong></p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/26.jpg`)} alt="26" border="0" />
                                                </figure>
                                            </div>
                                            <p>A successful message <em>“Cancel the hold successfully”</em> will be displayed and the Cancel button will display in Red Colour.</p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/27.jpg`)} alt="27" border="0" />
                                                </figure>
                                            </div>
                                            <p>The cancelled order will automatically move to <strong>Hold Cancelled List</strong> tab. </p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/28.jpg`)} alt="28" border="0" />
                                                </figure>
                                            </div>
                                       </div>
                                       <div className="col-12 border-bottom mb-5" id="confirm-order">
                                            <h3 id="delivery"><strong>Confirmed Order</strong></h3>
                                            <p>The <strong>Confirm Order</strong> list page displays the list of all the product/stock which are confirmed, and which are to be returned.</p>
                                            <p>In the Confirm History page, there are two tabs;</p>
                                            <ul>
                                                <li>
                                                    <p><b>Confirm List:</b> Once the diamond order is confirmed, it will be displayed under the <strong>Confirm List</strong> tab. After the confirmation. It goes through a quality assurance verification and inspection. Once the product has passed the quality assurance compliance it is ready for sale. An email confirmation for the confirmed request order will be sent. </p>
                                                    <div className="text-center">
                                                        <figure>
                                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/29.jpg`)} alt="29" border="0" />
                                                        </figure>
                                                    </div>
                                                </li>
                                                <li>
                                                    <p><b>Return List:</b> The product or stock order which does not pass the quality assurance compliance test will be moved to the return list for returning.</p>
                                                    <div className="text-center">
                                                        <figure>
                                                            <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/30.jpg`)} alt="30" border="0" />
                                                        </figure>
                                                    </div>
                                                </li>
                                            </ul>
                                       </div>
                                       <div className="col-12 border-bottom mb-5">
                                            <h3 id="sale-history"><strong>Sale History</strong></h3>
                                            <p>The Sale History page displays the list of orders which are already delivered to Diamond On Call Supplier Centre. It provides are summarised data of a Diamond On Call revenue from the sales of a diamond for a given time period. The Sale History table also provides detailed information like the <strong><em>Status, Pickup Date & Time,</em></strong> etc of product. Sales History is generally used for sales forecasting, i.e. predicting future sales of a product. </p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/31.jpg`)} alt="31" border="0" />
                                                </figure>
                                            </div>
                                       </div>
                                       <div className="col-12 border-bottom mb-5">
                                            <h3 id="delivery-payments"><strong>Delivery</strong></h3>
                                            <p >At Diamonds On Call Supplier Centre the delivery process is completed, after the goods are delivered by the suppliers to our HongKong office. The time taken for delivery tends to vary according to the destination; however, we make our best efforts to ensure that the domestic order is delivered within 10 working days after the order is confirmed. For international orders the time to deliver might differ. <em>For more details please contact your Account Manager.</em></p>
                                            <div className="text-center">
                                                <figure>
                                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/32.jpg`)} alt="32" border="0" />
                                                </figure>
                                            </div>
                                       </div>
                                       <div className="col-12 border-bottom mb-5">
                                            <h3 id="delivery"><strong>Payment</strong></h3>
                                            <p>Payments can be made through net- banking, bank wire, or cheque. All the payments will be made within 14 days of invoice and delivery. For the wire transfer, bank charges for the supplier bank will be borne by the supplier. For more details please contact your Account Manager.</p>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
