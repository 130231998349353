import React from "react";
import { Accordion, Card } from "react-bootstrap";
import {Eyeclean} from "./Tinge/Eyeclean"
import { Shade } from "./Tinge/Shade";

export const Tinge = () => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        TINGE
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                           <Eyeclean />
                           <Shade />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
