import React from "react";
import { toAbsoluteUrl } from "../../../../_helpers";
import moment from "moment-timezone";

const RecentOrderTbody = (props) =>{
    return (
        <>
            <tr>
                <td className=" align-middle">
                    {   props.status === "1"
                        ?   <span className="label label-light-warning label-pill label-inline">Pending</span>
                        : props.status === "2" ? 
                            <> {props.o_supplier_status === "2"
                                ?  <span className="label label-light-danger label-pill label-inline">Cancelled</span>
                                :  <span className="label label-light-danger label-pill label-inline">Released</span>}
                            </> 
                        : props.status === "3" ? <span className="label label-light-success label-pill label-inline">Approved</span>
                        :  (
                                <>
                                    {/* confitm history status */}
                                    {props.status === "PENDING" || props.status === "READY_TO_PICKUP" ? <span className="label label-light-success label-pill label-inline">Confirmed</span> : null}
                                    {props.status === "QC_OK" ? <span className="label label-light-success label-pill label-inline">QC OK</span> : null}
                                    {props.status === "QC_OK_OUT" ? <span className="label label-light-success label-pill label-inline">QC OK OUT</span> : null}
                                    {props.status === "QC REVIEW IN" || props.status === "QC REVIEW OUT"  ? <span className={`label label-light-${props.supplier_mgr_appr === 'N' ? "danger" : "warning"} label-pill label-inline`}>QC REVIEW</span> : null}
                                    
                                    {/* salse history status */}

                                    { props.status === "INTAKE" || props.status === "PICKUP_DONE" || props.status === "TRANSIT" || props.status === "RECEIVED" || props.status === "SHIPPED" ?
                                           <span className="label label-light-success label-pill label-inline">Delivered</span> : null 
                                    }

                                    {/* { props.status === "INTAKE" || props.status === "PICKUP_DONE" || props.status === "TRANSIT" || props.status === "RECEIVED" || props.status === "SHIPPED" ?
                                            props.o_supplier_status ==="BILL_PENDING" || props.o_supplier_status ==="BILL_RECEIVED" || props.o_supplier_status ==="PAYMENT_DONE" ?
                                                <OverlayTrigger placement="bottom" overlay={<Tooltip >{props.o_supplier_status}</Tooltip>} >
                                                    <span className={`label label-light-${props.o_supplier_status ==="BILL_PENDING" ? "warning" : props.o_supplier_status ==="BILL_RECEIVED" ? "primary" : props.o_supplier_status ==="PAYMENT_DONE" ? "success" : "success"} label-pill label-inline`}>
                                                        Delivered <FaInfoCircle className="ml-2"/>
                                                    </span>
                                                </OverlayTrigger>
                                            : <span className="label label-light-success label-pill label-inline">Delivered</span>
                                        : null 
                                    } */}
                                </>
                           )
                    }
                    {/* <span className={`label label-light-${props.status} label-pill label-inline label-lg"`}>{props.status}</span> */}
                </td>
                <td className="text-center text-nowrap align-middle">{ props.created_date ? moment.tz(props.created_date, props.userinfo_timezone ? props.userinfo_timezone : "Asia/Kolkata").format("YYYY-MM-DD") : "-"}</td>
                <td className="text-center text-nowrap align-middle">
                    {props.diamond_type === "PN" || props.diamond_type === "PL" ? 
                        <div className="d-flex">
                            <div className="pr-5">
                                <img
                                    className="img-fluid mr-2"
                                    alt={props.C_Shape}
                                    src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${props.C_Shape.toLowerCase()}.svg`)}
                                    width="50px"
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/svg_shape/parcel/other.svg`
                                            )
                                        }}
                                />
                            </div>
                            <div className="text-left">
                                <div className={`mb-1 ${props.diamond_type === "PN" ? "natTab" : "labTab"} d-flex align-items-center`}>
                                    <span className={`${props.diamond_type}_diamond small`}>
                                        {props.diamond_type}
                                    </span>
                                    <span className={`text-primary small ml-3`}>
                                        {props.diamond_type}-{props.parcel_id}-{props.split_id}{props.cg_add_split_accept ?<>/{props.cg_add_split_accept}</> : ""}
                                    </span>
                                    <span className={`text-primary text-capitalize small ml-3`}>
                                        {props.length_mm} - {props.width_mm} MM
                                    </span>
                                </div>

                                <span className="text-dark font-weight-bold d-block">
                                    {props.C_Shape}{" "}
                                    {props.weight}Ct.{" "}
                                    {props.color}{" "}
                                    {props.clarity}{" "}
                                </span>
                            </div>
                        </div>
                    :
                        <div className="d-flex">
                            <div className="pr-5">
                                {props.aws_image &&  props.aws_image !=="null" ? (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={props.C_Shape}
                                        src={props.aws_image}
                                        width="50px"
                                        height="50px"
                                        onError={toAbsoluteUrl(
                                            `/media/shape/${props.C_Shape ? props.C_Shape.toLowerCase() : ""}-no.png`
                                        )}
                                    />
                                ) : (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={props.C_Shape}
                                        src={toAbsoluteUrl(
                                            `/media/shape/${props.C_Shape ? props.C_Shape.toLowerCase() : ""}-no.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/noimage.jpg`
                                            )
                                        }}
                                        width="50px"
                                        height="50px"
                                        style={{ backgroundColor: "#f4f4f4" }}
                                    />
                                )}
                            </div>
                            <div className="text-left">
                                <div className={`mb-1 d-flex align-items-center ${props.diamond_type === "W" ? "natTab" : "labTab"}`}>
                                    <span className={`${ props.diamond_type === "W" ? props.C_Color === "fancy" ? "F" : "W" : props.C_Color === "fancy" ? "LF" : "L"  }_diamond small`}>
                                        {props.diamond_type === "W" ? props.C_Color === "fancy"  ? "F" : "W" : props.C_Color === "fancy" ? "LF" : "L"}
                                    </span>
                                    <span className={`text-primary small ml-3`}>
                                        {props.Loat_NO}
                                    </span>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primary mx-2 small"
                                        href={
                                            props.Lab === "IGI"
                                                ? `https://www.igi.org/viewpdf.php?r=${props.Certi_NO}`
                                                : props.Lab === "GIA"
                                                ? `https://www.gia.edu/report-check?reportno=${props.Certi_NO}`
                                                : props.Lab === "HRD"
                                                ? `http://ws2.hrdantwerp.com/HRprops.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                                : props.Lab === "GCAL"
                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_NO}`
                                                : props.Certi_link
                                        }
                                    >
                                        {props.Lab}-{props.Certi_NO}
                                    </a>
                                </div>

                                <span className="text-dark font-weight-bold d-block">
                                    {props.C_Shape}{" "}
                                    {props.C_Weight}{" "}
                                    {props.C_Color === "fancy" ? (
                                        <>
                                            {props.f_intensity}{" "}{props.f_overtone}{" "}{props.f_color}{" "}
                                        </>
                                    ) : (
                                        <>{props.C_Color}{" "}</>
                                    )}
                                    {props.C_Clarity}{" "}{props.C_Cut}{" "}{props.C_Polish}{" "}{props.C_Symmetry}{" "}
                                    {props.C_Fluorescence}
                                </span>
                            </div>
                        </div> 
                        }
                </td>
               
                <td className="text-right text-nowrap pr-5 align-middle">
                    <span className="font-weight-bolder text-primary">
                        ${parseFloat(props.price).toFixed(2)}
                    </span>{" "}
                    <br />
                    <span className="text-muted small">
                        $/CT ${parseFloat(props.O_Rate).toFixed(2)}
                    </span>
                </td>
            </tr>
        </>
    )
}

export default RecentOrderTbody;