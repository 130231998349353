import React from "react";
import {BASE_URL_FILE} from "../../../../env_config";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

// function jugaadDate(x) {
//     // const tempDateNew = moment.tz(x, "America/St_Johns").format("YYYY-MM-DD HH:mm:ss");
//     // const dateNew = moment.tz(tempDateNew, "America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss");
//     // return dateNew

//     let storedDate = new Date(x);
//     storedDate = new Date(storedDate.getTime() - (330 * 60 * 1000));
//     return storedDate
// }

export const InvalidDiamondColumn = (userinfo,editPerm,subId,downloadExcel,invData) => [
    {
        Header: "File Name",
        accessor: "file_name",
        minwidth: 50,
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    {row.value?  
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >{row.value}</Tooltip>} >
                            <span className="text-truncate d-inline-block" style={{ maxWidth: "80px" }}>{row.value}</span>
                        </OverlayTrigger>
                    : "N/A"}
                </>
            )
        }
    },
    // {
    //     Header: "File Upload Date & Time",
    //     // accessor: "created_date",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                 {/* {  d.created_date ? moment.tz(d.created_date, "Etc/GMT").format("YYYY-MM-DD HH:mm:ss") : "-"} */}
    //                 {  d.created_date ? moment.tz(d.created_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
    //             </>
    //         );
    //     },
    //     className: "text-center text-nowrap align-middle",
    // },
    {
        Header: "Processed Date & Time" ,
        // accessor: "file_update_date",
        accessor: (d) => {
            return (
                <>
                {/* {console.log(userinfo.timezone)} */}
                    {  d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className: "text-center text-nowrap align-middle",
    },
    {
        Header: "Status",
        accessor: (d) => {
            return (
                <>
                   <span className="text-danger">{d.upload_status}</span>
                </>
            );
        },
        className: "text-center align-middle",
    },
    
    
    {
        Header: "Action",
        accessor: (d) => {
            return (
                <>
                    {editPerm || !subId ?
                        <> {d.file_name ?  <a href={`${BASE_URL_FILE}/assets/supplier_upload_file/${d.file_name}`} className="btn btn-sm btn-primary">Download</a> : "N/A"}</>
                    : "-"}
                </>
            );
        },
        className: "text-center align-middle",
    },
    
];
