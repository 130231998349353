/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";

export function InvalidstoneTile({invalidDiamondCount,userinfo}) {
  return (
    <>
      <div className={`card card-custom invalidstone card-stretch gutter-b`} style={{ height: "120px" }} >
          <Link to="/invalid-diamond" >
            <div className="card-body">
              <div className="text-white font-weight-bolder font-size-h2 mt-3">{invalidDiamondCount !== "" ? invalidDiamondCount :"0" }</div>
              <span className="text-light font-weight-bold font-size-lg mt-1">Total Invalid Diamonds</span>
              <div className="bgIcon">
                <FaExclamationTriangle />
              </div>
            </div>
          </Link>
      </div>
    </>
  );
}
