import React , { useEffect } from "react";
import {Link} from "react-router-dom";
import { Alert } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import '../../../../node_modules/react-circular-progressbar/dist/styles.css';
import { useSubheader } from "../../layout";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
// import "./Dashboard.css"
import {
  HoldreqTile,
  ConfirmHistoryTile,
  SaleHistoryTile,
  ActiveStoneTile,
  InvalidstoneTile,
  ProfileTile,
  RecentOrderDetails,
  SalesChart,
  DashboardProfile
} from "./dashboardComponent";
import {toAbsoluteUrl} from "../../_helpers";

export function Dashboard(userinfo) {
// console.log(userinfo.subId)
  const [editPerm, setEditPerm] = React.useState(0)
  const [readPerm, setReadPerm] = React.useState(0)
  const [dashData, setDashData] = React.useState([]);
  const [supplierData, setSupplierData] = React.useState([]);
  const [recentOrderData, setRecentOrderData] = React.useState([]);
  const [salesChartData, setSalesChartData] = React.useState([]);

  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard");
  // console.log(userinfo)
  useEffect(() => {
      document.title = "Dashboard | Diamonds on Call - Suppliers Center";

      let isMounted = true;
        const finalData = {
          "C_Name":userinfo.userinfo.supplier_name,
          "id":userinfo.userinfo.id,
          "Type":userinfo.userinfo.type === "Natural" ? "N" : "L",
          "stock_status":`${userinfo.userinfo.stock_status}`
        };
        const recentOrderData = {
          "C_Name":userinfo.userinfo.supplier_name,
        };

        if (userinfo.subId){
          const permiFetchData = {
            "sub_supp_id":userinfo.subId,
            "resource_id":1
          };
          axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/fetchSuppPermission`,
            headers: { "Content-Type": "application/json" },
            data: permiFetchData,
          }).then((res) => {
            // console.log(res.data.data[0])
              if (res && res.data) {
                  isMounted && setEditPerm(res.data.data[0].edit_permission);
                  isMounted && setReadPerm(res.data.data[0].read_permission);
              }
          }).catch(error => {
            // setLoader(false)
            // console.log(error,"ERROR")
        });
        }

        // console.log("data", userinfo);
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/dashBoardFunctionality`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then((res) => {
          // console.log(res)
            if (res && res.data) {
                isMounted && setDashData(res.data);
                isMounted && setSupplierData(res.data.supp_detail);
            }
        });

        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/recentlyOrderDetails`,
            headers: { "Content-Type": "application/json" },
            data: recentOrderData,
        }).then((res) => {
          if (res && res.data) {
                // console.log("res",res.data.data)
                  isMounted && setRecentOrderData(res.data.data);
              }
        });
        axios({
          method: "POST",
          url: `${NODE_API_URL}/SUPP/salesChart`,
          headers: { "Content-Type": "application/json" },
          data: recentOrderData,
      }).then((res) => {
        // console.log(res.data.data)
        if (res && res.data) {
                isMounted && setSalesChartData(res.data.data);
            }
      });
  }, [userinfo.userinfo.supplier_name,userinfo.userinfo.id,userinfo.subId,userinfo.userinfo.stock_status,userinfo.userinfo.type]);

// console.log(readPerm)
  const percentage = dashData.active_stone > 0 ? 60 : 30;
  return (
    <>
    <div className="row ">
          <div className="col-12">
              <h2 className="text-dark mb-4">Welcome to Supplier centre, <span className="text-primary">{userinfo.userinfo.supplier_name_o}</span></h2>
          </div>
    </div>
    <div className="row">
      <div className="col-12">
      {userinfo.userinfo.status === 1 &&
        <Alert variant="danger">
           Your account is Inactive, please contact your purchase executive.
        </Alert> }
      </div>
    </div>
    {userinfo.userinfo.is_pending === 1 &&
      <>
        <div className="row ">
          {/* <div className="col-12">
              <h2 className="text-dark mb-4">Welcome to Supplier centre</h2>
          </div> */}
          <div className="col-12">
            
              {(((!userinfo.userinfo.designation) || (!userinfo.userinfo.address) || !userinfo.userinfo.c_partnet_doc) || (!userinfo.userinfo.c_document_type) || (!userinfo.userinfo.c_partnet_name) || (!userinfo.userinfo.c_register_upload) || (!userinfo.userinfo.c_register_no)) ?
                <Alert variant="danger">
                  {/* Your profile is under admin review. Please wait for approval until then you can start uploading your stock */}
                    Your profile is not Activated. Please Complete your <Link to="/profile">profile</Link>.
                </Alert> 
              :
                <Alert variant="success">
                  Thank you for completing your profile. Please wait for approval, until then you can start uploading your stock.
                </Alert>
              }
          </div>
        </div> 
        <div className="row shadow-xs flex-row card card-custom gutter-b mx-0 py-10 border">
            <div className="col-lg-3 col-md-6 text-center">
              <Link to="/profile" >
                <img className="mb-3" alt="Business Info" src={toAbsoluteUrl("/media/deal.svg")}/>
                <h5 className="text-danger mt-3">Business Info</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <Link to="/upload-diamond" >
                <img alt="Add Listing" src={toAbsoluteUrl("/media/add_list.svg")}/>
                <h5 className="text-danger mt-3">Add Listing</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <img alt="Get Approved" src={toAbsoluteUrl("/media/Get_appr.svg")}/>
              <h5 className="text-muted mt-3">Get Approved</h5>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mx-auto" style={{ width: 78, height: 78 }}>
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  strokeWidth={15}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#007bff",
                })}/>
              </div>
              <h5 className="text-muted mt-3">Complete Your Profile</h5>
            </div>
        </div>
      </>
    }
        
      <div className="row dashboardCard">
        <div className="col-lg-4 col-md-6">
          <HoldreqTile holdRequestCount={dashData.hold_request_stone} userinfo={userinfo.userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <ConfirmHistoryTile confirmHistoryCount={dashData.confirm_history_stone} userinfo={userinfo.userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <SaleHistoryTile salesHistoryCount={dashData.sales_history_stone} userinfo={userinfo.userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <ActiveStoneTile activeStoneCount={dashData.active_stone} userinfo={userinfo.userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <InvalidstoneTile invalidDiamondCount={dashData.invalid_diamond} userinfo={userinfo.userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <ProfileTile />
        </div>
        {readPerm || !userinfo.subId ? 
         <>
            <div className="col-12">
              <RecentOrderDetails recentOrderData={recentOrderData} userinfo={userinfo} />
            </div>
            <div className="col-lg-8">
              <SalesChart salesChartData={salesChartData} />
            </div>
            <div className="col-lg-4">
              <DashboardProfile supplierData={supplierData} />
            </div>
         </> : ""
        }
      </div>
    </>
  );
}
