import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { toAbsoluteUrl } from "../../_helpers";
// import SVG from "react-inlinesvg";

// function numberWithCommas(x) {
//     return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

function reasonGroup (x) {
    var y = x.replace(/,\s*$/, "");
    var reason = y.split(',').map(function (x, index) {
        return <span className="label label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold mr-1 text-nowrap" key={index}>{ x }</span>; 
    });

    return <div className="d-flex align-items-center justify-content-center flex-wrap">{reason}</div>;
}

export const InvalidDiamondColumn = () => [

   
    {
        Header: "Reason",
        accessor: "reason",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{ row.row.original.reason ? reasonGroup(row.row.original.reason): "-"}</>;
        },
    },
    {
        Header: "Country",
        accessor: "country",
        className: "text-center align-middle",
    },
    {
        Header: "Stone ID",
        accessor: "Loat_NO",
        className: "text-center text-nowrap align-middle",
    },
    {
        Header: "Carat",
        accessor: "C_Weight",
        // sortMethod: (a, b) => Number(a) - Number(b),
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{parseFloat(row.value).toFixed(2)}</>;
        },
        sortType: (rowA, rowB) => {
            if (rowB.original.C_Weight > rowA.original.C_Weight) return 1;
            if (rowA.original.C_Weight > rowB.original.C_Weight) return -1;
        }
    },
    {
        Header: "Shape",
        accessor: "C_Shape",
        className: "text-center align-middle p-2",
        // Cell: (row) => (
        //     <>
        //         {/* <SVG
        //             width="22px"
        //             height="22px"
        //             src={toAbsoluteUrl(
        //                 `/media/shape/svg_shape/${row.row.original.C_Shape.toLowerCase()}.svg`
        //             )}
        //         /> */}
        //         <div >{row.row.original.C_Shape}</div>
        //     </>
        // ),
    },
    {
        Header: "Color",
        accessor: (d) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {d.C_Color === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}
                                </>
                            ) : (
                                d.C_Color
                            )}
                        </Tooltip>
                    }
                >
                    <span
                        className="d-inline-block text-truncate text-dark"
                        style={{ maxWidth: "80px" }}
                    >
                        {d.C_Color === "fancy" ? (
                            <>
                                {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                            </>
                        ) : (
                            d.C_Color
                        )}
                    </span>
                </OverlayTrigger>
            </>
        ),
        minwidth: 50,
        className: "text-center align-middle",
    },
    {
        Header: "Clarity",
        accessor: "C_Clarity",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Cut",
        accessor: "C_Cut",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Polish",
        accessor: "C_Polish",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Symmetry",
        accessor: "C_Symmetry",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Fluo.",
        accessor: "C_Fluorescence",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Lab",
        accessor: "Lab",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Certificate",
        accessor: "Certi_NO",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "$/CT",
        className: "text-right align-middle pr-5",
        accessor: "O_Rate",
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => (
            <>
                {
                   
                    <span> ${parseFloat(row.value).toFixed(2)}</span>
                }
            </>
        ),
    },
    {
        Header: "Total",
        className: "text-right align-middle pr-5",
        accessor: (d) => {
            return (
                <>
                    ${parseFloat(d.O_Rate * d.C_Weight).toFixed(2) }
                </>
            );
        },
        // sortMethod: (a, b) => Number(a) - Number(b),
        disableSortBy: true,
    }
];
