import React,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";

export const ActiveShipping = (props) => {
    const [loading, setLoading] = useState(false);
    // console.log(props.data)
    const hideModel = () => {
        props.onHide();
    };

    const activeShip = (props) =>{
       

        let diamondCerti = props.data.map(value => {
            return (
                (value.Certi_NO)
            )
        })

        const finalData = {
            "Certi_NO":diamondCerti,
            "diamondType":props.data[0].diamond_type === "W" ? "N" : "L"
        };
        // console.log("finalData",finalData)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/activeShipment`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then((res) => {
            setLoading(false);
            // console.log(res);
            if (res && res.data) {
                // setData(res.data.data)
                // props.holdListData();
                props.setSuccessMsg(true)
                props.setTemp(!props.temp)
                setTimeout(() => {
                    props.setSuccessMsg(false)
                }, 10000);
                props.onHide();
                // window.location.reload();
            }
        })
        .catch((error) => {
            // console.log(error, "ERROR");
            setLoading(false);
        });
    }

    function total(x) {
        let total = parseFloat(x.O_Rate * x.C_Weight).toFixed(2);
        return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header className="">
                    <Modal.Title>Are you sure you want to activate stone for shipment?
                        <div className="small font-size-sm font-weight-bold text-primary">
                            Total diamonds: {props.totalDiamonds} diamonds |
                            Total Carat :{parseFloat(props.totalCarat).toFixed(2)} |
                            Total $/CT: ${parseFloat(props.totalDollarCarat).toFixed(2)} |
                            Total Payable: ${parseFloat(props.totalPrice).toFixed(2)}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive custom-scrollbar" style={{ maxHeight: "400px", position: "relative" }}>
                                <table className="table table-hover table-striped activeShipTable mb-0">
                                    <thead className="custom_datatable_head sticky-top">
                                        <tr>
                                            <th className="text-center text-nowrap">Stone ID</th>
                                            <th className="text-center text-nowrap">Shape</th>
                                            <th className="text-center text-nowrap">Carat</th>
                                            <th className="text-center text-nowrap">Color</th>
                                            <th className="text-center text-nowrap">Clarity</th>
                                            <th className="text-center text-nowrap">Cut</th>
                                            <th className="text-center text-nowrap">Polish</th>
                                            <th className="text-center text-nowrap">Symm.</th>
                                            <th className="text-center text-nowrap">Flour.</th>
                                            <th className="text-center text-nowrap">Lab</th>
                                            <th className="text-center text-nowrap">Certificate</th>
                                            <th className="text-center text-nowrap">$/CT</th>
                                            <th className="text-center text-nowrap">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {props.data.length !== 0 ? props.data.map((row) => {
                                        return(
                                            <tr key={row.id} >
                                                <td className="text-center text-nowrap">{row.Loat_NO}</td>
                                                <td className="text-center text-nowrap">{row.C_Shape}</td>
                                                <td className="text-center text-nowrap">{parseFloat(row.C_Weight).toFixed(2)}</td>
                                                <td className="text-center text-nowrap">{row.C_Color}</td>
                                                <td className="text-center text-nowrap">{row.C_Clarity}</td>
                                                <td className="text-center text-nowrap">{row.C_Cut}</td>
                                                <td className="text-center text-nowrap">{row.C_Polish}</td>
                                                <td className="text-center text-nowrap">{row.C_Symmetry}</td>
                                                <td className="text-center text-nowrap">{row.C_Fluorescence}</td>
                                                <td className="text-center text-nowrap">{row.Lab}</td>
                                                <td className="text-center text-nowrap">{row.Certi_NO}</td>
                                                <td className="text-center text-nowrap">${row.O_Rate}</td>
                                                <td className="text-center text-nowrap">${total(row)}</td>
                                            </tr>
                                        )
                                    }) : null }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {props.onHide ? <Button
                        variant="secondary"
                        onClick={() => hideModel(props)}
                        className="mr-4 btn-square px-10"
                    >
                        cancel
                    </Button> : ""}
                    <Button className="btn-square px-10"
                        variant="primary"
                        onClick={()=> activeShip(props)}
                    >
                        Yes
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
