import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { ViewUserTable } from "./ViewUserTable";

export function ViewUser({ userinfo,updateTemp ,temp}) {
    
    return (
        <div className="row">
            <div className="col-12">
                <Accordion className="search_accordion" defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" key="1">
                            View/Manage user
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className="pb-0">
                                <ViewUserTable userinfo={userinfo} temp={temp} updateTemp={()=> updateTemp()}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>
    );
}
