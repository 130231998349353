import React from "react";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

// function jugaadDate(x) {
//     // const tempDateNew = moment.tz(x, "America/St_Johns").format("YYYY-MM-DD HH:mm:ss");
//     // const dateNew = moment.tz(tempDateNew, "America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss");
//     // return dateNew

//     let storedDate = new Date(x);
//     storedDate = new Date(storedDate.getTime() - (330 * 60 * 1000));
//     return storedDate
// }

export const pendingFeedColumn = (userinfo) => [
    {
        Header: "Requested ID",
        accessor: "sr_id",
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Customer Status",
        accessor: (d) => {
            return (
                <>
                    {d.api === "0" ?  <div className="d-flex align-items-center justify-content-center"><span className="label label-dot label-danger"></span><span className="text-danger pl-3 font-weight-bolder">API turn Off by admin</span></div>
                        : d.api_on_off == "1" ? <div className="d-flex align-items-center justify-content-center"><span className="label label-dot label-success"></span><span className="text-success pl-3 font-weight-bolder">Active</span></div>
                        : <div className="d-flex align-items-center justify-content-center"><span className="label label-dot label-danger"></span><span className="text-danger pl-3 font-weight-bolder">InActive</span></div>
                    }
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Country" ,
        accessor: "country",
        className: "text-center align-middle",
        
    },
    {
        Header: "Request Id Timestamp",
        // accessor: "updated_date",
        accessor: (d) => {
            return (
                <>
                    {  d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className: "text-center align-middle",
    }
];
