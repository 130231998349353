import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    newpassword: "",
    confirmpassword: "",
};

function ResetForm() {
    const { token } = useParams();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [expiredMsg, setExpiredMsg] = useState(false);

    useEffect(() => {
        document.title = "Reset Password | Diamonds on Call - Suppliers Center";

        let isMounted = true;
        const finalData = {
            token: token,
        };
        // console.log(finalData);
        // console.log("data", userinfo);
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/checktoken`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                // console.log(res);
                if (res && res.data) {
                    isMounted && setExpiredMsg(res.data.error);
                    // isMounted && setSupplierData(res.data.supp_detail);
                }
            })
            .catch((res) => {
                // console.error(res.response);
            });
    }, []);
    // console.log(expiredMsg);
    const Schema = Yup.object().shape({
        newpassword: Yup.string("Enter your new password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
        confirmpassword: Yup.string("retype your new password")
            .oneOf([Yup.ref("newpassword"), null], "Password must match")
            .min(8, "Password should be of minimum 8 characters length")
            .required("retype Password is Required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
                const finalData = {
                    password: values.newpassword,
                    token: token,
                };

                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/SUPP/resetPassword`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                    data: finalData,
                })
                    .then(function(res) {
                        disableLoading();
                        setStatus(res.data.msg);
                        setSuccess(res.data.success);
                        // console.log(res);
                    })
                    .catch((res) => {
                        // console.error(res.response);
                        disableLoading();
                        setSubmitting(false);
                    });
                // disableLoading();
            }, 1000);
        },
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {expiredMsg === "expiredlink" ? (
                <div className="text-center">
                    <img
                        className="mb-5"
                        width="100px"
                        alt="verified"
                        src={toAbsoluteUrl("/media/General/warning.png")}
                    />
                    <h2 className="font-weight-bolder d-block text-primary">
                        The link you followed has expired
                    </h2>
                    <Link
                        to="/"
                        className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                    >
                        <span>Go Home</span>
                    </Link>
                </div>
            ) : (
                <>
                    {/* begin::Head */}
                    <div className="mb-5">
                        <h1> Recover Password </h1>
                    </div>
                    {/* end::Head */}
                    {/*begin::Form*/}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        {formik.status ? (
                            <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
                                <div className="alert-text font-weight-bold">
                                    {formik.status}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        {!success ? (
                        <>
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="type">
                                    New Password
                                    <span className="text-danger"> *</span>
                                </label>
                                <input
                                    placeholder="New Password"
                                    type="password"
                                    className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
                                        "newpassword"
                                    )}`}
                                    name="newpassword"
                                    {...formik.getFieldProps("newpassword")}
                                />
                                {formik.touched.newpassword &&
                                formik.errors.newpassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.newpassword}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="type">
                                    Confirm New Password
                                    <span className="text-danger"> *</span>
                                </label>
                                <input
                                    placeholder="Confirm New Password"
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
                                        "confirmpassword"
                                    )}`}
                                    name="confirmpassword"
                                    {...formik.getFieldProps("confirmpassword")}
                                />
                                {formik.touched.confirmpassword &&
                                formik.errors.confirmpassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.confirmpassword}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                                >
                                    <span>Reset Password</span>
                                    {loading && (
                                        <span className="ml-3 spinner spinner-white"></span>
                                    )}
                                </button>
                            </div>
                        </>
                        ) :
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <Link
                                to="/auth/login"
                                className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                            >
                                <span>Click to Login</span>
                            </Link>
                        </div> }
                    </form>
                </>
            )}
            {/*end::Form*/}
        </div>
    );
}

export default ResetForm;
