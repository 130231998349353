import React from "react";
import whyusImg from "../Images/whyus.jpg"


const Whyus = () =>{
    return(
        <>
            <div className="container-fluid bg-f6f8fc">
                <div className="container pt-5 pb-4">
                    <div className="row justify-content-center pt-4 pb-4 whyus">
                        <div className="col-md-6 col-sm-12 align-self-center">
                            <h2 className="pb-4 font-size-h1">Why Diamonds On Call for Suppliers?</h2>
                            <p className="font-size-lg">Since 2017, Diamonds On Call has been working with more than 100 supplier and delivering diamonds to jewellers in 26+ countries. With supplier centre, Diamonds On Call brings ease of managing orders online for all our prestigious and new diamond suppliers.</p>
                            <p className="font-size-lg">At Diamonds on call, we try to push our limits and tackle the challenges our Diamond industry face. We believe diamonds from all the suppliers, manufacturers Or dealers should be accessible by every jeweller around the globe, conflict free.</p>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center  align-self-center">
                            <img src={whyusImg} alt="why-diamondsoncall" title="Why us" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Whyus;