import React from "react";

export const CompanyRefdata = (props) => {
    return (
        <div className="card card-custom card-stretch">
            <div className="card-header py-3">
                <div className="card-title"> Company reference </div>
            </div>
            <div className="card-body">
                <div className="font-size-h6 font-weight-bolder border-bottom pb-2 mb-3">
                    Company reference - 1
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Company Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.comp_name_1 ? props.data.comp_name_1 : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Person Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.person_name_1
                            ? props.data.person_name_1
                            : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Designation:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.designation_1
                            ? props.data.designation_1
                            : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Email Id:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.email_id_1 ? props.data.email_id_1 : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Contact number:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.contact_no_1
                            ? props.data.contact_no_1
                            : "-"}
                    </div>
                </div>
                <div className="font-size-h6 font-weight-bolder border-bottom pb-2 mb-3 mt-3">
                    Company reference - 2
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Company Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.comp_name_2 ? props.data.comp_name_2 : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Person Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.person_name_2
                            ? props.data.person_name_2
                            : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Designation:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.designation_2
                            ? props.data.designation_2
                            : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Email Id:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.email_id_2 ? props.data.email_id_2 : "-"}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Contact number:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.data.contact_no_2
                            ? props.data.contact_no_2
                            : "-"}
                    </div>
                </div>
            </div>
        </div>
    );
};
