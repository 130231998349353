import React from "react";
import {BASE_URL_FILE} from "../../../../env_config";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
import { FaExternalLinkAlt } from 'react-icons/fa';

// function jugaadDate(x) {
//     // const tempDateNew = moment.tz(x, "America/St_Johns").format("YYYY-MM-DD HH:mm:ss");
//     // const dateNew = moment.tz(tempDateNew, "America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss");
//     // return dateNew

//     let storedDate = new Date(x);
//     storedDate = new Date(storedDate.getTime() - (330 * 60 * 1000));
//     return storedDate
// }

export const InvalidDiamondColumn = (userinfo,editPerm,subId,downloadExcel,invData) => [
    {
        Header: "File Name",
        accessor: "info",
        minwidth: 50,
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    {row.row.original.upload_type === "File"?  
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >{row.row.original.info}</Tooltip>} >
                            <span className="text-truncate d-inline-block" style={{ maxWidth: "80px" }}>{row.row.original.info}</span>
                        </OverlayTrigger>
                    : "N/A"}
                </>
            )
        }
    },
    {
        Header: "Upload Type",
        accessor: "upload_type",
        className: "text-center align-middle",
    },
    {
        Header: "Stock Status",
        accessor: (d) => {
            return (
                <>
                   {d.stock_status === "LIVE"?<span className="text-success">{d.stock_status}</span>
                   :d.stock_status === "Expired"?<span className="text-danger">{d.stock_status}</span>
                   :d.stock_status === "Freeze"?<span className="text-danger">{d.stock_status}</span>
                   :""}
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Date and Time",
        // accessor: "created_date",
        accessor: (d) => {
            return (
                <>
                    {/* {  d.created_date ? moment.tz(d.created_date, "Etc/GMT").format("YYYY-MM-DD HH:mm:ss") : "-"} */}
                    {  d.created_date ? moment.tz(d.created_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className: "text-center text-nowrap align-middle",
    },
    // {
    //     Header: "File Upload Date & Time" ,
    //     // accessor: "file_update_date",
    //     accessor: (d) => {
    //         return (
    //             <>
    //             {/* {console.log(userinfo.timezone)} */}
    //                 {  d.file_update_date ? moment.tz(d.file_update_date, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
    //             </>
    //         );
    //     },
    //     className: "text-center text-nowrap align-middle",
    // },
    // {
    //     Header: "Status",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                <span className="text-success">Completed</span>
    //             </>
    //         );
    //     },
    //     className: "text-center align-middle",
    // },
    {
        Header: "Total Diamonds",
        accessor: "no_of_stone",
        className: "text-center align-middle",
    },
    {
        Header: "Valid Diamonds",
        accessor: "active",
        className: "text-center align-middle",
    },
    {
        Header: "Invalid Diamonds",
        accessor: (d,i) => {
            return (
                <>
                   <span className="text-danger">
                    {i === 0 ?
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >Click to download Excel</Tooltip>} >
                            <span className="cursor-pointer" onClick={()=>downloadExcel(invData)}>{d.inactive} <FaExternalLinkAlt className="pl-1"/></span>
                        </OverlayTrigger>
                     : d.inactive}
                   </span>
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Action",
        accessor: (d) => {
            return (
                <>
                {editPerm || !subId ?
                    <> {d.upload_type === "File" && d.stock_status !== "Expired"?  <a href={`${BASE_URL_FILE}/assets/supplier_upload_file/${d.info}`} className="btn btn-sm btn-primary">Download</a> : "N/A"}</>
                : "-"}
                </>
            );
        },
        className: "text-center align-middle",
    },
    
];
