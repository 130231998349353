import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";

export const PendingApiApprove = (props) => {
    const [loading, setLoading] = useState(false);
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onClose();
    };
    const ApproveApifn = (props) => {

        setLoading(true);
        const finalData = {
            status: 1,
            email_Status: props.isActive ? "0" : "1",
            session_id: props.userinfo.id,
            sr_id: props.data.sr_id,
            user_id: props.data.user_id,
            api_id: props.data.api_id,
        };

        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/apiReqAprOrRej`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then((res) => {
            // disableLoading();
            setLoading(false);
            // console.log(res);
            if (res && res.data) {
                // setData(res.data.data)
                // props.holdListData();
                props.onHide();
                props.setTemp(!props.temp)
                props.setSuccessMsg(true)
                // window.location.reload();
            }
        })
        .catch((error) => {
            // console.log(error, "ERROR");
            setLoading(false);
        });
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className="text-center my-6">
                    <h2 className="mb-5 mt-5 text-center">Approve API</h2>
                    <div className="screenAlert-icon screenAlert-warning scaleWarning">
                        <span className="screenAlert-body pulseWarningIns"></span>
                        <span className="screenAlert-dot pulseWarningIns"></span>
                    </div>
                    <p className="font-size-h4 font-weight-bold my-10">
                        Are you sure you want to approve api?
                    </p>
                    {props.onHide ? (
                        <Button
                            variant="secondary"
                            onClick={() => hideModel(props)}
                            className="mr-4 btn-square px-10"
                        >
                            cancel
                        </Button>
                    ) : (
                        ""
                    )}
                    <Button
                        className="btn-square px-10"
                        variant="warning"
                        onClick={() => {
                            ApproveApifn(props);
                        }}
                    >
                        Yes
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};
