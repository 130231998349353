/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Bar } from 'react-chartjs-2';
import { FaChartBar } from "react-icons/fa";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function SalesChart({salesChartData}) {
  // console.log("salesChartData",salesChartData)
  const data = {
    labels:  salesChartData.map((val) => {
        return(
            val.month
        );    
    }),
    datasets: [
      {
        label: 'Total Sale in Dollars',
        data: salesChartData ? 
          salesChartData.map((val) => {
              return(
                  val.total
              );    
          }) 
        : [0, 0, 0, 0, 0, 0 ],
        backgroundColor: [
          'rgb(0 55 191)',
        ]
      },
    ],
  };
  
  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    // plugins: {
    //   title: {
    //     display: true,
    //     text: 'Sales Chart',
    //   },
    // },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    // },
  };

  return (
    <>
      {/* begin::Advance Table Widget 9 */}
      <div className={`border card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <div className="card-header">
          <div className="card-title align-items-center">
            <FaChartBar /> <h3 className="ml-3 mb-0" >Sales Chart</h3>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <Bar data={data} options={options} />
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}