import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Logo from "./Images/logo.svg"

const Header = ({isAuthorized}) =>{
    // console.log("isAuthorized",isAuthorized)
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
   
    return(
        <>
            <nav className="navbar navbar-light navbar-expand-xl sticky-top navigation-clean-button shadow-sm">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={Logo} alt="diamondsoncall-supplier-centre-logo" title="Diamonds On Call - Supplier Centre" />
                    </a>
                    <button onClick={handleNavCollapse} data-toggle="collapse" className="navbar-toggler" data-target="#supplierHeader" aria-expanded={!isNavCollapsed ? true : false} aria-controls="supplierHeader" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className= {`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`} id="supplierHeader">
                            {isAuthorized ?
                                <ul className="nav navbar-nav ml-auto">
                                    <li className="nav-item" role="presentation"><NavLink exact activeClassName='active' className="nav-link" to="/logout">Logout</NavLink></li>
                                </ul>
                            : 
                                <>
                                    <ul className="nav navbar-nav ml-auto">
                                        <li className="nav-item" role="presentation"><NavLink exact activeClassName='active' className="nav-link" to="/">Home</NavLink></li>
                                        <li className="nav-item" role="presentation"><HashLink className="nav-link" to="/#howitworks">How It Works</HashLink></li>
                                        <li className="nav-item" role="presentation"><HashLink className="nav-link" to="/#contact">Contact</HashLink></li>
                                        <li className="nav-item" role="presentation"><NavLink exact activeClassName='active' className="nav-link" to="/auth/login">Login</NavLink></li>
                                    </ul>
                                    <NavLink className="btn action-button py-2 px-8 ml-2 font-size-lg" role="button" to="/auth/registration">Join now </NavLink>
                                </>
                            }
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;