import React from "react";
import { Field } from "formik";

export const Lab = ({userinfo}) => {
    const LabData = ([
        {
            id: 1,
            lab:"GIA"
        },
        {
            id: 2,
            lab:"IGI"
        },
        {
            id: 3,
            lab:"HRD"
        },
        {
            id: 4,
            lab:"OTHER"
        }
    ]);
    const LabData_lab = ([
        {
            id: 1,
            lab:"GIA"
        },
        {
            id: 2,
            lab:"IGI"
        },
        {
            id: 3,
            lab:"HRD"
        },
        {
            id: 4,
            lab:"GCAL"
        },
        {
            id: 5,
            lab:"OTHER"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">LAB</p>
                </div>
                {userinfo.type === "Natural" ? 
                    <div className="col-lg-10">
                        {LabData.map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                                <div className="labBox selectBox">
                                    <span>{item.lab}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                :
                    <div className="col-lg-10">
                        {LabData_lab.map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                                <div className="labBox selectBox">
                                    <span>{item.lab}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                }
            </div>
        </>
    );
};