import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';
// import { toAbsoluteUrl } from "../../_helpers";

export const EditUserPopup = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    // console.log(props)

    const initialValues = {
        username:props.data.username,
        password: "",
        confirmpassword: "",
        email:props.data.email,
    };

    useEffect(() => {
        setShowPopup(props.show);
    }, [props.show]);

    const Schema = Yup.object().shape({
        password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
        confirmpassword: Yup.string("retype your new password")
            .oneOf([Yup.ref("password"), null], "Password must match")
            .min(8, "Password should be of minimum 8 characters length")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
        email: Yup.string()
            .email("Wrong email format")
            .required("email is Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting ,resetForm}) => {
            var finalData =''
           if(values.password === ""){
            var finalData = {
                    "sub_supp_id": props.data.sub_supp_id,
                    "email":values.email
                }
           }else{
            var finalData = {
                    "sub_supp_id": props.data.sub_supp_id,
                    "password": values.confirmpassword === "" ? null : values.confirmpassword  ,
                    "email":values.email
                }
            }
            setLoading(true);
            // console.log(finalData)
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/updateIntoSuppGroup`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            })
                .then((res) => {
                    setLoading(false);
                    if (res && res.data) {
                        // console.log("data", res);
                        setSubmitting(false);
                        setSuccessMessage(res.data.msg);
                        setErrorMessage();
                        resetForm();
                        const timeId = setTimeout(() => {
                            setSuccessMessage()
                            props.updateTemp()
                            props.onHide()
                        
                        }, 1000)
                        return () => {
                            clearTimeout(timeId)
                        }
                    }
                })
                .catch((res) => {
                    setLoading(false);
                    setSubmitting(false);
                    // console.error("error", res.response);
                    setSuccessMessage();
                    setErrorMessage(res.response.data.msg);
                    setSuccessMessage();
                    const timeId = setTimeout(() => {
                        setErrorMessage()
                    }, 5000)
                
                    return () => {
                        clearTimeout(timeId)
                    }
                });
        },
    });
    const [errmessage, setErrorMessage] = useState();
    const [successmsg, setSuccessMessage] = useState();

    const closeModel = () => {
        props.onHide();
        setShowPopup(false);
    };

    return (
        <div>
            <Modal
                show={showPopup}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form
                    id=""
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span className="font-weight-bold ">
                               Edit User - <span className="text-primary ml-2">{props.data.username}</span>
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* begin::Form */}
                        <div className="row mt-5">
                            <div className="col-12">
                                {errmessage ? (
                                    <div className="mb-5 alert alert-custom alert-light-danger mb-4 py-4 alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                        {errmessage}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {successmsg ? (
                                    <div className="mb-5 alert alert-custom alert-light-success mb-4 py-4 alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                        {successmsg}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                        Username
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                        <input
                                            type="text"
                                            placeholder="Enter Username"
                                            className={`form-control form-control-lg  ${getInputClasses(
                                                "username"
                                            )}`}
                                            autoComplete="off"
                                            disabled
                                            name="username"
                                            {...formik.getFieldProps(
                                                "username"
                                            )}
                                        />
                                        {formik.touched.username &&
                                        formik.errors.username ? (
                                            <div className="invalid-feedback">
                                                { formik.errors.username}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                        Password
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                        <input
                                            type="password"
                                            placeholder="Enter Password"
                                            className={`form-control form-control-lg  ${getInputClasses(
                                                "password"
                                            )}`}
                                            autoComplete="off"
                                            name="password"
                                            {...formik.getFieldProps(
                                                "password"
                                            )}
                                        />
                                        {formik.touched.password &&
                                        formik.errors.password ? (
                                            <div className="invalid-feedback">
                                                {
                                                    formik.errors.password
                                                }
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                        Verify Password
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                        <input
                                            type="text"
                                            placeholder="Verify Password"
                                            className={`form-control form-control-lg  ${getInputClasses(
                                                "confirmpassword"
                                            )}`}
                                            autoComplete="off"
                                            name="confirmpassword"
                                            {...formik.getFieldProps(
                                                "confirmpassword"
                                            )}
                                        />
                                        {formik.touched.confirmpassword &&
                                        formik.errors.confirmpassword ? (
                                            <div className="invalid-feedback">
                                                {
                                                    formik.errors.confirmpassword
                                                }
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                        Email
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                        <input
                                            type="email"
                                            placeholder="Enter Email"
                                            className={`form-control form-control-lg  ${getInputClasses(
                                                "email"
                                            )}`}
                                            autoComplete="off"
                                            name="email"
                                            {...formik.getFieldProps(
                                                "email"
                                            )}
                                        />
                                        {formik.touched.email &&
                                        formik.errors.email ? (
                                            <div className="invalid-feedback">
                                                {
                                                    formik.errors.email
                                                }
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Form */}
                    </Modal.Body>
                    <Modal.Footer className="py-3 justify-content-between">
                        <Button
                            variant="danger"
                            className="font-weight-bold"
                            onClick={() => closeModel(props)}
                        >
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9"
                        >
                            <span>Save</span>
                            {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};
