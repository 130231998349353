import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";

export const ContactMail = ({userinfo}) => {


    return (
        <>
           <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">Contact Informaition</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span>Contact</span>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="font-size-h1 font-weight-bold">Need Help ?</div>
                   <div> Our purchase executives are here to help and answer any questions you might have. We look forward to hearing from you.<br/>Please Select the category below related to your enquiry, and mention the discretion with image, our team will get back to you as soon as possible.</div>
                </div>
                <div className="col-12 mt-5">
                    <div className="row">
                        {/* <div className="col-md-7">
                            <ContactForm userinfo={userinfo} />
                        </div> */}
                        <div className="col-md-5">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <div className="card-title">Contact Us</div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 " ><b >Address:</b></div>
                                        <div className="col-md-8 " >UNIT E-F, 8/F, 8 Hart Avenue, Tsim Sha Tsui, Kowloon, <br/>HONG KONG</div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-4 "><b >Email:</b></div>
                                        <div className="col-md-8 ">supplier@diamondsoncall.com</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 "><b >WhatsApp:</b></div>
                                        <div className="col-md-8 ">+852 4644 4677</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
