import React, { useState, useEffect } from "react";
import { Modal} from "react-bootstrap";
import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';
// import { toAbsoluteUrl } from "../../_helpers";

export const PermissionPopup = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] =useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setShowPopup(props.show);
    }, [props.show]);
    const [update,setUpdate] = useState(false)
    useEffect(() => {
        let isMounted = true;
          
          axios({
              method: "POST",
              url: `${NODE_API_URL}/SUPP/fetchSupplierResource`,
              headers: { "Content-Type": "application/json" },
              data: {"sub_supp_id":props.data.sub_supp_id},
          }).then((res) => {
            // console.log(res)
              if (res && res.data.data) {
                // setLoader(false)
                  isMounted && setData(res.data.data);
                  
              }
          }).catch((res) => {
            // setLoader(false)
            // console.error("error", res.response);
          });
          
    }, [props.data.sub_supp_id,update]);



    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };



    const closeModel = () => {
        props.onHide();
        setShowPopup(false);
    }; 
    const UpdateChangedValue = (value,val) => {

            const finalData ={
                "sub_supp_id":props.data.sub_supp_id,
                "resource_id":value.id,
                "edit_permission":value.checked ? "1" : "0",
                "updated_by":props.data.supp_id
            }
            // console.log("finalData",finalData)
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/updateSuppPermission`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then((res) => {
                disableLoading();
                // console.log("res123",res)
                if (res && res.data) {

                    setUpdate(!update)
                }
            }).catch(error => {
                // console.log(error.response.data.error,"ERROR")
                disableLoading();
            })


    }
    const UpdateReadChangedValue = (value) => {

        const finalData ={
            "sub_supp_id":props.data.sub_supp_id,
            "resource_id":value.id,
            "read_permission":value.checked ? "1" : "0",
            "updated_by":props.data.supp_id
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/updateSuppPermission`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then((res) => {
            disableLoading();
            if (res && res.data) {
                setUpdate(!update)
            }
        }).catch(error => {
            // console.log(error.response,"ERROR")
            disableLoading();
        })

    }

    return (
        <div>
            <Modal
                show={showPopup}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                {/* <form
                    id=""
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                > */}
                    <Modal.Header>
                        <Modal.Title>
                            <span className="font-weight-bold ">
                                Change Permissions - <span className="text-primary ml-2">{props.data.username}</span>
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <div className="row form">
                        <div className="col-12">
                       
                            <table className="table table-bordered permissionTable">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="text-center">Page Name</th>
                                        <th className="text-center">View Permission</th>
                                        <th className="text-center">Edit Permission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length !== 0 ? data.map((val) => {
                                        return(
                                            <tr key={val.id}>
                                                <td>{val.page_title}</td>
                                                <td>
                                                    <label className="checkbox justify-content-center">
                                                        {/* <Field type="checkbox" name="view" value={`${val.page_url}_view`}  {...formik.getFieldProps("view")}/> */}
                                                        <input
                                                            type="checkbox"
                                                            name={`view_${val.id}`}
                                                            id={val.id}
                                                            // checked={val.read_permission === "1" && true}
                                                            checked={val.read_permission == "1" && true}
                                                            onChange={(e) => UpdateReadChangedValue(e.target)}
                                                            value={val.read_permission}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="checkbox justify-content-center">
                                                        {/* <Field type="checkbox" name="edit" value={`${val.page_url}_edit`} {...formik.getFieldProps("edit")} /> */}
                                                        <input
                                                            type="checkbox"
                                                            name={`edit_${val.id}`}
                                                            // checked={true}
                                                            id={val.id}
                                                            checked={val.edit_permission == "1" && true}
                                                            onChange={(e,val) => UpdateChangedValue(e.target,val)}
                                                            value={val.edit_permission}
                                                        />
                                                        <span></span>
                                                    </label>
                                                   
                                                </td>
                                            </tr>
                                        );    
                                    }) : <tbody><tr><td className="text-center border-bottom" colSpan="100%">No record found</td></tr></tbody> }
                                    
                                </tbody>
                            </table>
                        </div>
                       </div>
                    </Modal.Body>
                    <Modal.Footer className="py-3 justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9"
                            onClick={() => closeModel(props)}
                        >
                            <span>Close</span>
                        </button>
                        {/* <button
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9"
                            onClick={() => closeModel(props)}
                        >
                            <span>Save</span>
                            {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                        </button> */}
                    </Modal.Footer>
                {/* </form> */}
            </Modal>
        </div>
    );
};
