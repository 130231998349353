import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Carat } from "./Basic/Carat";
import {Shape} from "./Basic/Shape"
import {Color} from "./Basic/Color"
import { Clarity } from "./Basic/Clarity";
import { Lab } from "./Basic/Lab";
import { Shortcut } from "./Basic/Shortcut";
import { Finish } from "./Basic/Finish";
import { Flour } from "./Basic/Flour";
import { Location } from "./Basic/Location";
export const Basic = ({formik,option,userinfo}) => {
    // console.log(formik,"formik")
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        BASIC
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <Shape />
                            <Carat formik={formik}/>
                            <Color formik={formik} option={option}/>
                            <Clarity />
                            <Lab userinfo={userinfo} />
                            <Shortcut formik={formik} option={option}/>
                            <Finish />
                            <Flour />
                            <Location userinfo={userinfo} formik={formik}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
