/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaUserAlt, FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export function ProfileTile() {
  return (
    <>
      <div className={`card card-custom viewprofile card-stretch gutter-b`} style={{ height: "120px" }} >
        <Link  to="/profile" >
          <div className="card-body">
            <div className="text-white font-weight-bolder font-size-h2 mt-3">View Profile</div>
            <span className="text-light font-weight-bold font-size-h2 mt-1"><FaLongArrowAltRight /></span>
            <div className="bgIcon">
              <FaUserAlt />
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
