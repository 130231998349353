import React from "react";
import { Link } from "react-router-dom";
import {BASE_URL_OLD} from "../../../../env_config"
import { toAbsoluteUrl } from "../../../_helpers";

export const FtpModeHelp = ({userinfo}) => {

    return (
        <>
           <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="font-size-h2 font-weight-bold">Uploading the file using FTP</div>
                    <div>
                        <Link to="/">Home</Link>/<Link to="/help">Help</Link>/<span> FTP Mode</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-body">
                            <p>Do the steps provided below to upload files using FTP client;</p>
                            <p>Step 1: Repeat step 1 and step 2, see <Link to={'/uploadmode-help#uploading-the-stock'}>Uploading the Stock</Link></p>
                            <p>Step 2: Click <strong><a href={`"${BASE_URL_OLD}/assets/${userinfo.type === "Natural" ? "doc_file.csv" : "doc_lab_grown.csv" }"`} download="doc_upload_file.csv">Download Sample File from here</a></strong> to download the sample file. </p>
                            <p>Step 3:Rename the sample file as “stock.csv” while saving and upload with the same file name.</p>
                            <p>Step 4: Enter the diamond parameters details in the downloaded sample file and upload it. For more details on parameters go to HELP→ <a href="<?= base_url() ?>parameter">Parameter</a></p>
                            <p>Step 5: Copy the FTP details to upload files using the FTP client.</p>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/11.jpg`)} alt="11" border="0" />
                                </figure>
                            </div>
                            <p>For more details on how to upload files using FTP client please refer to the <a class="pl-1" href="https://www.siteground.com/tutorials/ftp/filezilla/#How_to_upload_files_using_the_FileZilla_FTP_client">sample link</a></p>
                            <p>Step 6: After uploading the file click on <strong>Refresh</strong> to verify if the data was successfully uploaded.</p>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/12.jpg`)} alt="12" border="0" />
                                </figure>
                            </div>
                            <p>You have successfully completed uploading the file via FTP.</p>
                            <div className="text-center">
                                <figure>
                                    <img className="img-fluid p-3" src={toAbsoluteUrl(`/media/screenshots/13.jpg`)} alt="13" border="0" />
                                </figure>
                            </div>
                            <p> Once the WinCEP FTP client is activated, you can define the setting in WinCEP FTP client to automatically upload files to the server every two hours.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
