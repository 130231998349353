/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export function ApiSetting({ userinfo }) {
    return (
        <div className="card card-custom">
            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        API Setting
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        Check your API setting
                    </span>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="card-body">
                <div className="row">
                    <div className="col-md-7">
                        <div className="border rounded bg-gray-100 p-5">
                            <div className="font-size-h4 font-weight-bolder mb-8">
                                Your Current Setting
                            </div>
                            {userinfo.stock_access_status === 1 ? (
                                <div>
                                    <div className="font-size-h5 font-weight-bolder">
                                        Access to all
                                    </div>
                                    <div className="font-weight-bold text-muted mb-2">
                                        This will allow all user to access your
                                        api
                                    </div>
                                </div>
                            ) : userinfo.stock_access_status === 2 ? (
                                <div>
                                    <div className="font-size-h5 font-weight-bolder">
                                        Access to Seleted
                                    </div>
                                    <div className="font-weight-bold text-muted mb-2">
                                        You will receive a request from customer
                                        and you can approve or cancel their api
                                        request
                                    </div>
                                </div>
                            ) : userinfo.stock_access_status === 3 ? (
                                <div>
                                    <div className="font-size-h5 font-weight-bolder">
                                        Turn off Access
                                    </div>
                                    <div className="font-weight-bold text-muted mb-2">
                                        Nobody can use your api
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Form */}
        </div>
    );
}
