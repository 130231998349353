import React, { useState } from "react";
import { toAbsoluteUrl } from "../../_helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../controls";
import { NODE_API_URL } from "../../../env_config";
import axios from "axios";

export const SelectUploadMode = ({ userinfo }) => {
    const [loading, setloading] = useState(false);

    const initialValues = {
        selectMode: "",
    };
 

    const Schema = Yup.object().shape({
        selectMode: Yup.string("").required("Please select any one mode"),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            // console.log("values",values)
            // console.log("formik.touched",formik.touched)
            // setloading(true);
            const finalData = {
                supplier_name: userinfo.supplier_name,
                mode: values.selectMode,
            };
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/uploadModeSelection`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            })
            .then((res) => {
                setloading(false);
                // console.log(res)
                if (res && res.data) {
                    setSubmitting(false);
                    window.location.reload()
                }
            })
            .catch((res) => {
                // console.log(res.response)
                setloading(false);
                setSubmitting(false);
            });
        },
    });

    return (
        <>
            <form className="form" onSubmit={formik.handleSubmit}>
                {loading && <ModalProgressBar />}
                <div className="card-body">
                    <h3 className="mb-1">Select Upload Mode</h3>
                    <span className="text-muted">
                        Kindly select the upload mode below, to upload your
                        Diamond inventory to Diamondsoncall.com
                    </span>
                    <div className="row">
                        <div className="col-12 mt-5">
                            <div className={`form-group px-5 ${getInputClasses( "selectMode" )}`}>
                                <div className="radio-list">
                                    <label className="radio mb-5 font-weight-bold">
                                        <input
                                            type="radio"
                                            name="selectMode"
                                            value="API"
                                            checked={formik.values.selectMode === "API"}
                                            onChange={() => formik.setFieldValue("selectMode", "API")}
                                            className={` ${getInputClasses(
                                                "selectMode"
                                            )}`}
                                        />
                                        <span></span>
                                        <img
                                            className="mr-3 rounded"
                                            width={24}
                                            alt="dimond type"
                                            src={toAbsoluteUrl(
                                                "/media/api-ico.png"
                                            )}
                                        />
                                        API
                                    </label>
                                    <label className="radio mb-5  font-weight-bold">
                                        <input
                                            type="radio"
                                            name="selectMode"
                                            value="FTP"
                                            checked={formik.values.selectMode === "FTP"}
                                            onChange={() => formik.setFieldValue("selectMode", "FTP")}
                                            className={` ${getInputClasses(
                                                "selectMode"
                                            )}`}
                                            // {...formik.getFieldProps("selectMode")}
                                        />
                                        <span></span>
                                        <img
                                            className="mr-3 rounded"
                                            width={24}
                                            alt="dimond type"
                                            src={toAbsoluteUrl(
                                                "/media/ftp_ico.png"
                                            )}
                                        />
                                        FTP
                                    </label>
                                    <label className="radio font-weight-bold">
                                        <input
                                            type="radio"
                                            name="selectMode"
                                            value="File"
                                            // {...formik.getFieldProps("selectMode")}
                                            checked={formik.values.selectMode === "File"}
                                            onChange={() => formik.setFieldValue("selectMode", "File")}
                                            className={` ${getInputClasses(
                                                "selectMode"
                                            )}`}
                                        />
                                        <span></span>
                                        <img
                                            className="mr-3 rounded"
                                            width={24}
                                            alt="dimond type"
                                            src={toAbsoluteUrl(
                                                "/media/file_ico.png"
                                            )}
                                        />
                                        FILE
                                    </label>
                                </div>
                            </div>
                            {formik.touched.selectMode &&
                            formik.errors.selectMode ? (
                                <div className="invalid-feedback">
                                    {formik.errors.selectMode}
                                </div>
                            ) : null}
                        </div>
                        <div className="col-12"></div>
                    </div>
                </div>
                <div className="card-footer py-4">
                    <button
                        type="submit"
                        className="btn btn-primary mr-2"
                        disabled={
                            formik.isSubmitting ||
                            (formik.touched && !formik.isValid)
                        }
                    >
                        Save Changes
                        {formik.isSubmitting}
                    </button>
                </div>
            </form>
        </>
    );
};
