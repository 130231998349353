/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Header from "./Header";
import HomeRoutes from "./HomeRoutes";
import FooterBootom from "./FooterBottom";
import "./css/style.css";

export function AuthPage() {
  return (
    <>
      {/*  <div className="homePage"> */}
        <Header />
        <HomeRoutes />
        <FooterBootom />
      {/*  </div> */}
    </>
  );
}
