import React from "react";
import { NavLink } from "react-router-dom";
import BannerImg from "../Images/sell-diamonds.png"
import JwaAward from "../Images/jwa_award_banner_t.png"
import FeaturesData from "./FeaturesData";
import FeaturesCard from "./FeaturesCard";

const Banner =() =>{
    return(
        <>
            <div className="container-fluid p-0 bannerbg ">
                <img className="text-center" src={JwaAward} alt="Jwa Award Banner" title="Jwa Award Banner" width="" height="70"/>
            </div>
            <div className="container-fluid px-0">
                <div className="row headertop justify-content-center m-0">
                    <div className="col-12 text-center">
                        <h1>Welcome to Supplier Centre<p>Sell Diamonds Online hassle-free</p></h1>
                    </div>
                    <div className="col-12 text-center">
                        <NavLink className="btn btn-light action-button py-2 font-size-lg px-8 mt-3" role="button" to="/auth/registration">Join now </NavLink>
                    </div>
                </div>
                <div className="row headerimg justify-content-center m-0">
                    <div className="col-12 text-center">
                        <img src={BannerImg} alt="sell-diamonds-online" title="Sell Diamonds Online Hassle Free" />
                    </div>
                </div>
                <div className="features-boxed mt-3">
                    <div className="container">
                        <div className="row justify-content-center features">
                            {FeaturesData.map((val) => {
                                return(
                                    <FeaturesCard 
                                        key={val.id}
                                        imgsrc={val.imgsrc}
                                        title={val.title}
                                        content={val.content}
                                    />
                                );    
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner-border"></div>
        </>
    )
}

export default Banner;